import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker"
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css"
import { differenceInSeconds, isValid, subHours } from "date-fns"
import { isArray } from "lodash"
import React, { useMemo, useState } from "react"
import "react-calendar/dist/Calendar.css"
import "react-clock/dist/Clock.css"
import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { useUserLocationStatsQuery } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Card } from "../common"

export type UserLocationRecordStatsProps = {
	userId: string
}

type ChartData = {
	zone: { _id: string; name: string }
	durationInSeconds: number
	Duration: number
	DurationInWords: string
}

export const UserLocationRecordStats: React.FC<UserLocationRecordStatsProps> = ({ userId }) => {
	const [range, setRange] = useState<[Date, Date]>([subHours(new Date(), 8), new Date()])

	const [{ data }] = useUserLocationStatsQuery({
		variables: { userId, startTimestamp: range[0], endTimestamp: range[1] },
		pause: !userId,
	})

	const userLocationStatsChartData = useMemo<ChartData[]>(() => {
		if (!data?.userLocationStats) return []

		const updatedChartData: ChartData[] = []
		const durationByZone: { [key: string]: number } = {}

		data.userLocationStats.forEach((record) => {
			const durationInSeconds = (): number => {
				// if selected start and end time is between the record start and end time
				if (new Date(range[0]).getTime() >= new Date(record.startAt).getTime() && new Date(range[1]).getTime() <= new Date(record.endAt).getTime()) {
					return Math.abs(differenceInSeconds(new Date(range[0]), new Date(range[1])))
				}
				// if selected start time is before the record start time and selected end time is less than record end time
				else if (new Date(range[0]).getTime() <= new Date(record.startAt).getTime() && new Date(range[1]).getTime() <= new Date(record.endAt).getTime()) {
					return Math.abs(differenceInSeconds(new Date(range[0]), new Date(record.endAt)))
				}
				// if selected start time is before the record end time and selected end time is
				else if (new Date(range[0]).getTime() >= new Date(record.startAt).getTime() && new Date(range[0]).getTime() <= new Date(record.endAt).getTime() && new Date(range[1]).getTime() >= new Date(record.endAt).getTime()) {
					return Math.abs(differenceInSeconds(new Date(record.startAt), new Date(range[1])))
				} else {
					return Math.abs(differenceInSeconds(new Date(record.startAt), new Date(record.endAt)))
				}
			}

			const duration = durationInSeconds()

			if (durationByZone[record.zone._id]) {
				durationByZone[record.zone._id] += duration
			} else {
				durationByZone[record.zone._id] = duration
			}
		})

		const totalTimeInSeconds = Object.values(durationByZone).reduce((acc, curr) => acc + curr, 0)

		for (const zoneId in durationByZone) {
			const zoneData = data.userLocationStats.find((record) => record.zone._id === zoneId)?.zone

			if (zoneData) {
				updatedChartData.push({
					zone: zoneData,
					durationInSeconds: durationByZone[zoneId],
					Duration: parseFloat(((durationByZone[zoneId] / totalTimeInSeconds) * 100).toFixed(2)),
					DurationInWords: getDurationInWords(durationByZone[zoneId]),
				})
			}
		}

		return updatedChartData.filter((u) => u.Duration > 0)
	}, [JSON.stringify(data), range])

	// Custom Tooltip Component
	const CustomTooltip = ({ payload, label }: any) => {
		if (payload.length === 0) return null

		// Extract values and format with '%'
		const value = payload[0].value

		const durationInWords = payload[0].payload.DurationInWords

		return (
			<div style={{ backgroundColor: "#fff", border: "1px solid #ccc", padding: "5px", borderRadius: "5px" }}>
				<p>{`Zone: ${label}`}</p>
				<p>{`Time Spent: ${durationInWords} (${value}%)`}</p>
			</div>
		)
	}

	return (
		<Card
			title="Location Stats"
			action={
				<DateTimeRangePicker
					onChange={(_range) => {
						if (isArray(_range) && isValid(_range[0]) && isValid(_range[1])) {
							setRange(_range as [Date, Date])
						}
					}}
					value={range}
					disableClock
					format="dd-MM-y h:mm a"
					clearIcon={null}
				/>
			}
			alwaysShowAction
		>
			<ResponsiveContainer width="100%" height={350}>
				<BarChart
					width={500}
					height={300}
					data={userLocationStatsChartData}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="zone.name" />
					<YAxis />
					<Tooltip content={<CustomTooltip />} />
					<Bar dataKey="Duration" fill="#a68bf3" activeBar={<Rectangle fill="#a68bf3" stroke="transparent" />} />
				</BarChart>
			</ResponsiveContainer>
		</Card>
	)
}
