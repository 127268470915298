import gql from "graphql-tag"
import * as Urql from "urql"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string
	String: string
	Boolean: boolean
	Int: number
	Float: number
	DateTime: any
}

export type ActiveRestrictedUserLocationRecordsResponse = {
	__typename?: "ActiveRestrictedUserLocationRecordsResponse"
	recordIds: Array<Scalars["String"]>
	zoneWiseRecordIds: Array<ZoneWiseActiveRestrictedUserLocationRecordsResponse>
}

export type ActiveUsersResponse = {
	__typename?: "ActiveUsersResponse"
	userIds: Array<Scalars["String"]>
	zoneWiseUserIds: Array<ZoneWiseActiveUsersResponse>
}

export type Alert = {
	__typename?: "Alert"
	_id: Scalars["String"]
	alertFlowId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	emailMessage: Scalars["String"]
	escalateAfter?: Maybe<Scalars["DateTime"]>
	isResolved?: Maybe<Scalars["Boolean"]>
	levels: Array<AlertLevel>
	notificationMessage: Scalars["String"]
	readerId?: Maybe<Scalars["String"]>
	siteId: Scalars["String"]
	siteType: SiteTypes
	tagId?: Maybe<Scalars["String"]>
	toBeEscalated?: Maybe<Scalars["Boolean"]>
	translation?: Maybe<AlertTranslationObject>
	type: AlertTypes
	updatedAt: Scalars["DateTime"]
	userId?: Maybe<Scalars["String"]>
	userLocationRecordId?: Maybe<Scalars["String"]>
	userRoleId?: Maybe<Scalars["String"]>
	userRouteRecordId?: Maybe<Scalars["String"]>
	zoneId?: Maybe<Scalars["String"]>
}

export type AlertFlow = {
	__typename?: "AlertFlow"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	levels: Array<AlertTargetLevel>
	siteId: Scalars["String"]
	siteType: SiteTypes
	source?: Maybe<AlertSource>
	translation?: Maybe<AlertFlowsTranslationObject>
	type: AlertTypes
	updatedAt: Scalars["DateTime"]
}

export type AlertFlowInput = {
	label: LabelInput
	levels: Array<AlertTargetLevelInput>
	source?: InputMaybe<AlertSourceInput>
	type: AlertTypes
}

export type AlertFlowsTranslation = {
	__typename?: "AlertFlowsTranslation"
	name: Scalars["String"]
	type: Scalars["String"]
}

export type AlertFlowsTranslationObject = {
	__typename?: "AlertFlowsTranslationObject"
	en: AlertFlowsTranslation
	es: AlertFlowsTranslation
	hi: AlertFlowsTranslation
}

export type AlertLevel = {
	__typename?: "AlertLevel"
	alertedUserIds?: Maybe<Array<Scalars["String"]>>
	emailedUserIds?: Maybe<Array<Scalars["String"]>>
	responses: Array<AlertResponse>
}

export type AlertResponse = {
	__typename?: "AlertResponse"
	acknowlegedAt: Scalars["DateTime"]
	acknowlegment?: Maybe<Scalars["String"]>
	resolution?: Maybe<Scalars["String"]>
	resolvedAt?: Maybe<Scalars["DateTime"]>
	userId: Scalars["String"]
}

export type AlertSource = {
	__typename?: "AlertSource"
	type: AlertSourceTypes
	userGroupIds?: Maybe<Array<Scalars["String"]>>
	userIds?: Maybe<Array<Scalars["String"]>>
	zoneGroupIds?: Maybe<Array<Scalars["String"]>>
	zoneIds?: Maybe<Array<Scalars["String"]>>
}

export type AlertSourceInput = {
	type?: InputMaybe<AlertSourceTypes>
	userGroupIds?: InputMaybe<Array<Scalars["String"]>>
	userIds?: InputMaybe<Array<Scalars["String"]>>
	zoneGroupIds?: InputMaybe<Array<Scalars["String"]>>
	zoneIds?: InputMaybe<Array<Scalars["String"]>>
}

export enum AlertSourceTypes {
	UserGroups = "UserGroups",
	Users = "Users",
	ZoneGroups = "ZoneGroups",
	Zones = "Zones",
}

export enum AlertStatus {
	Closed = "Closed",
	Open = "Open",
}

export type AlertTargetGroup = {
	__typename?: "AlertTargetGroup"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	siteId: Scalars["String"]
	siteType: SiteTypes
	translation?: Maybe<AlertTargetGroupTranslationObject>
	updatedAt: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
}

export type AlertTargetGroupInput = {
	label: LabelInput
	userIds: Array<Scalars["String"]>
}

export type AlertTargetGroupTranslation = {
	__typename?: "AlertTargetGroupTranslation"
	name: Scalars["String"]
}

export type AlertTargetGroupTranslationObject = {
	__typename?: "AlertTargetGroupTranslationObject"
	en: AlertTargetGroupTranslation
	es: AlertTargetGroupTranslation
	hi: AlertTargetGroupTranslation
}

export type AlertTargetLevel = {
	__typename?: "AlertTargetLevel"
	targetGroupIds: Array<Scalars["String"]>
	timeout: Scalars["Float"]
}

export type AlertTargetLevelInput = {
	targetGroupIds: Array<Scalars["String"]>
	timeout: Scalars["Float"]
}

export type AlertTranslation = {
	__typename?: "AlertTranslation"
	notificationMessage: Scalars["String"]
	type: Scalars["String"]
}

export type AlertTranslationObject = {
	__typename?: "AlertTranslationObject"
	es: AlertTranslation
	hi: AlertTranslation
}

export enum AlertTypes {
	AllInactiveReaders = "AllInactiveReaders",
	AppUpdate = "AppUpdate",
	Crowd = "Crowd",
	IdleZone = "IdleZone",
	InactiveReader = "InactiveReader",
	InactiveService = "InactiveService",
	IncorrectRoute = "IncorrectRoute",
	IncorrectRouteTime = "IncorrectRouteTime",
	RestrictedEntry = "RestrictedEntry",
	SOS = "SOS",
	TagBatteryLow = "TagBatteryLow",
	UserRoleExpiry = "UserRoleExpiry",
}

export type AlertsFilterInput = {
	status?: InputMaybe<AlertStatus>
	type?: InputMaybe<AlertTypes>
}

export type ComprehensiveUserAttendanceReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
}

export type DemoContractor = {
	__typename?: "DemoContractor"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type DemoFacialAttendance = {
	__typename?: "DemoFacialAttendance"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	inAt: Scalars["DateTime"]
	inDeviceId: Scalars["String"]
	inImage?: Maybe<Scalars["String"]>
	isUnknown?: Maybe<Scalars["Boolean"]>
	outAt?: Maybe<Scalars["DateTime"]>
	outDeviceId?: Maybe<Scalars["String"]>
	outImage?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	user?: Maybe<DemoUser>
	userId?: Maybe<Scalars["String"]>
}

export type DemoUser = {
	__typename?: "DemoUser"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	contractor: DemoContractor
	contractorId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	picture?: Maybe<Picture>
	referenceNo?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type DemoUserAttendanceStatsResponse = {
	__typename?: "DemoUserAttendanceStatsResponse"
	absent: Array<DemoUser>
	ins: Array<DemoFacialAttendance>
	outs: Array<DemoFacialAttendance>
	unknowns: Array<DemoFacialAttendance>
}

export type Department = {
	__typename?: "Department"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	siteId: Scalars["String"]
	siteType: SiteTypes
	translation?: Maybe<DepartmentTranslationObject>
	updatedAt: Scalars["DateTime"]
	zoneIds: Array<Scalars["String"]>
}

export type DepartmentInput = {
	label: LabelInput
	zoneIds: Array<Scalars["String"]>
}

export type DepartmentTranslation = {
	__typename?: "DepartmentTranslation"
	name: Scalars["String"]
}

export type DepartmentTranslationObject = {
	__typename?: "DepartmentTranslationObject"
	en: DepartmentTranslation
	es: DepartmentTranslation
	hi: DepartmentTranslation
}

export type Email = {
	__typename?: "Email"
	address: Scalars["String"]
	isVerified: Scalars["Boolean"]
	verifiedAt?: Maybe<Scalars["DateTime"]>
}

export type EmailInput = {
	address: Scalars["String"]
}

export type ExtendedAlert = {
	__typename?: "ExtendedAlert"
	_id: Scalars["String"]
	alertFlow: AlertFlow
	alertFlowId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	emailMessage: Scalars["String"]
	escalateAfter?: Maybe<Scalars["DateTime"]>
	isResolved?: Maybe<Scalars["Boolean"]>
	levels: Array<ExtendedAlertLevel>
	notificationMessage: Scalars["String"]
	reader?: Maybe<Reader>
	readerId?: Maybe<Scalars["String"]>
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	tag?: Maybe<ExtendedTag>
	tagId?: Maybe<Scalars["String"]>
	toBeEscalated?: Maybe<Scalars["Boolean"]>
	translation?: Maybe<AlertTranslationObject>
	type: AlertTypes
	updatedAt: Scalars["DateTime"]
	user?: Maybe<User>
	userId?: Maybe<Scalars["String"]>
	userLocationRecord?: Maybe<ExtendedUserLocationRecord>
	userLocationRecordId?: Maybe<Scalars["String"]>
	userRole?: Maybe<ExtendedUserRole>
	userRoleId?: Maybe<Scalars["String"]>
	userRouteRecord?: Maybe<ExtendedUserRouteRecord>
	userRouteRecordId?: Maybe<Scalars["String"]>
	zone?: Maybe<Zone>
	zoneId?: Maybe<Scalars["String"]>
}

export type ExtendedAlertFlow = {
	__typename?: "ExtendedAlertFlow"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	levels: Array<ExtendedAlertTargetLevel>
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	source?: Maybe<ExtendedAlertSource>
	translation?: Maybe<AlertFlowsTranslationObject>
	type: AlertTypes
	updatedAt: Scalars["DateTime"]
}

export type ExtendedAlertLevel = {
	__typename?: "ExtendedAlertLevel"
	alertedUserIds?: Maybe<Array<Scalars["String"]>>
	alertedUsers?: Maybe<Array<User>>
	emailedUserIds?: Maybe<Array<Scalars["String"]>>
	emailedUsers?: Maybe<Array<User>>
	responses: Array<ExtendedAlertResponse>
}

export type ExtendedAlertResponse = {
	__typename?: "ExtendedAlertResponse"
	acknowlegedAt: Scalars["DateTime"]
	acknowlegment?: Maybe<Scalars["String"]>
	resolution?: Maybe<Scalars["String"]>
	resolvedAt?: Maybe<Scalars["DateTime"]>
	user: User
	userId: Scalars["String"]
}

export type ExtendedAlertSource = {
	__typename?: "ExtendedAlertSource"
	type: AlertSourceTypes
	userGroupIds?: Maybe<Array<Scalars["String"]>>
	userGroups?: Maybe<Array<UserGroup>>
	userIds?: Maybe<Array<Scalars["String"]>>
	users?: Maybe<Array<User>>
	zoneGroupIds?: Maybe<Array<Scalars["String"]>>
	zoneGroups?: Maybe<Array<ZoneGroup>>
	zoneIds?: Maybe<Array<Scalars["String"]>>
	zones?: Maybe<Array<Zone>>
}

export type ExtendedAlertTargetGroup = {
	__typename?: "ExtendedAlertTargetGroup"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	translation?: Maybe<AlertTargetGroupTranslationObject>
	updatedAt: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
	users: Array<User>
}

export type ExtendedAlertTargetLevel = {
	__typename?: "ExtendedAlertTargetLevel"
	targetGroupIds: Array<Scalars["String"]>
	targetGroups: Array<AlertTargetGroup>
	timeout: Scalars["Float"]
}

export type ExtendedDepartment = {
	__typename?: "ExtendedDepartment"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	translation?: Maybe<DepartmentTranslationObject>
	updatedAt: Scalars["DateTime"]
	zoneIds: Array<Scalars["String"]>
	zones: Array<Zone>
}

export type ExtendedPatrollingRoute = {
	__typename?: "ExtendedPatrollingRoute"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	maxTime: Scalars["Float"]
	minTime: Scalars["Float"]
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	updatedAt: Scalars["DateTime"]
	zoneIds: Array<Scalars["String"]>
	zones: Array<Zone>
}

export type ExtendedReport = {
	__typename?: "ExtendedReport"
	_id: Scalars["String"]
	completeJson?: Maybe<ReportObject>
	createdAt: Scalars["DateTime"]
	csv?: Maybe<ReportObject>
	json?: Maybe<ReportObject>
	label: Label
	lastUpdatedByUser?: Maybe<User>
	readerIds?: Maybe<Array<Scalars["String"]>>
	readers?: Maybe<Array<Reader>>
	sinceDate: Scalars["DateTime"]
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	status: ReportStatus
	type: ReportTypes
	untilDate: Scalars["DateTime"]
	updatedAt: Scalars["DateTime"]
	userIds?: Maybe<Array<Scalars["String"]>>
	users?: Maybe<Array<User>>
	zoneGroupIds?: Maybe<Array<Scalars["String"]>>
	zoneGroups?: Maybe<Array<ZoneGroup>>
	zoneIds?: Maybe<Array<Scalars["String"]>>
	zones?: Maybe<Array<Zone>>
}

export type ExtendedRoutePoint = {
	__typename?: "ExtendedRoutePoint"
	areaImage?: Maybe<PictureObject>
	areaIntact?: Maybe<Scalars["Boolean"]>
	createdAt: Scalars["DateTime"]
	guardImage?: Maybe<PictureObject>
	guardOnDuty?: Maybe<Scalars["Boolean"]>
	isSkipped?: Maybe<Scalars["Boolean"]>
	locationRecord?: Maybe<UserLocationRecord>
	locationRecordId?: Maybe<Scalars["String"]>
	skipRemarks?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	zone: Zone
	zoneId: Scalars["String"]
}

export type ExtendedTag = {
	__typename?: "ExtendedTag"
	_id: Scalars["String"]
	assignedAt?: Maybe<Scalars["DateTime"]>
	assignedToUserId?: Maybe<Scalars["String"]>
	batteryStatus: TagBatteryStatus
	createdAt: Scalars["DateTime"]
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	name?: Maybe<Scalars["String"]>
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	status: TagStatus
	tagDecimalId: Scalars["String"]
	tagHexId: Scalars["String"]
	translation?: Maybe<TagsTranslationObject>
	updatedAt: Scalars["DateTime"]
	user?: Maybe<User>
}

export type ExtendedTileEmbeddedZone = {
	__typename?: "ExtendedTileEmbeddedZone"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	position: Position
	siteId: Scalars["String"]
	siteType: SiteTypes
	tileId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	zone: Zone
	zoneId: Scalars["String"]
}

export type ExtendedUser = {
	__typename?: "ExtendedUser"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	blacklistRemarks?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	email?: Maybe<Email>
	fcmTokens?: Maybe<Array<Scalars["String"]>>
	isBlacklisted?: Maybe<Scalars["Boolean"]>
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	name: Scalars["String"]
	permissionsGroupIds?: Maybe<Array<Scalars["String"]>>
	phone?: Maybe<Phone>
	picture?: Maybe<Picture>
	referenceNo?: Maybe<Scalars["String"]>
	scopedSiteIds: Array<Scalars["String"]>
	scopedSites: Array<Site>
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	status: UserStatus
	tag?: Maybe<Tag>
	tagId?: Maybe<Scalars["String"]>
	translation?: Maybe<Translation>
	type: UserType
	typeId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type ExtendedUserAttendance = {
	__typename?: "ExtendedUserAttendance"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	inAt: Scalars["DateTime"]
	inDeviceId?: Maybe<Scalars["String"]>
	inImage?: Maybe<Scalars["String"]>
	inRemarks?: Maybe<Scalars["String"]>
	outAt?: Maybe<Scalars["DateTime"]>
	outDeviceId?: Maybe<Scalars["String"]>
	outImage?: Maybe<Scalars["String"]>
	outRemarks?: Maybe<Scalars["String"]>
	site: Site
	siteId: Scalars["String"]
	siteType?: Maybe<SiteTypes>
	updatedAt: Scalars["DateTime"]
	user: User
	userId: Scalars["String"]
}

export type ExtendedUserGroup = {
	__typename?: "ExtendedUserGroup"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	updatedAt: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
	users: Array<User>
}

export type ExtendedUserLocationRecord = {
	__typename?: "ExtendedUserLocationRecord"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	endAt: Scalars["DateTime"]
	isRestricted?: Maybe<Scalars["Boolean"]>
	siteId: Scalars["String"]
	siteType?: Maybe<SiteTypes>
	startAt: Scalars["DateTime"]
	status: UserLocationRecordStatus
	updatedAt: Scalars["DateTime"]
	user: ExtendedUser
	userId: Scalars["String"]
	zone: Zone
	zoneId: Scalars["String"]
}

export type ExtendedUserRole = {
	__typename?: "ExtendedUserRole"
	_id: Scalars["String"]
	assignedAt: Scalars["DateTime"]
	createdAt: Scalars["DateTime"]
	designation?: Maybe<Scalars["String"]>
	endAt?: Maybe<Scalars["DateTime"]>
	removedAt?: Maybe<Scalars["DateTime"]>
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	startAt: Scalars["DateTime"]
	status: UserRoleStatus
	type: UserRoleTypes
	updatedAt: Scalars["DateTime"]
	user: User
	userId: Scalars["String"]
	userRoleGroup: ExtendedUserRoleGroup
	userRoleGroupId: Scalars["String"]
}

export type ExtendedUserRoleGroup = {
	__typename?: "ExtendedUserRoleGroup"
	_id: Scalars["String"]
	allowedZoneGroupIds?: Maybe<Array<Scalars["String"]>>
	allowedZoneGroups?: Maybe<Array<ZoneGroup>>
	allowedZoneIds?: Maybe<Array<Scalars["String"]>>
	allowedZones?: Maybe<Array<Zone>>
	createdAt: Scalars["DateTime"]
	department: Department
	departmentId: Scalars["String"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	patrollingRoute?: Maybe<ExtendedPatrollingRoute>
	patrollingRouteId?: Maybe<Scalars["String"]>
	rounds?: Maybe<Scalars["Float"]>
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	translation?: Maybe<UserRoleGroupsTranslationObject>
	type: UserRoleTypes
	updatedAt: Scalars["DateTime"]
	workShift?: Maybe<WorkShift>
	workShiftId?: Maybe<Scalars["String"]>
}

export type ExtendedUserRouteRecord = {
	__typename?: "ExtendedUserRouteRecord"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	endAt: Scalars["DateTime"]
	expectedMaxTimeInMinutes: Scalars["Float"]
	expectedMinTimeInMinutes: Scalars["Float"]
	expectedRouteZoneIds: Array<Scalars["String"]>
	expectedRouteZones: Array<Zone>
	findings?: Maybe<Array<RouteFinding>>
	isFinal?: Maybe<Scalars["Boolean"]>
	isPartiallyFinal?: Maybe<Scalars["Boolean"]>
	isRouteCorrect?: Maybe<Scalars["Boolean"]>
	isTemporary?: Maybe<Scalars["Boolean"]>
	isTimeCorrect?: Maybe<Scalars["Boolean"]>
	isTimeHigh?: Maybe<Scalars["Boolean"]>
	isTimeLow?: Maybe<Scalars["Boolean"]>
	locationRecordIds?: Maybe<Array<Scalars["String"]>>
	locationRecords?: Maybe<Array<RouteUserLocationRecord>>
	patrollingRouteId: Scalars["String"]
	routePoints?: Maybe<Array<ExtendedRoutePoint>>
	siteId: Scalars["String"]
	siteType?: Maybe<SiteTypes>
	startAt: Scalars["DateTime"]
	timeTakenInMinutes: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
	userId: Scalars["String"]
}

export type ExtendedUserUpdateLog = {
	__typename?: "ExtendedUserUpdateLog"
	_id: Scalars["String"]
	afterUpdate: PartialExtendedUser
	beforeUpdate: PartialExtendedUser
	document: ExtendedUser
	documentId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	updatedByUser: ExtendedUser
	updatedByUserId: Scalars["String"]
}

export type ExtendedWorkShift = {
	__typename?: "ExtendedWorkShift"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	endAt: Scalars["String"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	startAt: Scalars["String"]
	translation?: Maybe<WorkShiftTranslationObject>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedZone = {
	__typename?: "ExtendedZone"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	crowdLimit: Scalars["Float"]
	idleSince?: Maybe<Scalars["DateTime"]>
	isActive?: Maybe<Scalars["Boolean"]>
	isCrowded?: Maybe<Scalars["Boolean"]>
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	picture?: Maybe<Picture>
	readerIds?: Maybe<Array<Scalars["String"]>>
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	translation?: Maybe<ZonesTranslationObject>
	type: ZoneTypes
	updatedAt: Scalars["DateTime"]
}

export type ExtendedZoneGroup = {
	__typename?: "ExtendedZoneGroup"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	updatedAt: Scalars["DateTime"]
	zoneIds: Array<Scalars["String"]>
	zones: Array<Zone>
}

export type FieldError = {
	__typename?: "FieldError"
	error: Scalars["String"]
	field: Scalars["String"]
}

export type FinishUploadInput = {
	key: Scalars["String"]
}

export type FinishUploadResponse = {
	__typename?: "FinishUploadResponse"
	type: MediaTypes
	url: Scalars["String"]
}

export type Label = {
	__typename?: "Label"
	description?: Maybe<Scalars["String"]>
	name: Scalars["String"]
}

export type LabelInput = {
	description?: InputMaybe<Scalars["String"]>
	name: Scalars["String"]
}

export type MarkUserAttendanceBulkInInput = {
	inRemarks: Scalars["String"]
	inTime: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
}

export type MarkUserAttendanceBulkOutInput = {
	outRemarks: Scalars["String"]
	outTime: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
}

export type MeUser = {
	__typename?: "MeUser"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	blacklistRemarks?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	email?: Maybe<Email>
	fcmTokens?: Maybe<Array<Scalars["String"]>>
	isBlacklisted?: Maybe<Scalars["Boolean"]>
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	name: Scalars["String"]
	permissionsGroupIds?: Maybe<Array<Scalars["String"]>>
	permissionsGroups?: Maybe<Array<PermissionsGroup>>
	phone?: Maybe<Phone>
	picture?: Maybe<Picture>
	referenceNo?: Maybe<Scalars["String"]>
	scopedSiteIds: Array<Scalars["String"]>
	scopedSites: Array<Site>
	site: Site
	siteId: Scalars["String"]
	siteType: SiteTypes
	status: UserStatus
	tag?: Maybe<Tag>
	tagId?: Maybe<Scalars["String"]>
	translation?: Maybe<Translation>
	type: UserType
	typeId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export enum MediaTypes {
	DOC = "DOC",
	Image = "Image",
	MobileApp = "MobileApp",
	PDF = "PDF",
	Video = "Video",
}

export type MobileApp = {
	__typename?: "MobileApp"
	_id: Scalars["String"]
	changes: Scalars["String"]
	createdAt: Scalars["DateTime"]
	siteType: SiteTypes
	type: MobileAppTypes
	updatedAt: Scalars["DateTime"]
	url: Scalars["String"]
	version: Scalars["String"]
}

export type MobileAppInput = {
	changes: Scalars["String"]
	type: MobileAppTypes
	url: Scalars["String"]
	version: Scalars["String"]
}

export enum MobileAppTypes {
	Android = "Android",
	iOS = "iOS",
}

export type MobileAppsFilterInput = {
	keyword?: InputMaybe<Scalars["String"]>
	type?: InputMaybe<MobileAppTypes>
}

export type Mutation = {
	__typename?: "Mutation"
	acknowledgeAlert: ExtendedAlert
	activateTagOfUser: Tag
	activateUser: ExtendedUser
	addFCMToken: Scalars["Boolean"]
	addReaderToZone: Reader
	assignTag: Tag
	assignUserRole: ExtendedUserRole
	blacklistUser: ExtendedUser
	changePassword: Scalars["Boolean"]
	createAlertFlow: ExtendedAlertFlow
	createAlertTargetGroup: ExtendedAlertTargetGroup
	createDemoContractor: DemoContractor
	createDemoUser: DemoUser
	createDepartment: ExtendedDepartment
	createPatrollingRoute: ExtendedPatrollingRoute
	createPermissionsGroup: PermissionsGroup
	createReader: Reader
	createSOSEmergencyAlert: Array<Alert>
	createService: Service
	createTag: Tag
	createUser: ExtendedUser
	createUserGroup: ExtendedUserGroup
	createUserRoleGroup: ExtendedUserRoleGroup
	createUserType: UserType
	createWorkShift: ExtendedWorkShift
	createZone: ExtendedZone
	createZoneGroup: ExtendedZoneGroup
	deactivateTagOfUser: Tag
	deactivateUser: ExtendedUser
	deleteAlertFlow: Scalars["Boolean"]
	deleteAlertTargetGroup: Scalars["Boolean"]
	deletePermissionsGroup: Scalars["Boolean"]
	deleteService: Scalars["Boolean"]
	deleteUserType: Scalars["Boolean"]
	finishAvatarUpload: Picture
	finishCheckPointPictureUpload: FinishUploadResponse
	finishMobileAppUpload: FinishUploadResponse
	finishRouteFindingPictureUpload: FinishUploadResponse
	flushTag: Tag
	generateComprehensiveUserAttendanceReport: ExtendedReport
	generateReadersStatusLogsReport: ExtendedReport
	generateSummarizedUserAttendanceReport: ExtendedReport
	generateUserLocationRecordsReport: ExtendedReport
	generateUserPatrollingRouteReport: ExtendedReport
	generateUserRestrictedEntriesReport: ExtendedReport
	generateZoneGroupRestrictedEntriesReport: ExtendedReport
	generateZoneRestrictedEntriesReport: ExtendedReport
	login: UserAuthResponse
	logout: Scalars["Boolean"]
	markUserAttendanceBulkIn: Array<ExtendedUserAttendance>
	markUserAttendanceBulkOut: Array<ExtendedUserAttendance>
	markUserAttendanceIn: ExtendedUserAttendance
	markUserAttendanceOut: ExtendedUserAttendance
	removeCurrentUserRole: Scalars["Boolean"]
	removeReaderFromZone: Scalars["Boolean"]
	removeWorkShift: Scalars["Boolean"]
	resetUserPassword: ExtendedUser
	resolveAlert: ExtendedAlert
	sendEmailVerificationSecretCode: Scalars["Boolean"]
	signAvatarUpload: UploadSignatureResponse
	signCheckPointPictureUpload: UploadSignatureResponse
	signMobileAppUpload: UploadSignatureResponse
	signRouteFindingPictureUpload: UploadSignatureResponse
	submitRouteFinding?: Maybe<ExtendedUserRouteRecord>
	submitRoutePoints?: Maybe<ExtendedUserRouteRecord>
	switchSite: ExtendedUser
	updateAlertFlow: ExtendedAlertFlow
	updateAlertTargetGroup: ExtendedAlertTargetGroup
	updateDepartment: ExtendedDepartment
	updateManpowerCount: Site
	updatePatrollingRoute: ExtendedPatrollingRoute
	updatePermissionsGroup: PermissionsGroup
	updateService: Service
	updateUser: ExtendedUser
	updateUserGroup: ExtendedUserGroup
	updateUserPermissionsGroups: Array<PermissionsGroup>
	updateUserRoleGroup: ExtendedUserRoleGroup
	updateUserScopedSites: ExtendedUser
	updateUserType: UserType
	updateWorkShift: ExtendedWorkShift
	updateZone: ExtendedZone
	updateZoneGroup: ExtendedZoneGroup
	uploadMobileApp: MobileApp
	verifyEmailWithSecretCode: Scalars["Boolean"]
	whitelistUser: ExtendedUser
}

export type MutationacknowledgeAlertArgs = {
	alertId: Scalars["String"]
	level: Scalars["Float"]
	message: Scalars["String"]
}

export type MutationactivateTagOfUserArgs = {
	userId: Scalars["String"]
}

export type MutationactivateUserArgs = {
	userId: Scalars["String"]
}

export type MutationaddFCMTokenArgs = {
	token: Scalars["String"]
}

export type MutationaddReaderToZoneArgs = {
	readerDecimalId: Scalars["String"]
	zoneId: Scalars["String"]
}

export type MutationassignTagArgs = {
	tagDecimalId: Scalars["String"]
	userId: Scalars["String"]
}

export type MutationassignUserRoleArgs = {
	input: UserRoleInput
	userId: Scalars["String"]
}

export type MutationblacklistUserArgs = {
	remarks: Scalars["String"]
	userId: Scalars["String"]
}

export type MutationchangePasswordArgs = {
	currentPassword: Scalars["String"]
	newPassword: Scalars["String"]
	shouldLogOutOnOtherDevices?: InputMaybe<Scalars["Boolean"]>
}

export type MutationcreateAlertFlowArgs = {
	input: AlertFlowInput
}

export type MutationcreateAlertTargetGroupArgs = {
	input: AlertTargetGroupInput
}

export type MutationcreateDemoContractorArgs = {
	name: Scalars["String"]
}

export type MutationcreateDemoUserArgs = {
	address?: InputMaybe<Scalars["String"]>
	contractorId: Scalars["String"]
	name: Scalars["String"]
	pictureKey: Scalars["String"]
	referenceNo?: InputMaybe<Scalars["String"]>
	username: Scalars["String"]
}

export type MutationcreateDepartmentArgs = {
	input: DepartmentInput
}

export type MutationcreatePatrollingRouteArgs = {
	input: PatrollingRouteInput
}

export type MutationcreatePermissionsGroupArgs = {
	input: PermissionsGroupInput
}

export type MutationcreateReaderArgs = {
	input: ReaderInput
}

export type MutationcreateServiceArgs = {
	input: ServiceInput
}

export type MutationcreateTagArgs = {
	name?: InputMaybe<Scalars["String"]>
	tagDecimalId: Scalars["String"]
}

export type MutationcreateUserArgs = {
	input: UserInput
}

export type MutationcreateUserGroupArgs = {
	input: UserGroupInput
}

export type MutationcreateUserRoleGroupArgs = {
	input: UserRoleGroupInput
}

export type MutationcreateUserTypeArgs = {
	input: UserTypeInput
}

export type MutationcreateWorkShiftArgs = {
	input: WorkShiftInput
}

export type MutationcreateZoneArgs = {
	input: ZoneInput
}

export type MutationcreateZoneGroupArgs = {
	input: ZoneGroupInput
}

export type MutationdeactivateTagOfUserArgs = {
	userId: Scalars["String"]
}

export type MutationdeactivateUserArgs = {
	userId: Scalars["String"]
}

export type MutationdeleteAlertFlowArgs = {
	alertFlowId: Scalars["String"]
}

export type MutationdeleteAlertTargetGroupArgs = {
	alertTargetGroupId: Scalars["String"]
}

export type MutationdeletePermissionsGroupArgs = {
	permissionsGroupId: Scalars["String"]
}

export type MutationdeleteServiceArgs = {
	serviceId: Scalars["String"]
}

export type MutationdeleteUserTypeArgs = {
	userTypeId: Scalars["String"]
}

export type MutationfinishAvatarUploadArgs = {
	input: FinishUploadInput
	userId: Scalars["String"]
}

export type MutationfinishCheckPointPictureUploadArgs = {
	input: FinishUploadInput
}

export type MutationfinishMobileAppUploadArgs = {
	input: FinishUploadInput
}

export type MutationfinishRouteFindingPictureUploadArgs = {
	input: FinishUploadInput
}

export type MutationflushTagArgs = {
	userId: Scalars["String"]
}

export type MutationgenerateComprehensiveUserAttendanceReportArgs = {
	input: ComprehensiveUserAttendanceReportInput
}

export type MutationgenerateReadersStatusLogsReportArgs = {
	input: ReadersStatusLogsReportInput
}

export type MutationgenerateSummarizedUserAttendanceReportArgs = {
	input: SummarizedUserAttendanceReportInput
}

export type MutationgenerateUserLocationRecordsReportArgs = {
	input: UserLocationRecordsReportInput
}

export type MutationgenerateUserPatrollingRouteReportArgs = {
	input: UserPatrollingRouteReportInput
}

export type MutationgenerateUserRestrictedEntriesReportArgs = {
	input: UserRestrictedEntriesReportInput
}

export type MutationgenerateZoneGroupRestrictedEntriesReportArgs = {
	input: ZoneGroupRestrictedEntriesReportInput
}

export type MutationgenerateZoneRestrictedEntriesReportArgs = {
	input: ZoneRestrictedEntriesReportInput
}

export type MutationloginArgs = {
	password: Scalars["String"]
	siteType: SiteTypes
	username: Scalars["String"]
}

export type MutationmarkUserAttendanceBulkInArgs = {
	input: MarkUserAttendanceBulkInInput
}

export type MutationmarkUserAttendanceBulkOutArgs = {
	input: MarkUserAttendanceBulkOutInput
}

export type MutationmarkUserAttendanceInArgs = {
	inRemarks: Scalars["String"]
	inTime: Scalars["DateTime"]
	userId: Scalars["String"]
}

export type MutationmarkUserAttendanceOutArgs = {
	outRemarks: Scalars["String"]
	outTime: Scalars["DateTime"]
	userId: Scalars["String"]
}

export type MutationremoveCurrentUserRoleArgs = {
	userId: Scalars["String"]
}

export type MutationremoveReaderFromZoneArgs = {
	readerId: Scalars["String"]
	zoneId: Scalars["String"]
}

export type MutationremoveWorkShiftArgs = {
	workShiftId: Scalars["String"]
}

export type MutationresetUserPasswordArgs = {
	userId: Scalars["String"]
}

export type MutationresolveAlertArgs = {
	alertId: Scalars["String"]
	level: Scalars["Float"]
	message: Scalars["String"]
}

export type MutationsignAvatarUploadArgs = {
	format: Scalars["String"]
	userId: Scalars["String"]
}

export type MutationsignCheckPointPictureUploadArgs = {
	format: Scalars["String"]
}

export type MutationsignMobileAppUploadArgs = {
	format: Scalars["String"]
	type: MobileAppTypes
	version: Scalars["String"]
}

export type MutationsignRouteFindingPictureUploadArgs = {
	format: Scalars["String"]
}

export type MutationsubmitRouteFindingArgs = {
	input: RouteFindingInput
}

export type MutationsubmitRoutePointsArgs = {
	input: RoutePointInput
}

export type MutationswitchSiteArgs = {
	siteId: Scalars["String"]
	userId: Scalars["String"]
}

export type MutationupdateAlertFlowArgs = {
	alertFlowId: Scalars["String"]
	input: AlertFlowInput
}

export type MutationupdateAlertTargetGroupArgs = {
	alertTargetGroupId: Scalars["String"]
	input: AlertTargetGroupInput
}

export type MutationupdateDepartmentArgs = {
	departmentId: Scalars["String"]
	input: DepartmentInput
}

export type MutationupdateManpowerCountArgs = {
	manpowerCount: Scalars["Float"]
}

export type MutationupdatePatrollingRouteArgs = {
	input: PatrollingRouteInput
	patrollingRouteId: Scalars["String"]
}

export type MutationupdatePermissionsGroupArgs = {
	input: PermissionsGroupInput
	permissionsGroupId: Scalars["String"]
}

export type MutationupdateServiceArgs = {
	input: ServiceInput
	serviceId: Scalars["String"]
}

export type MutationupdateUserArgs = {
	input: UserInput
	userId: Scalars["String"]
}

export type MutationupdateUserGroupArgs = {
	input: UserGroupInput
	userGroupId: Scalars["String"]
}

export type MutationupdateUserPermissionsGroupsArgs = {
	permissionsGroupIds: Array<Scalars["String"]>
	userId: Scalars["String"]
}

export type MutationupdateUserRoleGroupArgs = {
	input: UpdateUserRoleGroupInput
	userRoleGroupId: Scalars["String"]
}

export type MutationupdateUserScopedSitesArgs = {
	siteIds: Array<Scalars["String"]>
	userId: Scalars["String"]
}

export type MutationupdateUserTypeArgs = {
	input: UserTypeInput
	userTypeId: Scalars["String"]
}

export type MutationupdateWorkShiftArgs = {
	input: WorkShiftInput
	workShiftId: Scalars["String"]
}

export type MutationupdateZoneArgs = {
	input: ZoneInput
	zoneId: Scalars["String"]
}

export type MutationupdateZoneGroupArgs = {
	input: ZoneGroupInput
	zoneGroupId: Scalars["String"]
}

export type MutationuploadMobileAppArgs = {
	input: MobileAppInput
}

export type MutationverifyEmailWithSecretCodeArgs = {
	code: Scalars["String"]
}

export type MutationwhitelistUserArgs = {
	userId: Scalars["String"]
}

export type PaginatedAlertFlowsResponse = {
	__typename?: "PaginatedAlertFlowsResponse"
	alertFlows: Array<AlertFlow>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedAlertTargetGroupsResponse = {
	__typename?: "PaginatedAlertTargetGroupsResponse"
	alertTargetGroups: Array<ExtendedAlertTargetGroup>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedAlertsResponse = {
	__typename?: "PaginatedAlertsResponse"
	alerts: Array<Alert>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedDepartmentsResponse = {
	__typename?: "PaginatedDepartmentsResponse"
	currentPage: Scalars["Float"]
	departments: Array<ExtendedDepartment>
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
}

export type PaginatedInput = {
	limit?: InputMaybe<Scalars["Int"]>
	page?: InputMaybe<Scalars["Float"]>
}

export type PaginatedMobileAppsResponse = {
	__typename?: "PaginatedMobileAppsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	mobileApps: Array<MobileApp>
}

export type PaginatedPatrollingRoutesResponse = {
	__typename?: "PaginatedPatrollingRoutesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	patrollingRoutes?: Maybe<Array<ExtendedPatrollingRoute>>
}

export type PaginatedPermissionsGroupsResponse = {
	__typename?: "PaginatedPermissionsGroupsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	permissionsGroups: Array<PermissionsGroup>
}

export type PaginatedReaderStatusLogsResponse = {
	__typename?: "PaginatedReaderStatusLogsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	readerStatusLogs: Array<ReaderStatusLog>
}

export type PaginatedReadersResponse = {
	__typename?: "PaginatedReadersResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	readers: Array<Reader>
}

export type PaginatedReportsResponse = {
	__typename?: "PaginatedReportsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	reports: Array<Report>
}

export type PaginatedTagsResponse = {
	__typename?: "PaginatedTagsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	tags: Array<ExtendedTag>
}

export type PaginatedUserGroupsResponse = {
	__typename?: "PaginatedUserGroupsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	userGroups: Array<ExtendedUserGroup>
}

export type PaginatedUserLocationRecordsResponse = {
	__typename?: "PaginatedUserLocationRecordsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	userLocationRecords: Array<ExtendedUserLocationRecord>
}

export type PaginatedUserRoleGroupsResponse = {
	__typename?: "PaginatedUserRoleGroupsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	userRoleGroups: Array<ExtendedUserRoleGroup>
}

export type PaginatedUserRouteRecordsResponse = {
	__typename?: "PaginatedUserRouteRecordsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	userRouteRecords: Array<ExtendedUserRouteRecord>
}

export type PaginatedUserTypesResponse = {
	__typename?: "PaginatedUserTypesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	userTypes: Array<UserType>
}

export type PaginatedUsersResponse = {
	__typename?: "PaginatedUsersResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	users: Array<ExtendedUser>
}

export type PaginatedWorkShiftsResponse = {
	__typename?: "PaginatedWorkShiftsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	workShifts: Array<WorkShift>
}

export type PaginatedZoneGroupsResponse = {
	__typename?: "PaginatedZoneGroupsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	zoneGroups: Array<ExtendedZoneGroup>
}

export type PaginatedZonesResponse = {
	__typename?: "PaginatedZonesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	zones: Array<ExtendedZone>
}

export type PartialExtendedUser = {
	__typename?: "PartialExtendedUser"
	_id?: Maybe<Scalars["String"]>
	address?: Maybe<Scalars["String"]>
	blacklistRemarks?: Maybe<Scalars["String"]>
	email?: Maybe<Email>
	fcmTokens?: Maybe<Array<Scalars["String"]>>
	isBlacklisted?: Maybe<Scalars["Boolean"]>
	name?: Maybe<Scalars["String"]>
	permissionsGroupIds?: Maybe<Array<Scalars["String"]>>
	phone?: Maybe<Phone>
	picture?: Maybe<Picture>
	referenceNo?: Maybe<Scalars["String"]>
	scopedSiteIds: Array<Scalars["String"]>
	site?: Maybe<Site>
	siteId?: Maybe<Scalars["String"]>
	siteType: SiteTypes
	status?: Maybe<UserStatus>
	tag?: Maybe<Tag>
	tagId?: Maybe<Scalars["String"]>
	type?: Maybe<UserType>
	typeId?: Maybe<Scalars["String"]>
	username?: Maybe<Scalars["String"]>
}

export type PatrollingRouteInput = {
	label: LabelInput
	maxTime: Scalars["Float"]
	minTime: Scalars["Float"]
	zoneIds: Array<Scalars["String"]>
}

export type PatrollingUserDashboardStatsResponse = {
	__typename?: "PatrollingUserDashboardStatsResponse"
	assignedRoute: Scalars["String"]
	completedRoundsWithTimeTaken: Array<Scalars["Float"]>
	expectedRounds: Scalars["Float"]
	user: User
}

export type PermissionGroupsTranslation = {
	__typename?: "PermissionGroupsTranslation"
	name: Scalars["String"]
}

export type PermissionGroupsTranslationObject = {
	__typename?: "PermissionGroupsTranslationObject"
	en: PermissionGroupsTranslation
	es: PermissionGroupsTranslation
	hi: PermissionGroupsTranslation
}

export type PermissionsGroup = {
	__typename?: "PermissionsGroup"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	isPrivateToAdmin?: Maybe<Scalars["Boolean"]>
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	siteType: SiteTypes
	tags: Array<Scalars["String"]>
	translation?: Maybe<PermissionGroupsTranslationObject>
	updatedAt: Scalars["DateTime"]
}

export type PermissionsGroupInput = {
	isPrivateToAdmin?: InputMaybe<Scalars["Boolean"]>
	label: LabelInput
	tags: Array<Scalars["String"]>
}

export type Phone = {
	__typename?: "Phone"
	countryCode: Scalars["String"]
	number: Scalars["String"]
}

export type PhoneInput = {
	countryCode: Scalars["String"]
	number: Scalars["String"]
}

export type Picture = {
	__typename?: "Picture"
	original: PictureObject
	variants?: Maybe<PictureVariants>
}

export type PictureObject = {
	__typename?: "PictureObject"
	height?: Maybe<Scalars["Float"]>
	key: Scalars["String"]
	url: Scalars["String"]
	width?: Maybe<Scalars["Float"]>
}

export type PictureObjectInput = {
	key: Scalars["String"]
	url: Scalars["String"]
}

export type PictureVariants = {
	__typename?: "PictureVariants"
	large?: Maybe<PictureObject>
	medium?: Maybe<PictureObject>
	small?: Maybe<PictureObject>
	thumbnail?: Maybe<PictureObject>
}

export type Position = {
	__typename?: "Position"
	leftPercent: Scalars["Float"]
	topPercent: Scalars["Float"]
}

export type Query = {
	__typename?: "Query"
	activeRestrictedEntries: ActiveRestrictedUserLocationRecordsResponse
	activeUsers: ActiveUsersResponse
	alertById: ExtendedAlert
	alertFlowById?: Maybe<ExtendedAlertFlow>
	alertFlows: Array<AlertFlow>
	alertFlowsByKeyword: PaginatedAlertFlowsResponse
	alertFlowsTranslation?: Maybe<Array<AlertFlow>>
	alertTargetGroupById: ExtendedAlertTargetGroup
	alertTargetGroupTranslation?: Maybe<Array<AlertTargetGroup>>
	alertTargetGroups: Array<AlertTargetGroup>
	alertTargetGroupsByKeyword: PaginatedAlertTargetGroupsResponse
	alertTranslation?: Maybe<Array<Alert>>
	alertsByFilter: PaginatedAlertsResponse
	allAlertFlows: PaginatedAlertFlowsResponse
	allAlertTargetGroups: PaginatedAlertTargetGroupsResponse
	allAlerts: PaginatedAlertsResponse
	allDepartments: PaginatedDepartmentsResponse
	allMobileApps: PaginatedMobileAppsResponse
	allPatrollingRoutes: PaginatedPatrollingRoutesResponse
	allPermissionsGroups: PaginatedPermissionsGroupsResponse
	allReaders: PaginatedReadersResponse
	allReports: PaginatedReportsResponse
	allServices: Array<Service>
	allTags: PaginatedTagsResponse
	allUserGroups: PaginatedUserGroupsResponse
	allUserRoleGroups: PaginatedUserRoleGroupsResponse
	allUserTypes: PaginatedUserTypesResponse
	allUsers: PaginatedUsersResponse
	allWorkShifts: PaginatedWorkShiftsResponse
	allZoneGroups: PaginatedZoneGroupsResponse
	allZones: PaginatedZonesResponse
	attendanceStats: DemoUserAttendanceStatsResponse
	checkForMobileAppUpdate: MobileApp
	contractors: Array<DemoContractor>
	departmentById: ExtendedDepartment
	departmentTranslation?: Maybe<Array<Department>>
	departments: Array<Department>
	departmentsByKeyword: PaginatedDepartmentsResponse
	lastWeekRestrictedUserLocationRecords: Array<ExtendedUserLocationRecord>
	lastestMobileApp: MobileApp
	me?: Maybe<MeUser>
	mobileApps: Array<MobileApp>
	mobileAppsByFilter: PaginatedMobileAppsResponse
	patrollingRouteById: ExtendedPatrollingRoute
	patrollingRoutes: Array<ExtendedPatrollingRoute>
	patrollingRoutesByKeyword: PaginatedPatrollingRoutesResponse
	patrollingUsersDashboardStats: Array<PatrollingUserDashboardStatsResponse>
	permissionTags: Array<Scalars["String"]>
	permissionsGroupById: PermissionsGroup
	permissionsGroupTranslation?: Maybe<Array<PermissionsGroup>>
	permissionsGroups: Array<PermissionsGroup>
	permissionsGroupsByKeyword: PaginatedPermissionsGroupsResponse
	previousWeekRestrictedUserLocationRecords: Array<ExtendedUserLocationRecord>
	readerById?: Maybe<Reader>
	readerTranslation?: Maybe<Array<Reader>>
	readers: Array<Reader>
	readersAtZone: Array<Reader>
	readersByFilter: PaginatedReadersResponse
	reportById?: Maybe<ExtendedReport>
	reportsByKeyword: PaginatedReportsResponse
	restrictedUserLocationRecordsAtZone: Array<ExtendedUserLocationRecord>
	rootTile?: Maybe<Tile>
	serviceById: Service
	servicesByKeyword: Array<Service>
	statusLogsOfReader: PaginatedReaderStatusLogsResponse
	tagById?: Maybe<ExtendedTag>
	tagTranslation?: Maybe<Array<Tag>>
	tagsByFilter: PaginatedTagsResponse
	tileById?: Maybe<Tile>
	tileEmbeddedZonesByTileId: Array<ExtendedTileEmbeddedZone>
	tiles: Array<Tile>
	todayAttendanceStats: UserAttendanceStatsResponse
	todayPastUserLocationRecords: Array<ExtendedUserLocationRecord>
	todayRestrictedUserLocationRecords: Array<Scalars["String"]>
	todayTrackedUserIds: Array<Scalars["String"]>
	todaysShiftWiseAttendanceStats: UserShiftWiseAttendanceStatsResponse
	todaysUserAttendance?: Maybe<ExtendedUserAttendance>
	userActiveRouteRecord?: Maybe<ExtendedUserRouteRecord>
	userById?: Maybe<ExtendedUser>
	userCurrentLocationRecord?: Maybe<ExtendedUserLocationRecord>
	userCurrentRouteRecord?: Maybe<ExtendedUserRouteRecord>
	userCurrentUserRole?: Maybe<ExtendedUserRole>
	userGroupById: ExtendedUserGroup
	userGroups: Array<ExtendedUserGroup>
	userGroupsByKeyword: PaginatedUserGroupsResponse
	userLocationRecordsAtZone: Array<ExtendedUserLocationRecord>
	userLocationStats: Array<UserLocationStatsResponse>
	userPastLocationRecords: PaginatedUserLocationRecordsResponse
	userPastRouteRecords: PaginatedUserRouteRecordsResponse
	userPermissionsGroups?: Maybe<Array<PermissionsGroup>>
	userRoleGroupById?: Maybe<ExtendedUserRoleGroup>
	userRoleGroupTranslation?: Maybe<Array<UserRoleGroup>>
	userRoleGroups: Array<UserRoleGroup>
	userRoleGroupsByKeyword: PaginatedUserRoleGroupsResponse
	userTranslation?: Maybe<Array<User>>
	userTypeById: UserType
	userTypeTranslation?: Maybe<Array<UserType>>
	userTypes: Array<UserType>
	userTypesByKeyword: PaginatedUserTypesResponse
	userUpdateLogs: Array<ExtendedUserUpdateLog>
	users: PaginatedUsersResponse
	usersByCurrentUserRoleGroup: PaginatedUsersResponse
	usersByDepartment: PaginatedUsersResponse
	usersByKeyword: PaginatedUsersResponse
	usersByType: PaginatedUsersResponse
	usersByWorkShift: PaginatedUsersResponse
	workShiftById: ExtendedWorkShift
	workShiftTranslation?: Maybe<Array<WorkShift>>
	workShifts: Array<WorkShift>
	workShiftsByKeyword: PaginatedWorkShiftsResponse
	zoneById: ExtendedZone
	zoneGroupById: ExtendedZoneGroup
	zoneGroups: Array<ExtendedZoneGroup>
	zoneGroupsByFilter: Array<ExtendedZoneGroup>
	zoneGroupsByKeyword: PaginatedZoneGroupsResponse
	zoneStats: Array<ZoneStats>
	zoneTranslation?: Maybe<Array<Zone>>
	zones: Array<Zone>
	zonesByFilter: PaginatedZonesResponse
	zonesByKeyword: PaginatedZonesResponse
}

export type QueryalertByIdArgs = {
	alertId: Scalars["String"]
}

export type QueryalertFlowByIdArgs = {
	alertFlowId: Scalars["String"]
}

export type QueryalertFlowsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryalertTargetGroupByIdArgs = {
	alertTargetGroupId: Scalars["String"]
}

export type QueryalertTargetGroupsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryalertsByFilterArgs = {
	filter: AlertsFilterInput
	pagination: PaginatedInput
}

export type QueryallAlertFlowsArgs = {
	pagination: PaginatedInput
}

export type QueryallAlertTargetGroupsArgs = {
	pagination: PaginatedInput
}

export type QueryallAlertsArgs = {
	pagination: PaginatedInput
}

export type QueryallDepartmentsArgs = {
	pagination: PaginatedInput
}

export type QueryallMobileAppsArgs = {
	pagination: PaginatedInput
}

export type QueryallPatrollingRoutesArgs = {
	pagination: PaginatedInput
}

export type QueryallPermissionsGroupsArgs = {
	pagination: PaginatedInput
}

export type QueryallReadersArgs = {
	pagination: PaginatedInput
}

export type QueryallReportsArgs = {
	pagination: PaginatedInput
}

export type QueryallTagsArgs = {
	pagination: PaginatedInput
}

export type QueryallUserGroupsArgs = {
	pagination: PaginatedInput
}

export type QueryallUserRoleGroupsArgs = {
	pagination: PaginatedInput
}

export type QueryallUserTypesArgs = {
	pagination: PaginatedInput
}

export type QueryallUsersArgs = {
	pagination: PaginatedInput
}

export type QueryallWorkShiftsArgs = {
	pagination: PaginatedInput
}

export type QueryallZoneGroupsArgs = {
	pagination: PaginatedInput
}

export type QueryallZonesArgs = {
	pagination: PaginatedInput
}

export type QuerycheckForMobileAppUpdateArgs = {
	version: Scalars["String"]
}

export type QuerydepartmentByIdArgs = {
	departmentId: Scalars["String"]
}

export type QuerydepartmentsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerymobileAppsByFilterArgs = {
	filter: MobileAppsFilterInput
	pagination: PaginatedInput
}

export type QuerypatrollingRouteByIdArgs = {
	patrollingRouteId: Scalars["String"]
}

export type QuerypatrollingRoutesByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerypermissionsGroupByIdArgs = {
	permissionsGroupId: Scalars["String"]
}

export type QuerypermissionsGroupsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryreaderByIdArgs = {
	readerId: Scalars["String"]
}

export type QueryreadersAtZoneArgs = {
	zoneId: Scalars["String"]
}

export type QueryreadersByFilterArgs = {
	filter: ReadersFilterInput
	pagination: PaginatedInput
}

export type QueryreportByIdArgs = {
	reportId: Scalars["String"]
}

export type QueryreportsByKeywordArgs = {
	endDate?: InputMaybe<Scalars["DateTime"]>
	keyword: Scalars["String"]
	pagination: PaginatedInput
	startDate?: InputMaybe<Scalars["DateTime"]>
	status?: InputMaybe<Scalars["String"]>
	type?: InputMaybe<Scalars["String"]>
}

export type QueryrestrictedUserLocationRecordsAtZoneArgs = {
	zoneId: Scalars["String"]
}

export type QueryserviceByIdArgs = {
	serviceId: Scalars["String"]
}

export type QueryservicesByKeywordArgs = {
	keyword: Scalars["String"]
}

export type QuerystatusLogsOfReaderArgs = {
	date: Scalars["DateTime"]
	pagination: PaginatedInput
	readerId: Scalars["String"]
}

export type QuerytagByIdArgs = {
	tagId: Scalars["String"]
}

export type QuerytagsByFilterArgs = {
	filter: TagsFilterInput
	pagination: PaginatedInput
}

export type QuerytileByIdArgs = {
	tileId: Scalars["String"]
}

export type QuerytileEmbeddedZonesByTileIdArgs = {
	tileId: Scalars["String"]
}

export type QuerytodayAttendanceStatsArgs = {
	date: Scalars["DateTime"]
}

export type QuerytodaysShiftWiseAttendanceStatsArgs = {
	date: Scalars["DateTime"]
}

export type QuerytodaysUserAttendanceArgs = {
	userId: Scalars["String"]
}

export type QueryuserActiveRouteRecordArgs = {
	userId: Scalars["String"]
}

export type QueryuserByIdArgs = {
	userId: Scalars["String"]
}

export type QueryuserCurrentLocationRecordArgs = {
	userId: Scalars["String"]
}

export type QueryuserCurrentRouteRecordArgs = {
	userId: Scalars["String"]
}

export type QueryuserCurrentUserRoleArgs = {
	userId: Scalars["String"]
}

export type QueryuserGroupByIdArgs = {
	userGroupId: Scalars["String"]
}

export type QueryuserGroupsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryuserLocationRecordsAtZoneArgs = {
	userTypeIds?: InputMaybe<Array<Scalars["String"]>>
	zoneId: Scalars["String"]
}

export type QueryuserLocationStatsArgs = {
	endTimestamp: Scalars["DateTime"]
	startTimestamp: Scalars["DateTime"]
	userId: Scalars["String"]
}

export type QueryuserPastLocationRecordsArgs = {
	date: Scalars["DateTime"]
	pagination: PaginatedInput
	userId: Scalars["String"]
}

export type QueryuserPastRouteRecordsArgs = {
	date: Scalars["DateTime"]
	pagination: PaginatedInput
	userId: Scalars["String"]
}

export type QueryuserPermissionsGroupsArgs = {
	userId: Scalars["String"]
}

export type QueryuserRoleGroupByIdArgs = {
	userRoleGroupId: Scalars["String"]
}

export type QueryuserRoleGroupsArgs = {
	type?: InputMaybe<UserRoleTypes>
}

export type QueryuserRoleGroupsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryuserTypeByIdArgs = {
	userTypeId: Scalars["String"]
}

export type QueryuserTypesByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryuserUpdateLogsArgs = {
	endDate?: InputMaybe<Scalars["DateTime"]>
	startDate?: InputMaybe<Scalars["DateTime"]>
	updatedByUserId?: InputMaybe<Scalars["String"]>
}

export type QueryusersArgs = {
	filter: UsersFilterInput
	pagination: PaginatedInput
}

export type QueryusersByCurrentUserRoleGroupArgs = {
	pagination: PaginatedInput
	userRoleGroupId: Scalars["String"]
}

export type QueryusersByDepartmentArgs = {
	departmentId: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryusersByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
	typeId?: InputMaybe<Scalars["String"]>
}

export type QueryusersByTypeArgs = {
	pagination: PaginatedInput
	typeId: Scalars["String"]
}

export type QueryusersByWorkShiftArgs = {
	pagination: PaginatedInput
	workShiftId: Scalars["String"]
}

export type QueryworkShiftByIdArgs = {
	workShiftId: Scalars["String"]
}

export type QueryworkShiftsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryzoneByIdArgs = {
	zoneId: Scalars["String"]
}

export type QueryzoneGroupByIdArgs = {
	zoneGroupId: Scalars["String"]
}

export type QueryzoneGroupsByFilterArgs = {
	filter: ZoneGroupsFilterInput
}

export type QueryzoneGroupsByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QueryzoneStatsArgs = {
	endTimestamp: Scalars["DateTime"]
	startTimestamp: Scalars["DateTime"]
	zoneId: Scalars["String"]
}

export type QueryzonesByFilterArgs = {
	filter: ZonesFilterInput
	pagination: PaginatedInput
}

export type QueryzonesByKeywordArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type Reader = {
	__typename?: "Reader"
	_id: Scalars["String"]
	assignedAt?: Maybe<Scalars["DateTime"]>
	assignedToZoneId?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	isActive?: Maybe<Scalars["Boolean"]>
	label: Label
	lastConnectedAt?: Maybe<Scalars["DateTime"]>
	lastConnectedEndpoint?: Maybe<Scalars["String"]>
	lastHeartbeatAt?: Maybe<Scalars["DateTime"]>
	readerDecimalId: Scalars["String"]
	readerHexId: Scalars["String"]
	siteId: Scalars["String"]
	siteType: SiteTypes
	stockReaderId: Scalars["String"]
	translation?: Maybe<ReadersTranslationObject>
	type: ReaderTypes
	updatedAt: Scalars["DateTime"]
}

export type ReaderInput = {
	label: LabelInput
	readerDecimalId: Scalars["String"]
	type: ReaderTypes
}

export enum ReaderStatus {
	Active = "Active",
	Inactive = "Inactive",
}

export type ReaderStatusLog = {
	__typename?: "ReaderStatusLog"
	_id: Scalars["String"]
	connectedAt: Scalars["DateTime"]
	createdAt: Scalars["DateTime"]
	disconnectedAt?: Maybe<Scalars["DateTime"]>
	isActive?: Maybe<Scalars["Boolean"]>
	readerId: Scalars["String"]
	siteId: Scalars["String"]
	siteType?: Maybe<SiteTypes>
	updatedAt: Scalars["DateTime"]
}

export enum ReaderTypes {
	Indoor = "Indoor",
	Outdoor = "Outdoor",
}

export type ReadersFilterInput = {
	keyword?: InputMaybe<Scalars["String"]>
	status?: InputMaybe<ReaderStatus>
	type?: InputMaybe<ReaderTypes>
}

export type ReadersStatusLogsReportInput = {
	label: LabelInput
	readerIds: Array<Scalars["String"]>
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
}

export type ReadersTranslation = {
	__typename?: "ReadersTranslation"
	name: Scalars["String"]
}

export type ReadersTranslationObject = {
	__typename?: "ReadersTranslationObject"
	en: ReadersTranslation
	es: ReadersTranslation
	hi: ReadersTranslation
}

export type Report = {
	__typename?: "Report"
	_id: Scalars["String"]
	completeJson?: Maybe<ReportObject>
	createdAt: Scalars["DateTime"]
	csv?: Maybe<ReportObject>
	json?: Maybe<ReportObject>
	label: Label
	readerIds?: Maybe<Array<Scalars["String"]>>
	sinceDate: Scalars["DateTime"]
	siteId: Scalars["String"]
	siteType: SiteTypes
	status: ReportStatus
	type: ReportTypes
	untilDate: Scalars["DateTime"]
	updatedAt: Scalars["DateTime"]
	userIds?: Maybe<Array<Scalars["String"]>>
	zoneGroupIds?: Maybe<Array<Scalars["String"]>>
	zoneIds?: Maybe<Array<Scalars["String"]>>
}

export type ReportObject = {
	__typename?: "ReportObject"
	key: Scalars["String"]
	url: Scalars["String"]
}

export enum ReportStatus {
	Failed = "Failed",
	Generated = "Generated",
	Processing = "Processing",
	Queued = "Queued",
}

export enum ReportTypes {
	ComprehensiveUserAttendance = "ComprehensiveUserAttendance",
	ReaderStatusLogs = "ReaderStatusLogs",
	SummarizedUserAttendance = "SummarizedUserAttendance",
	UserLocationRecords = "UserLocationRecords",
	UserPatrollingRoute = "UserPatrollingRoute",
	UserRestrictedEntries = "UserRestrictedEntries",
	ZoneGroupRestrictedEntries = "ZoneGroupRestrictedEntries",
	ZoneRestrictedEntries = "ZoneRestrictedEntries",
}

export type RouteFinding = {
	__typename?: "RouteFinding"
	createdAt: Scalars["DateTime"]
	image: PictureObject
	lastRoutePointIndex: Scalars["Float"]
	remarks: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type RouteFindingInput = {
	image: PictureObjectInput
	remarks: Scalars["String"]
}

export type RoutePointInput = {
	areaImage?: InputMaybe<PictureObjectInput>
	areaIntact?: InputMaybe<Scalars["Boolean"]>
	guardImage?: InputMaybe<PictureObjectInput>
	guardOnDuty?: InputMaybe<Scalars["Boolean"]>
	isSkipped?: InputMaybe<Scalars["Boolean"]>
	skipRemarks?: InputMaybe<Scalars["String"]>
}

export type RouteUserLocationRecord = {
	__typename?: "RouteUserLocationRecord"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	endAt: Scalars["DateTime"]
	isRestricted?: Maybe<Scalars["Boolean"]>
	siteId: Scalars["String"]
	siteType?: Maybe<SiteTypes>
	startAt: Scalars["DateTime"]
	status: UserLocationRecordStatus
	updatedAt: Scalars["DateTime"]
	userId: Scalars["String"]
	zone: Zone
	zoneId: Scalars["String"]
}

export type Service = {
	__typename?: "Service"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	isActive?: Maybe<Scalars["Boolean"]>
	key: Scalars["String"]
	label: Label
	lastHeartbeatAt?: Maybe<Scalars["DateTime"]>
	updatedAt: Scalars["DateTime"]
}

export type ServiceInput = {
	key: Scalars["String"]
	label: LabelInput
}

export type Site = {
	__typename?: "Site"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	manpowerCount?: Maybe<Scalars["Float"]>
	siteType: SiteTypes
	updatedAt: Scalars["DateTime"]
}

export enum SiteTypes {
	JMD = "JMD",
}

export type Subscription = {
	__typename?: "Subscription"
	alertsUpdate: ExtendedAlert
	demoFacialAttendanceUpdate?: Maybe<DemoFacialAttendance>
	readerUpdate: Reader
	readerUpdateAtZone: Reader
	reportUpdate: Report
	restrictedUserLocationRecordsUpdate: ExtendedUserLocationRecord
	userLocationRecordUpdate: ExtendedUserLocationRecord
	userLocationRecordUpdateAtZone: ExtendedUserLocationRecord
	userLocationRecordsUpdate: ExtendedUserLocationRecord
	userRouteRecordsUpdate?: Maybe<ExtendedUserRouteRecord>
}

export type SubscriptionreaderUpdateArgs = {
	readerId: Scalars["String"]
}

export type SubscriptionreaderUpdateAtZoneArgs = {
	zoneId: Scalars["String"]
}

export type SubscriptionreportUpdateArgs = {
	reportId: Scalars["String"]
}

export type SubscriptionrestrictedUserLocationRecordsUpdateArgs = {
	recordIds: Array<Scalars["String"]>
}

export type SubscriptionuserLocationRecordUpdateArgs = {
	userId: Scalars["String"]
}

export type SubscriptionuserLocationRecordUpdateAtZoneArgs = {
	zoneId: Scalars["String"]
}

export type SubscriptionuserRouteRecordsUpdateArgs = {
	userId: Scalars["String"]
}

export type SummarizedUserAttendanceReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
}

export type Tag = {
	__typename?: "Tag"
	_id: Scalars["String"]
	assignedAt?: Maybe<Scalars["DateTime"]>
	assignedToUserId?: Maybe<Scalars["String"]>
	batteryStatus: TagBatteryStatus
	createdAt: Scalars["DateTime"]
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	name?: Maybe<Scalars["String"]>
	siteId: Scalars["String"]
	siteType: SiteTypes
	status: TagStatus
	tagDecimalId: Scalars["String"]
	tagHexId: Scalars["String"]
	translation?: Maybe<TagsTranslationObject>
	updatedAt: Scalars["DateTime"]
}

export enum TagBatteryStatus {
	Low = "Low",
	Normal = "Normal",
}

export enum TagStatus {
	Active = "Active",
	Delivered = "Delivered",
	Expired = "Expired",
	Inactive = "Inactive",
	Personalized = "Personalized",
	Unregistered = "Unregistered",
	Virgin = "Virgin",
}

export type TagsFilterInput = {
	batteryStatus?: InputMaybe<TagBatteryStatus>
	keyword?: InputMaybe<Scalars["String"]>
	status?: InputMaybe<TagStatus>
}

export type TagsTranslation = {
	__typename?: "TagsTranslation"
	batteryStatus?: Maybe<Scalars["String"]>
	status?: Maybe<Scalars["String"]>
}

export type TagsTranslationObject = {
	__typename?: "TagsTranslationObject"
	en: TagsTranslation
	es: TagsTranslation
	hi: TagsTranslation
}

export type Tile = {
	__typename?: "Tile"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	embeddedZoneIds: Array<Scalars["String"]>
	embedsAllZones: Scalars["Boolean"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	picture?: Maybe<Picture>
	siteId: Scalars["String"]
	siteType: SiteTypes
	updatedAt: Scalars["DateTime"]
}

export type Translation = {
	__typename?: "Translation"
	en: UserTranslation
	es: UserTranslation
	hi: UserTranslation
}

export type UpdateUserRoleGroupInput = {
	allowedZoneGroupIds?: InputMaybe<Array<Scalars["String"]>>
	allowedZoneIds?: InputMaybe<Array<Scalars["String"]>>
	departmentId: Scalars["String"]
	label: LabelInput
	workShiftId?: InputMaybe<Scalars["String"]>
}

export type UploadSignatureResponse = {
	__typename?: "UploadSignatureResponse"
	key: Scalars["String"]
	signedUrl: Scalars["String"]
}

export type User = {
	__typename?: "User"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	blacklistRemarks?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	email?: Maybe<Email>
	fcmTokens?: Maybe<Array<Scalars["String"]>>
	isBlacklisted?: Maybe<Scalars["Boolean"]>
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	name: Scalars["String"]
	permissionsGroupIds?: Maybe<Array<Scalars["String"]>>
	phone?: Maybe<Phone>
	picture?: Maybe<Picture>
	referenceNo?: Maybe<Scalars["String"]>
	scopedSiteIds: Array<Scalars["String"]>
	siteId: Scalars["String"]
	siteType: SiteTypes
	status: UserStatus
	tagId?: Maybe<Scalars["String"]>
	translation?: Maybe<Translation>
	typeId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type UserAttendanceStatsResponse = {
	__typename?: "UserAttendanceStatsResponse"
	absent: Array<User>
	present: Array<ExtendedUserAttendance>
}

export type UserAuthResponse = {
	__typename?: "UserAuthResponse"
	errors?: Maybe<Array<FieldError>>
	sessionToken?: Maybe<Scalars["String"]>
	user?: Maybe<MeUser>
}

export type UserGroup = {
	__typename?: "UserGroup"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	siteId: Scalars["String"]
	siteType: SiteTypes
	updatedAt: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
}

export type UserGroupInput = {
	label: LabelInput
	userIds: Array<Scalars["String"]>
}

export type UserInput = {
	address?: InputMaybe<Scalars["String"]>
	email?: InputMaybe<EmailInput>
	name: Scalars["String"]
	phone?: InputMaybe<PhoneInput>
	referenceNo?: InputMaybe<Scalars["String"]>
	typeId: Scalars["String"]
}

export type UserLocationRecord = {
	__typename?: "UserLocationRecord"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	endAt: Scalars["DateTime"]
	isRestricted?: Maybe<Scalars["Boolean"]>
	siteId: Scalars["String"]
	siteType?: Maybe<SiteTypes>
	startAt: Scalars["DateTime"]
	status: UserLocationRecordStatus
	updatedAt: Scalars["DateTime"]
	userId: Scalars["String"]
	zoneId: Scalars["String"]
}

export enum UserLocationRecordStatus {
	Active = "Active",
	Final = "Final",
	Stale = "Stale",
}

export type UserLocationRecordsReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
}

export type UserLocationStatsResponse = {
	__typename?: "UserLocationStatsResponse"
	endAt: Scalars["DateTime"]
	startAt: Scalars["DateTime"]
	zone: UserLocationStatsZone
}

export type UserLocationStatsZone = {
	__typename?: "UserLocationStatsZone"
	_id: Scalars["String"]
	name: Scalars["String"]
}

export type UserPatrollingRouteReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
}

export type UserRestrictedEntriesReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	userIds: Array<Scalars["String"]>
}

export type UserRoleGroup = {
	__typename?: "UserRoleGroup"
	_id: Scalars["String"]
	allowedZoneGroupIds?: Maybe<Array<Scalars["String"]>>
	allowedZoneIds?: Maybe<Array<Scalars["String"]>>
	createdAt: Scalars["DateTime"]
	departmentId: Scalars["String"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	patrollingRouteId?: Maybe<Scalars["String"]>
	rounds?: Maybe<Scalars["Float"]>
	siteId: Scalars["String"]
	siteType: SiteTypes
	translation?: Maybe<UserRoleGroupsTranslationObject>
	type: UserRoleTypes
	updatedAt: Scalars["DateTime"]
	workShiftId?: Maybe<Scalars["String"]>
}

export type UserRoleGroupInput = {
	allowedZoneGroupIds?: InputMaybe<Array<Scalars["String"]>>
	allowedZoneIds?: InputMaybe<Array<Scalars["String"]>>
	departmentId: Scalars["String"]
	label: LabelInput
	patrollingRouteId?: InputMaybe<Scalars["String"]>
	rounds?: InputMaybe<Scalars["Float"]>
	type: UserRoleTypes
	workShiftId?: InputMaybe<Scalars["String"]>
}

export type UserRoleGroupsTranslation = {
	__typename?: "UserRoleGroupsTranslation"
	name?: Maybe<Scalars["String"]>
	type?: Maybe<Scalars["String"]>
}

export type UserRoleGroupsTranslationObject = {
	__typename?: "UserRoleGroupsTranslationObject"
	en: UserRoleGroupsTranslation
	es: UserRoleGroupsTranslation
	hi: UserRoleGroupsTranslation
}

export type UserRoleInput = {
	designation: Scalars["String"]
	endAt?: InputMaybe<Scalars["DateTime"]>
	startAt: Scalars["DateTime"]
	type: UserRoleTypes
	userRoleGroupId: Scalars["String"]
}

export enum UserRoleStatus {
	Current = "Current",
	Expired = "Expired",
	Future = "Future",
	Past = "Past",
}

export enum UserRoleTypes {
	Admin = "Admin",
	Patrolling = "Patrolling",
	Stationary = "Stationary",
}

export type UserShiftWiseAttendanceStatsResponse = {
	__typename?: "UserShiftWiseAttendanceStatsResponse"
	eveningAbsent: Array<User>
	eveningPresent: Array<ExtendedUserAttendance>
	morningAbsent: Array<User>
	morningPresent: Array<ExtendedUserAttendance>
}

export enum UserStatus {
	Active = "Active",
	Inactive = "Inactive",
}

export type UserTranslation = {
	__typename?: "UserTranslation"
	address: Scalars["String"]
	name: Scalars["String"]
	status: Scalars["String"]
}

export type UserType = {
	__typename?: "UserType"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	roleType: UserRoleTypes
	siteId: Scalars["String"]
	siteType: SiteTypes
	translation?: Maybe<UserTypeTranslationObject>
	updatedAt: Scalars["DateTime"]
}

export type UserTypeInput = {
	label: LabelInput
	roleType: UserRoleTypes
}

export type UserTypeTranslation = {
	__typename?: "UserTypeTranslation"
	roleType: Scalars["String"]
}

export type UserTypeTranslationObject = {
	__typename?: "UserTypeTranslationObject"
	en: UserTypeTranslation
	es: UserTypeTranslation
	hi: UserTypeTranslation
}

export type UsersFilterInput = {
	departmentId?: InputMaybe<Scalars["String"]>
	keyword?: InputMaybe<Scalars["String"]>
	userTypeId?: InputMaybe<Scalars["String"]>
}

export type WorkShift = {
	__typename?: "WorkShift"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	endAt: Scalars["String"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	siteId: Scalars["String"]
	siteType: SiteTypes
	startAt: Scalars["String"]
	translation?: Maybe<WorkShiftTranslationObject>
	updatedAt: Scalars["DateTime"]
}

export type WorkShiftInput = {
	endAt: Scalars["String"]
	label: LabelInput
	startAt: Scalars["String"]
}

export type WorkShiftTranslation = {
	__typename?: "WorkShiftTranslation"
	description: Scalars["String"]
	name: Scalars["String"]
}

export type WorkShiftTranslationObject = {
	__typename?: "WorkShiftTranslationObject"
	en: WorkShiftTranslation
	es: WorkShiftTranslation
	hi: WorkShiftTranslation
}

export type Zone = {
	__typename?: "Zone"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	crowdLimit: Scalars["Float"]
	idleSince?: Maybe<Scalars["DateTime"]>
	isActive?: Maybe<Scalars["Boolean"]>
	isCrowded?: Maybe<Scalars["Boolean"]>
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	picture?: Maybe<Picture>
	readerIds?: Maybe<Array<Scalars["String"]>>
	siteId: Scalars["String"]
	siteType: SiteTypes
	translation?: Maybe<ZonesTranslationObject>
	type: ZoneTypes
	updatedAt: Scalars["DateTime"]
}

export type ZoneGroup = {
	__typename?: "ZoneGroup"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	label: Label
	lastUpdatedAt: Scalars["DateTime"]
	lastUpdatedByUserId: Scalars["String"]
	siteId: Scalars["String"]
	siteType: SiteTypes
	updatedAt: Scalars["DateTime"]
	zoneIds: Array<Scalars["String"]>
}

export type ZoneGroupInput = {
	label: LabelInput
	zoneIds: Array<Scalars["String"]>
}

export type ZoneGroupRestrictedEntriesReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	zoneGroupIds: Array<Scalars["String"]>
}

export type ZoneGroupsFilterInput = {
	keyword?: InputMaybe<Scalars["String"]>
}

export type ZoneInput = {
	crowdLimit: Scalars["Float"]
	label: LabelInput
	type: ZoneTypes
}

export type ZoneRestrictedEntriesReportInput = {
	label: LabelInput
	sinceDate: Scalars["DateTime"]
	untilDate: Scalars["DateTime"]
	zoneIds: Array<Scalars["String"]>
}

export type ZoneStats = {
	__typename?: "ZoneStats"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	siteType: SiteTypes
	timeStamp: Scalars["DateTime"]
	updatedAt: Scalars["DateTime"]
	usersCount: Scalars["Int"]
	zoneId: Scalars["String"]
}

export enum ZoneTypes {
	Outdoor = "Outdoor",
}

export type ZoneWiseActiveRestrictedUserLocationRecordsResponse = {
	__typename?: "ZoneWiseActiveRestrictedUserLocationRecordsResponse"
	recordIds: Array<Scalars["String"]>
	zoneId: Scalars["String"]
}

export type ZoneWiseActiveUsersResponse = {
	__typename?: "ZoneWiseActiveUsersResponse"
	userIds: Array<Scalars["String"]>
	zoneId: Scalars["String"]
}

export type ZonesFilterInput = {
	isActive?: InputMaybe<Scalars["Boolean"]>
	keyword?: InputMaybe<Scalars["String"]>
	type?: InputMaybe<ZoneTypes>
}

export type ZonesTranslation = {
	__typename?: "ZonesTranslation"
	name?: Maybe<Scalars["String"]>
	type?: Maybe<Scalars["String"]>
}

export type ZonesTranslationObject = {
	__typename?: "ZonesTranslationObject"
	en: ZonesTranslation
	es: ZonesTranslation
	hi: ZonesTranslation
}

export type AlertFragment = {
	__typename?: "Alert"
	_id: string
	siteId: string
	type: AlertTypes
	alertFlowId: string
	emailMessage: string
	notificationMessage: string
	userId?: string | null
	userRoleId?: string | null
	zoneId?: string | null
	readerId?: string | null
	tagId?: string | null
	userLocationRecordId?: string | null
	userRouteRecordId?: string | null
	toBeEscalated?: boolean | null
	escalateAfter?: any | null
	isResolved?: boolean | null
	createdAt: any
	updatedAt: any
	levels: Array<{
		__typename?: "AlertLevel"
		alertedUserIds?: Array<string> | null
		emailedUserIds?: Array<string> | null
		responses: Array<{ __typename?: "AlertResponse"; userId: string; acknowlegedAt: any; acknowlegment?: string | null; resolvedAt?: any | null; resolution?: string | null }>
	}>
}

export type AlertFlowFragment = {
	__typename?: "AlertFlow"
	_id: string
	siteId: string
	type: AlertTypes
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
	source?: { __typename?: "AlertSource"; type: AlertSourceTypes; zoneIds?: Array<string> | null; zoneGroupIds?: Array<string> | null; userIds?: Array<string> | null; userGroupIds?: Array<string> | null } | null
	levels: Array<{ __typename?: "AlertTargetLevel"; targetGroupIds: Array<string>; timeout: number }>
}

export type AlertLevelFragment = {
	__typename?: "AlertLevel"
	alertedUserIds?: Array<string> | null
	emailedUserIds?: Array<string> | null
	responses: Array<{ __typename?: "AlertResponse"; userId: string; acknowlegedAt: any; acknowlegment?: string | null; resolvedAt?: any | null; resolution?: string | null }>
}

export type AlertResponseFragment = { __typename?: "AlertResponse"; userId: string; acknowlegedAt: any; acknowlegment?: string | null; resolvedAt?: any | null; resolution?: string | null }

export type AlertTargetGroupFragment = { __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }

export type AlertTargetLevelFragment = { __typename?: "AlertTargetLevel"; targetGroupIds: Array<string>; timeout: number }

export type DepartmentFragment = { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }

export type EmailFragment = { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null }

export type ExtendedAlertFragment = {
	__typename?: "ExtendedAlert"
	_id: string
	siteId: string
	type: AlertTypes
	alertFlowId: string
	emailMessage: string
	notificationMessage: string
	userId?: string | null
	userRoleId?: string | null
	zoneId?: string | null
	readerId?: string | null
	tagId?: string | null
	userLocationRecordId?: string | null
	userRouteRecordId?: string | null
	toBeEscalated?: boolean | null
	escalateAfter?: any | null
	isResolved?: boolean | null
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	alertFlow: {
		__typename?: "AlertFlow"
		_id: string
		siteId: string
		type: AlertTypes
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		source?: { __typename?: "AlertSource"; type: AlertSourceTypes; zoneIds?: Array<string> | null; zoneGroupIds?: Array<string> | null; userIds?: Array<string> | null; userGroupIds?: Array<string> | null } | null
		levels: Array<{ __typename?: "AlertTargetLevel"; targetGroupIds: Array<string>; timeout: number }>
	}
	levels: Array<{
		__typename?: "ExtendedAlertLevel"
		alertedUserIds?: Array<string> | null
		emailedUserIds?: Array<string> | null
		alertedUsers?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		emailedUsers?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		responses: Array<{
			__typename?: "ExtendedAlertResponse"
			userId: string
			acknowlegedAt: any
			acknowlegment?: string | null
			resolvedAt?: any | null
			resolution?: string | null
			user: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
		}>
	}>
	user?: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	} | null
	userRole?: {
		__typename?: "ExtendedUserRole"
		_id: string
		siteId: string
		userId: string
		type: UserRoleTypes
		userRoleGroupId: string
		designation?: string | null
		startAt: any
		endAt?: any | null
		assignedAt: any
		removedAt?: any | null
		status: UserRoleStatus
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		userRoleGroup: {
			__typename?: "ExtendedUserRoleGroup"
			_id: string
			siteId: string
			type: UserRoleTypes
			allowedZoneIds?: Array<string> | null
			allowedZoneGroupIds?: Array<string> | null
			patrollingRouteId?: string | null
			rounds?: number | null
			departmentId: string
			workShiftId?: string | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			allowedZones?: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
			patrollingRoute?: {
				__typename?: "ExtendedPatrollingRoute"
				_id: string
				siteId: string
				zoneIds: Array<string>
				minTime: number
				maxTime: number
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				label: { __typename?: "Label"; name: string; description?: string | null }
				zones: Array<{
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}>
			} | null
			department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
		}
	} | null
	zone?: {
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	} | null
	reader?: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	} | null
	tag?: {
		__typename?: "ExtendedTag"
		_id: string
		name?: string | null
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
		user?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	} | null
	userLocationRecord?: {
		__typename?: "ExtendedUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		user: {
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}
	} | null
	userRouteRecord?: {
		__typename?: "ExtendedUserRouteRecord"
		_id: string
		siteId: string
		userId: string
		startAt: any
		endAt: any
		patrollingRouteId: string
		expectedRouteZoneIds: Array<string>
		expectedMinTimeInMinutes: number
		expectedMaxTimeInMinutes: number
		locationRecordIds?: Array<string> | null
		isRouteCorrect?: boolean | null
		isTimeCorrect?: boolean | null
		isTimeHigh?: boolean | null
		isTimeLow?: boolean | null
		isFinal?: boolean | null
		isPartiallyFinal?: boolean | null
		timeTakenInMinutes: number
		createdAt: any
		updatedAt: any
		expectedRouteZones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
		locationRecords?: Array<{
			__typename?: "RouteUserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
		}> | null
		routePoints?: Array<{
			__typename?: "ExtendedRoutePoint"
			guardOnDuty?: boolean | null
			areaIntact?: boolean | null
			isSkipped?: boolean | null
			skipRemarks?: string | null
			zoneId: string
			locationRecordId?: string | null
			createdAt: any
			updatedAt: any
			guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
			areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			locationRecord?: {
				__typename?: "UserLocationRecord"
				_id: string
				siteId: string
				zoneId: string
				userId: string
				startAt: any
				endAt: any
				status: UserLocationRecordStatus
				isRestricted?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
		}> | null
		findings?: Array<{ __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }> | null
	} | null
}

export type ExtendedAlertFlowFragment = {
	__typename?: "ExtendedAlertFlow"
	_id: string
	siteId: string
	type: AlertTypes
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
	source?: {
		__typename?: "ExtendedAlertSource"
		type: AlertSourceTypes
		zoneIds?: Array<string> | null
		zoneGroupIds?: Array<string> | null
		userIds?: Array<string> | null
		userGroupIds?: Array<string> | null
		zones?: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		zoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
		users?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		userGroups?: Array<{ __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
	} | null
	levels: Array<{
		__typename?: "ExtendedAlertTargetLevel"
		targetGroupIds: Array<string>
		timeout: number
		targetGroups: Array<{ __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}>
}

export type ExtendedAlertLevelFragment = {
	__typename?: "ExtendedAlertLevel"
	alertedUserIds?: Array<string> | null
	emailedUserIds?: Array<string> | null
	alertedUsers?: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}> | null
	emailedUsers?: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}> | null
	responses: Array<{
		__typename?: "ExtendedAlertResponse"
		userId: string
		acknowlegedAt: any
		acknowlegment?: string | null
		resolvedAt?: any | null
		resolution?: string | null
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
	}>
}

export type ExtendedAlertResponseFragment = {
	__typename?: "ExtendedAlertResponse"
	userId: string
	acknowlegedAt: any
	acknowlegment?: string | null
	resolvedAt?: any | null
	resolution?: string | null
	user: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}
}

export type ExtendedAlertTargetGroupFragment = {
	__typename?: "ExtendedAlertTargetGroup"
	_id: string
	siteId: string
	userIds: Array<string>
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
	users: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}>
}

export type ExtendedAlertTargetLevelFragment = {
	__typename?: "ExtendedAlertTargetLevel"
	targetGroupIds: Array<string>
	timeout: number
	targetGroups: Array<{ __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type ExtendedDepartmentFragment = {
	__typename?: "ExtendedDepartment"
	_id: string
	siteId: string
	zoneIds: Array<string>
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
	zones: Array<{
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}>
}

export type ExtendedPatrollingRouteFragment = {
	__typename?: "ExtendedPatrollingRoute"
	_id: string
	siteId: string
	zoneIds: Array<string>
	minTime: number
	maxTime: number
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
	zones: Array<{
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}>
}

export type ExtendedReportFragment = {
	__typename?: "ExtendedReport"
	_id: string
	siteId: string
	type: ReportTypes
	userIds?: Array<string> | null
	sinceDate: any
	untilDate: any
	status: ReportStatus
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
	users?: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}> | null
	completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
	json?: { __typename?: "ReportObject"; key: string; url: string } | null
	csv?: { __typename?: "ReportObject"; key: string; url: string } | null
	lastUpdatedByUser?: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	} | null
}

export type ExtendedRoutePointFragment = {
	__typename?: "ExtendedRoutePoint"
	guardOnDuty?: boolean | null
	areaIntact?: boolean | null
	isSkipped?: boolean | null
	skipRemarks?: string | null
	zoneId: string
	locationRecordId?: string | null
	createdAt: any
	updatedAt: any
	guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
	areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
	zone: {
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}
	locationRecord?: {
		__typename?: "UserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
	} | null
}

export type ExtendedTagFragment = {
	__typename?: "ExtendedTag"
	_id: string
	name?: string | null
	tagHexId: string
	tagDecimalId: string
	status: TagStatus
	batteryStatus: TagBatteryStatus
	assignedToUserId?: string | null
	assignedAt?: any | null
	createdAt: any
	updatedAt: any
	user?: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	} | null
}

export type ExtendedTileEmbeddedZoneFragment = {
	__typename?: "ExtendedTileEmbeddedZone"
	_id: string
	siteId: string
	tileId: string
	zoneId: string
	position: { __typename?: "Position"; topPercent: number; leftPercent: number }
	zone: {
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}
}

export type ExtendedUserFragment = {
	__typename?: "ExtendedUser"
	_id: string
	siteId: string
	scopedSiteIds: Array<string>
	name: string
	username: string
	address?: string | null
	typeId: string
	referenceNo?: string | null
	tagId?: string | null
	isBlacklisted?: boolean | null
	blacklistRemarks?: string | null
	status: UserStatus
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
	phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
	email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
	picture?: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
		} | null
	} | null
	type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
	tag?: {
		__typename?: "Tag"
		_id: string
		name?: string | null
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	} | null
}

export type ExtendedUserAttendanceFragment = {
	__typename?: "ExtendedUserAttendance"
	_id: string
	siteId: string
	userId: string
	inAt: any
	outAt?: any | null
	inImage?: string | null
	inRemarks?: string | null
	outRemarks?: string | null
	outImage?: string | null
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	user: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}
}

export type ExtendedUserGroupFragment = {
	__typename?: "ExtendedUserGroup"
	_id: string
	siteId: string
	userIds: Array<string>
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
	users: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}>
}

export type ExtendedUserLocationRecordFragment = {
	__typename?: "ExtendedUserLocationRecord"
	_id: string
	siteId: string
	zoneId: string
	userId: string
	startAt: any
	endAt: any
	status: UserLocationRecordStatus
	isRestricted?: boolean | null
	createdAt: any
	updatedAt: any
	zone: {
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}
	user: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type ExtendedUserRoleFragment = {
	__typename?: "ExtendedUserRole"
	_id: string
	siteId: string
	userId: string
	type: UserRoleTypes
	userRoleGroupId: string
	designation?: string | null
	startAt: any
	endAt?: any | null
	assignedAt: any
	removedAt?: any | null
	status: UserRoleStatus
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	user: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}
	userRoleGroup: {
		__typename?: "ExtendedUserRoleGroup"
		_id: string
		siteId: string
		type: UserRoleTypes
		allowedZoneIds?: Array<string> | null
		allowedZoneGroupIds?: Array<string> | null
		patrollingRouteId?: string | null
		rounds?: number | null
		departmentId: string
		workShiftId?: string | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		allowedZones?: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
		patrollingRoute?: {
			__typename?: "ExtendedPatrollingRoute"
			_id: string
			siteId: string
			zoneIds: Array<string>
			minTime: number
			maxTime: number
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			zones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		} | null
		department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
	}
}

export type ExtendedUserRoleGroupFragment = {
	__typename?: "ExtendedUserRoleGroup"
	_id: string
	siteId: string
	type: UserRoleTypes
	allowedZoneIds?: Array<string> | null
	allowedZoneGroupIds?: Array<string> | null
	patrollingRouteId?: string | null
	rounds?: number | null
	departmentId: string
	workShiftId?: string | null
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
	allowedZones?: Array<{
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}> | null
	allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
	patrollingRoute?: {
		__typename?: "ExtendedPatrollingRoute"
		_id: string
		siteId: string
		zoneIds: Array<string>
		minTime: number
		maxTime: number
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	} | null
	department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
}

export type ExtendedUserRouteRecordFragment = {
	__typename?: "ExtendedUserRouteRecord"
	_id: string
	siteId: string
	userId: string
	startAt: any
	endAt: any
	patrollingRouteId: string
	expectedRouteZoneIds: Array<string>
	expectedMinTimeInMinutes: number
	expectedMaxTimeInMinutes: number
	locationRecordIds?: Array<string> | null
	isRouteCorrect?: boolean | null
	isTimeCorrect?: boolean | null
	isTimeHigh?: boolean | null
	isTimeLow?: boolean | null
	isFinal?: boolean | null
	isPartiallyFinal?: boolean | null
	timeTakenInMinutes: number
	createdAt: any
	updatedAt: any
	expectedRouteZones: Array<{
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}>
	locationRecords?: Array<{
		__typename?: "RouteUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
	}> | null
	routePoints?: Array<{
		__typename?: "ExtendedRoutePoint"
		guardOnDuty?: boolean | null
		areaIntact?: boolean | null
		isSkipped?: boolean | null
		skipRemarks?: string | null
		zoneId: string
		locationRecordId?: string | null
		createdAt: any
		updatedAt: any
		guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
		areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		locationRecord?: {
			__typename?: "UserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
		} | null
	}> | null
	findings?: Array<{ __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }> | null
}

export type ExtendedWorkShiftFragment = {
	__typename?: "ExtendedWorkShift"
	_id: string
	siteId: string
	startAt: string
	endAt: string
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
}

export type ExtendedZoneFragment = {
	__typename?: "ExtendedZone"
	_id: string
	siteId: string
	readerIds?: Array<string> | null
	type: ZoneTypes
	isActive?: boolean | null
	isCrowded?: boolean | null
	crowdLimit: number
	idleSince?: any | null
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
	picture?: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
		} | null
	} | null
}

export type ExtendedZoneGroupFragment = {
	__typename?: "ExtendedZoneGroup"
	_id: string
	siteId: string
	zoneIds: Array<string>
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	label: { __typename?: "Label"; name: string; description?: string | null }
	zones: Array<{
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}>
}

export type FieldErrorFragment = { __typename?: "FieldError"; field: string; error: string }

export type LabelFragment = { __typename?: "Label"; name: string; description?: string | null }

export type MeUserFragment = {
	__typename?: "MeUser"
	_id: string
	siteId: string
	scopedSiteIds: Array<string>
	name: string
	username: string
	address?: string | null
	isBlacklisted?: boolean | null
	blacklistRemarks?: string | null
	typeId: string
	referenceNo?: string | null
	tagId?: string | null
	permissionsGroupIds?: Array<string> | null
	status: UserStatus
	createdAt: any
	updatedAt: any
	site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
	scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
	phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
	email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
	picture?: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
		} | null
	} | null
	type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
	tag?: {
		__typename?: "Tag"
		_id: string
		name?: string | null
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	} | null
	permissionsGroups?: Array<{ __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
}

export type MobileAppFragment = { __typename?: "MobileApp"; _id: string; type: MobileAppTypes; version: string; changes: string; url: string; createdAt: any; updatedAt: any }

export type PatrollingUserDashboardStatsResponseFragment = {
	__typename?: "PatrollingUserDashboardStatsResponse"
	completedRoundsWithTimeTaken: Array<number>
	expectedRounds: number
	assignedRoute: string
	user: {
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}
}

export type PermissionsGroupFragment = { __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }

export type PictureFragment = {
	__typename?: "Picture"
	original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
	variants?: {
		__typename?: "PictureVariants"
		small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
		medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
		large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
		thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
	} | null
}

export type PictureObjectFragment = { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }

export type ReaderFragment = {
	__typename?: "Reader"
	_id: string
	siteId: string
	type: ReaderTypes
	readerHexId: string
	readerDecimalId: string
	assignedToZoneId?: string | null
	assignedAt?: any | null
	isActive?: boolean | null
	lastConnectedEndpoint?: string | null
	lastConnectedAt?: any | null
	lastHeartbeatAt?: any | null
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
}

export type ReaderStatusLogFragment = { __typename?: "ReaderStatusLog"; _id: string; siteId: string; readerId: string; isActive?: boolean | null; connectedAt: any; disconnectedAt?: any | null; createdAt: any; updatedAt: any }

export type ReportFragment = {
	__typename?: "Report"
	_id: string
	siteId: string
	type: ReportTypes
	userIds?: Array<string> | null
	sinceDate: any
	untilDate: any
	status: ReportStatus
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
	completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
	json?: { __typename?: "ReportObject"; key: string; url: string } | null
	csv?: { __typename?: "ReportObject"; key: string; url: string } | null
}

export type RouteFindingFragment = { __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }

export type RouteUserLocationRecordFragment = {
	__typename?: "RouteUserLocationRecord"
	_id: string
	siteId: string
	zoneId: string
	userId: string
	startAt: any
	endAt: any
	status: UserLocationRecordStatus
	isRestricted?: boolean | null
	createdAt: any
	updatedAt: any
	zone: {
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}
}

export type ServiceFragment = {
	__typename?: "Service"
	_id: string
	key: string
	lastHeartbeatAt?: any | null
	isActive?: boolean | null
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
}

export type SiteFragment = { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }

export type TagFragment = {
	__typename?: "Tag"
	_id: string
	name?: string | null
	tagHexId: string
	tagDecimalId: string
	status: TagStatus
	batteryStatus: TagBatteryStatus
	assignedToUserId?: string | null
	assignedAt?: any | null
	createdAt: any
	updatedAt: any
}

export type TileFragment = {
	__typename?: "Tile"
	_id: string
	embeddedZoneIds: Array<string>
	embedsAllZones: boolean
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
	picture?: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
		} | null
	} | null
}

export type UserFragment = {
	__typename?: "User"
	_id: string
	siteId: string
	name: string
	username: string
	address?: string | null
	isBlacklisted?: boolean | null
	blacklistRemarks?: string | null
	typeId: string
	referenceNo?: string | null
	tagId?: string | null
	status: UserStatus
	createdAt: any
	updatedAt: any
	phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
	email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
	picture?: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
		} | null
	} | null
}

export type UserAttendanceStatsResponseFragment = {
	__typename?: "UserAttendanceStatsResponse"
	present: Array<{
		__typename?: "ExtendedUserAttendance"
		_id: string
		siteId: string
		userId: string
		inAt: any
		outAt?: any | null
		inImage?: string | null
		inRemarks?: string | null
		outRemarks?: string | null
		outImage?: string | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
	}>
	absent: Array<{
		__typename?: "User"
		_id: string
		siteId: string
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}>
}

export type UserAuthResponseFragment = {
	__typename?: "UserAuthResponse"
	sessionToken?: string | null
	errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
	user?: {
		__typename?: "MeUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		permissionsGroupIds?: Array<string> | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
		permissionsGroups?: Array<{ __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
	} | null
}

export type UserGroupFragment = { __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }

export type UserLocationRecordFragment = {
	__typename?: "UserLocationRecord"
	_id: string
	siteId: string
	zoneId: string
	userId: string
	startAt: any
	endAt: any
	status: UserLocationRecordStatus
	isRestricted?: boolean | null
	createdAt: any
	updatedAt: any
}

export type UserRoleGroupFragment = {
	__typename?: "UserRoleGroup"
	_id: string
	siteId: string
	type: UserRoleTypes
	allowedZoneIds?: Array<string> | null
	allowedZoneGroupIds?: Array<string> | null
	departmentId: string
	workShiftId?: string | null
	patrollingRouteId?: string | null
	rounds?: number | null
	createdAt: any
	updatedAt: any
	label: { __typename?: "Label"; name: string; description?: string | null }
}

export type UserTypeFragment = { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }

export type WorkShiftFragment = { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }

export type ZoneFragment = {
	__typename?: "Zone"
	_id: string
	siteId: string
	readerIds?: Array<string> | null
	type: ZoneTypes
	isActive?: boolean | null
	isCrowded?: boolean | null
	crowdLimit: number
	idleSince?: any | null
	label: { __typename?: "Label"; name: string; description?: string | null }
	picture?: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
		} | null
	} | null
}

export type ZoneGroupFragment = { __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }

export type ZoneStatsFragment = { __typename?: "ZoneStats"; _id: string; siteType: SiteTypes; zoneId: string; timeStamp: any; usersCount: number; createdAt: any; updatedAt: any }

export type AcknowledgeAlertMutationVariables = Exact<{
	alertId: Scalars["String"]
	message: Scalars["String"]
	level: Scalars["Float"]
}>

export type AcknowledgeAlertMutation = {
	__typename?: "Mutation"
	acknowledgeAlert: {
		__typename?: "ExtendedAlert"
		_id: string
		siteId: string
		type: AlertTypes
		alertFlowId: string
		emailMessage: string
		notificationMessage: string
		userId?: string | null
		userRoleId?: string | null
		zoneId?: string | null
		readerId?: string | null
		tagId?: string | null
		userLocationRecordId?: string | null
		userRouteRecordId?: string | null
		toBeEscalated?: boolean | null
		escalateAfter?: any | null
		isResolved?: boolean | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		alertFlow: {
			__typename?: "AlertFlow"
			_id: string
			siteId: string
			type: AlertTypes
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			source?: { __typename?: "AlertSource"; type: AlertSourceTypes; zoneIds?: Array<string> | null; zoneGroupIds?: Array<string> | null; userIds?: Array<string> | null; userGroupIds?: Array<string> | null } | null
			levels: Array<{ __typename?: "AlertTargetLevel"; targetGroupIds: Array<string>; timeout: number }>
		}
		levels: Array<{
			__typename?: "ExtendedAlertLevel"
			alertedUserIds?: Array<string> | null
			emailedUserIds?: Array<string> | null
			alertedUsers?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			emailedUsers?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			responses: Array<{
				__typename?: "ExtendedAlertResponse"
				userId: string
				acknowlegedAt: any
				acknowlegment?: string | null
				resolvedAt?: any | null
				resolution?: string | null
				user: {
					__typename?: "User"
					_id: string
					siteId: string
					name: string
					username: string
					address?: string | null
					isBlacklisted?: boolean | null
					blacklistRemarks?: string | null
					typeId: string
					referenceNo?: string | null
					tagId?: string | null
					status: UserStatus
					createdAt: any
					updatedAt: any
					phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
					email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
			}>
		}>
		user?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
		userRole?: {
			__typename?: "ExtendedUserRole"
			_id: string
			siteId: string
			userId: string
			type: UserRoleTypes
			userRoleGroupId: string
			designation?: string | null
			startAt: any
			endAt?: any | null
			assignedAt: any
			removedAt?: any | null
			status: UserRoleStatus
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			user: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			userRoleGroup: {
				__typename?: "ExtendedUserRoleGroup"
				_id: string
				siteId: string
				type: UserRoleTypes
				allowedZoneIds?: Array<string> | null
				allowedZoneGroupIds?: Array<string> | null
				patrollingRouteId?: string | null
				rounds?: number | null
				departmentId: string
				workShiftId?: string | null
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				label: { __typename?: "Label"; name: string; description?: string | null }
				allowedZones?: Array<{
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}> | null
				allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
				patrollingRoute?: {
					__typename?: "ExtendedPatrollingRoute"
					_id: string
					siteId: string
					zoneIds: Array<string>
					minTime: number
					maxTime: number
					createdAt: any
					updatedAt: any
					site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
					label: { __typename?: "Label"; name: string; description?: string | null }
					zones: Array<{
						__typename?: "Zone"
						_id: string
						siteId: string
						readerIds?: Array<string> | null
						type: ZoneTypes
						isActive?: boolean | null
						isCrowded?: boolean | null
						crowdLimit: number
						idleSince?: any | null
						label: { __typename?: "Label"; name: string; description?: string | null }
						picture?: {
							__typename?: "Picture"
							original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
							variants?: {
								__typename?: "PictureVariants"
								small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							} | null
						} | null
					}>
				} | null
				department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
			}
		} | null
		zone?: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
		reader?: {
			__typename?: "Reader"
			_id: string
			siteId: string
			type: ReaderTypes
			readerHexId: string
			readerDecimalId: string
			assignedToZoneId?: string | null
			assignedAt?: any | null
			isActive?: boolean | null
			lastConnectedEndpoint?: string | null
			lastConnectedAt?: any | null
			lastHeartbeatAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		} | null
		tag?: {
			__typename?: "ExtendedTag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
			user?: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			} | null
		} | null
		userLocationRecord?: {
			__typename?: "ExtendedUserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			user: {
				__typename?: "ExtendedUser"
				_id: string
				siteId: string
				scopedSiteIds: Array<string>
				name: string
				username: string
				address?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
				type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
				tag?: {
					__typename?: "Tag"
					_id: string
					name?: string | null
					tagHexId: string
					tagDecimalId: string
					status: TagStatus
					batteryStatus: TagBatteryStatus
					assignedToUserId?: string | null
					assignedAt?: any | null
					createdAt: any
					updatedAt: any
				} | null
			}
		} | null
		userRouteRecord?: {
			__typename?: "ExtendedUserRouteRecord"
			_id: string
			siteId: string
			userId: string
			startAt: any
			endAt: any
			patrollingRouteId: string
			expectedRouteZoneIds: Array<string>
			expectedMinTimeInMinutes: number
			expectedMaxTimeInMinutes: number
			locationRecordIds?: Array<string> | null
			isRouteCorrect?: boolean | null
			isTimeCorrect?: boolean | null
			isTimeHigh?: boolean | null
			isTimeLow?: boolean | null
			isFinal?: boolean | null
			isPartiallyFinal?: boolean | null
			timeTakenInMinutes: number
			createdAt: any
			updatedAt: any
			expectedRouteZones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
			locationRecords?: Array<{
				__typename?: "RouteUserLocationRecord"
				_id: string
				siteId: string
				zoneId: string
				userId: string
				startAt: any
				endAt: any
				status: UserLocationRecordStatus
				isRestricted?: boolean | null
				createdAt: any
				updatedAt: any
				zone: {
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
			}> | null
			routePoints?: Array<{
				__typename?: "ExtendedRoutePoint"
				guardOnDuty?: boolean | null
				areaIntact?: boolean | null
				isSkipped?: boolean | null
				skipRemarks?: string | null
				zoneId: string
				locationRecordId?: string | null
				createdAt: any
				updatedAt: any
				guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
				areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
				zone: {
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
				locationRecord?: {
					__typename?: "UserLocationRecord"
					_id: string
					siteId: string
					zoneId: string
					userId: string
					startAt: any
					endAt: any
					status: UserLocationRecordStatus
					isRestricted?: boolean | null
					createdAt: any
					updatedAt: any
				} | null
			}> | null
			findings?: Array<{ __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }> | null
		} | null
	}
}

export type ActivateTagOfUserMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type ActivateTagOfUserMutation = {
	__typename?: "Mutation"
	activateTagOfUser: {
		__typename?: "Tag"
		_id: string
		name?: string | null
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type ActivateUserMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type ActivateUserMutation = {
	__typename?: "Mutation"
	activateUser: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type AddReaderToZoneMutationVariables = Exact<{
	zoneId: Scalars["String"]
	readerDecimalId: Scalars["String"]
}>

export type AddReaderToZoneMutation = {
	__typename?: "Mutation"
	addReaderToZone: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type AssignTagMutationVariables = Exact<{
	userId: Scalars["String"]
	tagDecimalId: Scalars["String"]
}>

export type AssignTagMutation = {
	__typename?: "Mutation"
	assignTag: {
		__typename?: "Tag"
		_id: string
		name?: string | null
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type AssignUserRoleMutationVariables = Exact<{
	userId: Scalars["String"]
	input: UserRoleInput
}>

export type AssignUserRoleMutation = {
	__typename?: "Mutation"
	assignUserRole: {
		__typename?: "ExtendedUserRole"
		_id: string
		siteId: string
		userId: string
		type: UserRoleTypes
		userRoleGroupId: string
		designation?: string | null
		startAt: any
		endAt?: any | null
		assignedAt: any
		removedAt?: any | null
		status: UserRoleStatus
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		userRoleGroup: {
			__typename?: "ExtendedUserRoleGroup"
			_id: string
			siteId: string
			type: UserRoleTypes
			allowedZoneIds?: Array<string> | null
			allowedZoneGroupIds?: Array<string> | null
			patrollingRouteId?: string | null
			rounds?: number | null
			departmentId: string
			workShiftId?: string | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			allowedZones?: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
			patrollingRoute?: {
				__typename?: "ExtendedPatrollingRoute"
				_id: string
				siteId: string
				zoneIds: Array<string>
				minTime: number
				maxTime: number
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				label: { __typename?: "Label"; name: string; description?: string | null }
				zones: Array<{
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}>
			} | null
			department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
		}
	}
}

export type BlacklistUserMutationVariables = Exact<{
	userId: Scalars["String"]
	remarks: Scalars["String"]
}>

export type BlacklistUserMutation = {
	__typename?: "Mutation"
	blacklistUser: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type ChangePasswordMutationVariables = Exact<{
	currentPassword: Scalars["String"]
	newPassword: Scalars["String"]
	shouldLogOutOnOtherDevices?: InputMaybe<Scalars["Boolean"]>
}>

export type ChangePasswordMutation = { __typename?: "Mutation"; changePassword: boolean }

export type CreateAlertFlowMutationVariables = Exact<{
	input: AlertFlowInput
}>

export type CreateAlertFlowMutation = {
	__typename?: "Mutation"
	createAlertFlow: {
		__typename?: "ExtendedAlertFlow"
		_id: string
		siteId: string
		type: AlertTypes
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		source?: {
			__typename?: "ExtendedAlertSource"
			type: AlertSourceTypes
			zoneIds?: Array<string> | null
			zoneGroupIds?: Array<string> | null
			userIds?: Array<string> | null
			userGroupIds?: Array<string> | null
			zones?: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			zoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
			users?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			userGroups?: Array<{ __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
		} | null
		levels: Array<{
			__typename?: "ExtendedAlertTargetLevel"
			targetGroupIds: Array<string>
			timeout: number
			targetGroups: Array<{ __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		}>
	}
}

export type CreateAlertTargetGroupMutationVariables = Exact<{
	input: AlertTargetGroupInput
}>

export type CreateAlertTargetGroupMutation = {
	__typename?: "Mutation"
	createAlertTargetGroup: {
		__typename?: "ExtendedAlertTargetGroup"
		_id: string
		siteId: string
		userIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type CreateDepartmentMutationVariables = Exact<{
	input: DepartmentInput
}>

export type CreateDepartmentMutation = {
	__typename?: "Mutation"
	createDepartment: {
		__typename?: "ExtendedDepartment"
		_id: string
		siteId: string
		zoneIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type CreatePatrollingRouteMutationVariables = Exact<{
	input: PatrollingRouteInput
}>

export type CreatePatrollingRouteMutation = {
	__typename?: "Mutation"
	createPatrollingRoute: {
		__typename?: "ExtendedPatrollingRoute"
		_id: string
		siteId: string
		zoneIds: Array<string>
		minTime: number
		maxTime: number
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type CreatePermissionsGroupMutationVariables = Exact<{
	input: PermissionsGroupInput
}>

export type CreatePermissionsGroupMutation = {
	__typename?: "Mutation"
	createPermissionsGroup: { __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type CreateReaderMutationVariables = Exact<{
	input: ReaderInput
}>

export type CreateReaderMutation = {
	__typename?: "Mutation"
	createReader: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type CreateServiceMutationVariables = Exact<{
	input: ServiceInput
}>

export type CreateServiceMutation = {
	__typename?: "Mutation"
	createService: { __typename?: "Service"; _id: string; key: string; lastHeartbeatAt?: any | null; isActive?: boolean | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type CreateTagMutationVariables = Exact<{
	tagDecimalId: Scalars["String"]
	name?: InputMaybe<Scalars["String"]>
}>

export type CreateTagMutation = {
	__typename?: "Mutation"
	createTag: {
		__typename?: "Tag"
		_id: string
		name?: string | null
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type CreateUserMutationVariables = Exact<{
	input: UserInput
}>

export type CreateUserMutation = {
	__typename?: "Mutation"
	createUser: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type CreateUserGroupMutationVariables = Exact<{
	input: UserGroupInput
}>

export type CreateUserGroupMutation = {
	__typename?: "Mutation"
	createUserGroup: {
		__typename?: "ExtendedUserGroup"
		_id: string
		siteId: string
		userIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type CreateUserRoleGroupMutationVariables = Exact<{
	input: UserRoleGroupInput
}>

export type CreateUserRoleGroupMutation = {
	__typename?: "Mutation"
	createUserRoleGroup: {
		__typename?: "ExtendedUserRoleGroup"
		_id: string
		siteId: string
		type: UserRoleTypes
		allowedZoneIds?: Array<string> | null
		allowedZoneGroupIds?: Array<string> | null
		patrollingRouteId?: string | null
		rounds?: number | null
		departmentId: string
		workShiftId?: string | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		allowedZones?: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
		patrollingRoute?: {
			__typename?: "ExtendedPatrollingRoute"
			_id: string
			siteId: string
			zoneIds: Array<string>
			minTime: number
			maxTime: number
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			zones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		} | null
		department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
	}
}

export type CreateUserTypeMutationVariables = Exact<{
	input: UserTypeInput
}>

export type CreateUserTypeMutation = { __typename?: "Mutation"; createUserType: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } } }

export type CreateWorkShiftMutationVariables = Exact<{
	input: WorkShiftInput
}>

export type CreateWorkShiftMutation = {
	__typename?: "Mutation"
	createWorkShift: {
		__typename?: "ExtendedWorkShift"
		_id: string
		siteId: string
		startAt: string
		endAt: string
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type CreateZoneGroupMutationVariables = Exact<{
	input: ZoneGroupInput
}>

export type CreateZoneGroupMutation = {
	__typename?: "Mutation"
	createZoneGroup: {
		__typename?: "ExtendedZoneGroup"
		_id: string
		siteId: string
		zoneIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type DeactivateTagOfUserMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type DeactivateTagOfUserMutation = {
	__typename?: "Mutation"
	deactivateTagOfUser: {
		__typename?: "Tag"
		_id: string
		name?: string | null
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type DeactivateUserMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type DeactivateUserMutation = {
	__typename?: "Mutation"
	deactivateUser: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type DeleteServiceMutationVariables = Exact<{
	serviceId: Scalars["String"]
}>

export type DeleteServiceMutation = { __typename?: "Mutation"; deleteService: boolean }

export type FinishAvatarUploadMutationVariables = Exact<{
	userId: Scalars["String"]
	input: FinishUploadInput
}>

export type FinishAvatarUploadMutation = {
	__typename?: "Mutation"
	finishAvatarUpload: {
		__typename?: "Picture"
		original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
		variants?: {
			__typename?: "PictureVariants"
			small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
		} | null
	}
}

export type FinishMobileAppUploadMutationVariables = Exact<{
	input: FinishUploadInput
}>

export type FinishMobileAppUploadMutation = { __typename?: "Mutation"; finishMobileAppUpload: { __typename?: "FinishUploadResponse"; type: MediaTypes; url: string } }

export type FlushTagMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type FlushTagMutation = {
	__typename?: "Mutation"
	flushTag: {
		__typename?: "Tag"
		_id: string
		name?: string | null
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export type GenerateComprehensiveUserAttendanceReportMutationVariables = Exact<{
	input: ComprehensiveUserAttendanceReportInput
}>

export type GenerateComprehensiveUserAttendanceReportMutation = {
	__typename?: "Mutation"
	generateComprehensiveUserAttendanceReport: {
		__typename?: "ExtendedReport"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		lastUpdatedByUser?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	}
}

export type GenerateReadersStatusLogsReportMutationVariables = Exact<{
	input: ReadersStatusLogsReportInput
}>

export type GenerateReadersStatusLogsReportMutation = {
	__typename?: "Mutation"
	generateReadersStatusLogsReport: {
		__typename?: "ExtendedReport"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		lastUpdatedByUser?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	}
}

export type GenerateSummarizedUserAttendanceReportMutationVariables = Exact<{
	input: SummarizedUserAttendanceReportInput
}>

export type GenerateSummarizedUserAttendanceReportMutation = {
	__typename?: "Mutation"
	generateSummarizedUserAttendanceReport: {
		__typename?: "ExtendedReport"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		lastUpdatedByUser?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	}
}

export type GenerateUserLocationRecordsReportMutationVariables = Exact<{
	input: UserLocationRecordsReportInput
}>

export type GenerateUserLocationRecordsReportMutation = {
	__typename?: "Mutation"
	generateUserLocationRecordsReport: {
		__typename?: "ExtendedReport"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		lastUpdatedByUser?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	}
}

export type GenerateUserPatrollingRouteReportMutationVariables = Exact<{
	input: UserPatrollingRouteReportInput
}>

export type GenerateUserPatrollingRouteReportMutation = {
	__typename?: "Mutation"
	generateUserPatrollingRouteReport: {
		__typename?: "ExtendedReport"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		lastUpdatedByUser?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	}
}

export type GenerateUserRestrictedEntriesReportMutationVariables = Exact<{
	input: UserRestrictedEntriesReportInput
}>

export type GenerateUserRestrictedEntriesReportMutation = {
	__typename?: "Mutation"
	generateUserRestrictedEntriesReport: {
		__typename?: "ExtendedReport"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		lastUpdatedByUser?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	}
}

export type GenerateZoneGroupRestrictedEntriesReportMutationVariables = Exact<{
	input: ZoneGroupRestrictedEntriesReportInput
}>

export type GenerateZoneGroupRestrictedEntriesReportMutation = {
	__typename?: "Mutation"
	generateZoneGroupRestrictedEntriesReport: {
		__typename?: "ExtendedReport"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		lastUpdatedByUser?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	}
}

export type GenerateZoneRestrictedEntriesReportMutationVariables = Exact<{
	input: ZoneRestrictedEntriesReportInput
}>

export type GenerateZoneRestrictedEntriesReportMutation = {
	__typename?: "Mutation"
	generateZoneRestrictedEntriesReport: {
		__typename?: "ExtendedReport"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		lastUpdatedByUser?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	}
}

export type LoginMutationVariables = Exact<{
	username: Scalars["String"]
	password: Scalars["String"]
	siteType: SiteTypes
}>

export type LoginMutation = {
	__typename?: "Mutation"
	login: {
		__typename?: "UserAuthResponse"
		sessionToken?: string | null
		errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
		user?: {
			__typename?: "MeUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			permissionsGroupIds?: Array<string> | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
			permissionsGroups?: Array<{ __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
		} | null
	}
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: "Mutation"; logout: boolean }

export type MarkUserAttendanceBulkInMutationVariables = Exact<{
	input: MarkUserAttendanceBulkInInput
}>

export type MarkUserAttendanceBulkInMutation = {
	__typename?: "Mutation"
	markUserAttendanceBulkIn: Array<{
		__typename?: "ExtendedUserAttendance"
		_id: string
		siteId: string
		userId: string
		inAt: any
		outAt?: any | null
		inImage?: string | null
		inRemarks?: string | null
		outRemarks?: string | null
		outImage?: string | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
	}>
}

export type MarkUserAttendanceBulkOutMutationVariables = Exact<{
	input: MarkUserAttendanceBulkOutInput
}>

export type MarkUserAttendanceBulkOutMutation = {
	__typename?: "Mutation"
	markUserAttendanceBulkOut: Array<{
		__typename?: "ExtendedUserAttendance"
		_id: string
		siteId: string
		userId: string
		inAt: any
		outAt?: any | null
		inImage?: string | null
		inRemarks?: string | null
		outRemarks?: string | null
		outImage?: string | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
	}>
}

export type MarkUserAttendanceInMutationVariables = Exact<{
	userId: Scalars["String"]
	inRemarks: Scalars["String"]
	inTime: Scalars["DateTime"]
}>

export type MarkUserAttendanceInMutation = {
	__typename?: "Mutation"
	markUserAttendanceIn: {
		__typename?: "ExtendedUserAttendance"
		_id: string
		siteId: string
		userId: string
		inAt: any
		outAt?: any | null
		inImage?: string | null
		inRemarks?: string | null
		outRemarks?: string | null
		outImage?: string | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
	}
}

export type MarkUserAttendanceOutMutationVariables = Exact<{
	userId: Scalars["String"]
	outRemarks: Scalars["String"]
	outTime: Scalars["DateTime"]
}>

export type MarkUserAttendanceOutMutation = {
	__typename?: "Mutation"
	markUserAttendanceOut: {
		__typename?: "ExtendedUserAttendance"
		_id: string
		siteId: string
		userId: string
		inAt: any
		outAt?: any | null
		inImage?: string | null
		inRemarks?: string | null
		outRemarks?: string | null
		outImage?: string | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
	}
}

export type RemoveCurrentUserRoleMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type RemoveCurrentUserRoleMutation = { __typename?: "Mutation"; removeCurrentUserRole: boolean }

export type RemoveReaderFromZoneMutationVariables = Exact<{
	zoneId: Scalars["String"]
	readerId: Scalars["String"]
}>

export type RemoveReaderFromZoneMutation = { __typename?: "Mutation"; removeReaderFromZone: boolean }

export type ResetUserPasswordMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type ResetUserPasswordMutation = {
	__typename?: "Mutation"
	resetUserPassword: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type ResolveAlertMutationVariables = Exact<{
	alertId: Scalars["String"]
	message: Scalars["String"]
	level: Scalars["Float"]
}>

export type ResolveAlertMutation = {
	__typename?: "Mutation"
	resolveAlert: {
		__typename?: "ExtendedAlert"
		_id: string
		siteId: string
		type: AlertTypes
		alertFlowId: string
		emailMessage: string
		notificationMessage: string
		userId?: string | null
		userRoleId?: string | null
		zoneId?: string | null
		readerId?: string | null
		tagId?: string | null
		userLocationRecordId?: string | null
		userRouteRecordId?: string | null
		toBeEscalated?: boolean | null
		escalateAfter?: any | null
		isResolved?: boolean | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		alertFlow: {
			__typename?: "AlertFlow"
			_id: string
			siteId: string
			type: AlertTypes
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			source?: { __typename?: "AlertSource"; type: AlertSourceTypes; zoneIds?: Array<string> | null; zoneGroupIds?: Array<string> | null; userIds?: Array<string> | null; userGroupIds?: Array<string> | null } | null
			levels: Array<{ __typename?: "AlertTargetLevel"; targetGroupIds: Array<string>; timeout: number }>
		}
		levels: Array<{
			__typename?: "ExtendedAlertLevel"
			alertedUserIds?: Array<string> | null
			emailedUserIds?: Array<string> | null
			alertedUsers?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			emailedUsers?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			responses: Array<{
				__typename?: "ExtendedAlertResponse"
				userId: string
				acknowlegedAt: any
				acknowlegment?: string | null
				resolvedAt?: any | null
				resolution?: string | null
				user: {
					__typename?: "User"
					_id: string
					siteId: string
					name: string
					username: string
					address?: string | null
					isBlacklisted?: boolean | null
					blacklistRemarks?: string | null
					typeId: string
					referenceNo?: string | null
					tagId?: string | null
					status: UserStatus
					createdAt: any
					updatedAt: any
					phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
					email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
			}>
		}>
		user?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
		userRole?: {
			__typename?: "ExtendedUserRole"
			_id: string
			siteId: string
			userId: string
			type: UserRoleTypes
			userRoleGroupId: string
			designation?: string | null
			startAt: any
			endAt?: any | null
			assignedAt: any
			removedAt?: any | null
			status: UserRoleStatus
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			user: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			userRoleGroup: {
				__typename?: "ExtendedUserRoleGroup"
				_id: string
				siteId: string
				type: UserRoleTypes
				allowedZoneIds?: Array<string> | null
				allowedZoneGroupIds?: Array<string> | null
				patrollingRouteId?: string | null
				rounds?: number | null
				departmentId: string
				workShiftId?: string | null
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				label: { __typename?: "Label"; name: string; description?: string | null }
				allowedZones?: Array<{
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}> | null
				allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
				patrollingRoute?: {
					__typename?: "ExtendedPatrollingRoute"
					_id: string
					siteId: string
					zoneIds: Array<string>
					minTime: number
					maxTime: number
					createdAt: any
					updatedAt: any
					site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
					label: { __typename?: "Label"; name: string; description?: string | null }
					zones: Array<{
						__typename?: "Zone"
						_id: string
						siteId: string
						readerIds?: Array<string> | null
						type: ZoneTypes
						isActive?: boolean | null
						isCrowded?: boolean | null
						crowdLimit: number
						idleSince?: any | null
						label: { __typename?: "Label"; name: string; description?: string | null }
						picture?: {
							__typename?: "Picture"
							original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
							variants?: {
								__typename?: "PictureVariants"
								small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							} | null
						} | null
					}>
				} | null
				department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
			}
		} | null
		zone?: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
		reader?: {
			__typename?: "Reader"
			_id: string
			siteId: string
			type: ReaderTypes
			readerHexId: string
			readerDecimalId: string
			assignedToZoneId?: string | null
			assignedAt?: any | null
			isActive?: boolean | null
			lastConnectedEndpoint?: string | null
			lastConnectedAt?: any | null
			lastHeartbeatAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		} | null
		tag?: {
			__typename?: "ExtendedTag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
			user?: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			} | null
		} | null
		userLocationRecord?: {
			__typename?: "ExtendedUserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			user: {
				__typename?: "ExtendedUser"
				_id: string
				siteId: string
				scopedSiteIds: Array<string>
				name: string
				username: string
				address?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
				type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
				tag?: {
					__typename?: "Tag"
					_id: string
					name?: string | null
					tagHexId: string
					tagDecimalId: string
					status: TagStatus
					batteryStatus: TagBatteryStatus
					assignedToUserId?: string | null
					assignedAt?: any | null
					createdAt: any
					updatedAt: any
				} | null
			}
		} | null
		userRouteRecord?: {
			__typename?: "ExtendedUserRouteRecord"
			_id: string
			siteId: string
			userId: string
			startAt: any
			endAt: any
			patrollingRouteId: string
			expectedRouteZoneIds: Array<string>
			expectedMinTimeInMinutes: number
			expectedMaxTimeInMinutes: number
			locationRecordIds?: Array<string> | null
			isRouteCorrect?: boolean | null
			isTimeCorrect?: boolean | null
			isTimeHigh?: boolean | null
			isTimeLow?: boolean | null
			isFinal?: boolean | null
			isPartiallyFinal?: boolean | null
			timeTakenInMinutes: number
			createdAt: any
			updatedAt: any
			expectedRouteZones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
			locationRecords?: Array<{
				__typename?: "RouteUserLocationRecord"
				_id: string
				siteId: string
				zoneId: string
				userId: string
				startAt: any
				endAt: any
				status: UserLocationRecordStatus
				isRestricted?: boolean | null
				createdAt: any
				updatedAt: any
				zone: {
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
			}> | null
			routePoints?: Array<{
				__typename?: "ExtendedRoutePoint"
				guardOnDuty?: boolean | null
				areaIntact?: boolean | null
				isSkipped?: boolean | null
				skipRemarks?: string | null
				zoneId: string
				locationRecordId?: string | null
				createdAt: any
				updatedAt: any
				guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
				areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
				zone: {
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
				locationRecord?: {
					__typename?: "UserLocationRecord"
					_id: string
					siteId: string
					zoneId: string
					userId: string
					startAt: any
					endAt: any
					status: UserLocationRecordStatus
					isRestricted?: boolean | null
					createdAt: any
					updatedAt: any
				} | null
			}> | null
			findings?: Array<{ __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }> | null
		} | null
	}
}

export type SendEmailVerificationSecretCodeMutationVariables = Exact<{ [key: string]: never }>

export type SendEmailVerificationSecretCodeMutation = { __typename?: "Mutation"; sendEmailVerificationSecretCode: boolean }

export type SignAvatarUploadMutationVariables = Exact<{
	userId: Scalars["String"]
	format: Scalars["String"]
}>

export type SignAvatarUploadMutation = { __typename?: "Mutation"; signAvatarUpload: { __typename?: "UploadSignatureResponse"; signedUrl: string; key: string } }

export type SignMobileAppUploadMutationVariables = Exact<{
	type: MobileAppTypes
	version: Scalars["String"]
	format: Scalars["String"]
}>

export type SignMobileAppUploadMutation = { __typename?: "Mutation"; signMobileAppUpload: { __typename?: "UploadSignatureResponse"; signedUrl: string; key: string } }

export type SwitchSiteMutationVariables = Exact<{
	userId: Scalars["String"]
	siteId: Scalars["String"]
}>

export type SwitchSiteMutation = {
	__typename?: "Mutation"
	switchSite: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type UpdateAlertFlowMutationVariables = Exact<{
	alertFlowId: Scalars["String"]
	input: AlertFlowInput
}>

export type UpdateAlertFlowMutation = {
	__typename?: "Mutation"
	updateAlertFlow: {
		__typename?: "ExtendedAlertFlow"
		_id: string
		siteId: string
		type: AlertTypes
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		source?: {
			__typename?: "ExtendedAlertSource"
			type: AlertSourceTypes
			zoneIds?: Array<string> | null
			zoneGroupIds?: Array<string> | null
			userIds?: Array<string> | null
			userGroupIds?: Array<string> | null
			zones?: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			zoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
			users?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			userGroups?: Array<{ __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
		} | null
		levels: Array<{
			__typename?: "ExtendedAlertTargetLevel"
			targetGroupIds: Array<string>
			timeout: number
			targetGroups: Array<{ __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		}>
	}
}

export type UpdateAlertTargetGroupMutationVariables = Exact<{
	alertTargetGroupId: Scalars["String"]
	input: AlertTargetGroupInput
}>

export type UpdateAlertTargetGroupMutation = {
	__typename?: "Mutation"
	updateAlertTargetGroup: {
		__typename?: "ExtendedAlertTargetGroup"
		_id: string
		siteId: string
		userIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type UpdateManpowerCountMutationVariables = Exact<{
	manpowerCount: Scalars["Float"]
}>

export type UpdateManpowerCountMutation = {
	__typename?: "Mutation"
	updateManpowerCount: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type UpdateServiceMutationVariables = Exact<{
	serviceId: Scalars["String"]
	input: ServiceInput
}>

export type UpdateServiceMutation = {
	__typename?: "Mutation"
	updateService: { __typename?: "Service"; _id: string; key: string; lastHeartbeatAt?: any | null; isActive?: boolean | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type UpdateUserMutationVariables = Exact<{
	userId: Scalars["String"]
	input: UserInput
}>

export type UpdateUserMutation = {
	__typename?: "Mutation"
	updateUser: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type UpdateUserGroupMutationVariables = Exact<{
	userGroupId: Scalars["String"]
	input: UserGroupInput
}>

export type UpdateUserGroupMutation = {
	__typename?: "Mutation"
	updateUserGroup: {
		__typename?: "ExtendedUserGroup"
		_id: string
		siteId: string
		userIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type UpdateUserPermissionsGroupsMutationVariables = Exact<{
	userId: Scalars["String"]
	permissionsGroupIds: Array<Scalars["String"]> | Scalars["String"]
}>

export type UpdateUserPermissionsGroupsMutation = {
	__typename?: "Mutation"
	updateUserPermissionsGroups: Array<{ __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type UpdateUserScopedSitesMutationVariables = Exact<{
	userId: Scalars["String"]
	siteIds: Array<Scalars["String"]> | Scalars["String"]
}>

export type UpdateUserScopedSitesMutation = {
	__typename?: "Mutation"
	updateUserScopedSites: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type UpdateZoneMutationVariables = Exact<{
	zoneId: Scalars["String"]
	input: ZoneInput
}>

export type UpdateZoneMutation = {
	__typename?: "Mutation"
	updateZone: {
		__typename?: "ExtendedZone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}
}

export type UploadMobileAppMutationVariables = Exact<{
	input: MobileAppInput
}>

export type UploadMobileAppMutation = { __typename?: "Mutation"; uploadMobileApp: { __typename?: "MobileApp"; _id: string; type: MobileAppTypes; version: string; changes: string; url: string; createdAt: any; updatedAt: any } }

export type VerifyEmailWithSecretCodeMutationVariables = Exact<{
	code: Scalars["String"]
}>

export type VerifyEmailWithSecretCodeMutation = { __typename?: "Mutation"; verifyEmailWithSecretCode: boolean }

export type WhitelistUserMutationVariables = Exact<{
	userId: Scalars["String"]
}>

export type WhitelistUserMutation = {
	__typename?: "Mutation"
	whitelistUser: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type ActiveRestrictedEntriesQueryVariables = Exact<{ [key: string]: never }>

export type ActiveRestrictedEntriesQuery = {
	__typename?: "Query"
	activeRestrictedEntries: {
		__typename?: "ActiveRestrictedUserLocationRecordsResponse"
		recordIds: Array<string>
		zoneWiseRecordIds: Array<{ __typename?: "ZoneWiseActiveRestrictedUserLocationRecordsResponse"; zoneId: string; recordIds: Array<string> }>
	}
}

export type ActiveUsersQueryVariables = Exact<{ [key: string]: never }>

export type ActiveUsersQuery = {
	__typename?: "Query"
	activeUsers: { __typename?: "ActiveUsersResponse"; userIds: Array<string>; zoneWiseUserIds: Array<{ __typename?: "ZoneWiseActiveUsersResponse"; zoneId: string; userIds: Array<string> }> }
}

export type AlertByIdQueryVariables = Exact<{
	alertId: Scalars["String"]
}>

export type AlertByIdQuery = {
	__typename?: "Query"
	alertById: {
		__typename?: "ExtendedAlert"
		_id: string
		siteId: string
		type: AlertTypes
		alertFlowId: string
		emailMessage: string
		notificationMessage: string
		userId?: string | null
		userRoleId?: string | null
		zoneId?: string | null
		readerId?: string | null
		tagId?: string | null
		userLocationRecordId?: string | null
		userRouteRecordId?: string | null
		toBeEscalated?: boolean | null
		escalateAfter?: any | null
		isResolved?: boolean | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		alertFlow: {
			__typename?: "AlertFlow"
			_id: string
			siteId: string
			type: AlertTypes
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			source?: { __typename?: "AlertSource"; type: AlertSourceTypes; zoneIds?: Array<string> | null; zoneGroupIds?: Array<string> | null; userIds?: Array<string> | null; userGroupIds?: Array<string> | null } | null
			levels: Array<{ __typename?: "AlertTargetLevel"; targetGroupIds: Array<string>; timeout: number }>
		}
		levels: Array<{
			__typename?: "ExtendedAlertLevel"
			alertedUserIds?: Array<string> | null
			emailedUserIds?: Array<string> | null
			alertedUsers?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			emailedUsers?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			responses: Array<{
				__typename?: "ExtendedAlertResponse"
				userId: string
				acknowlegedAt: any
				acknowlegment?: string | null
				resolvedAt?: any | null
				resolution?: string | null
				user: {
					__typename?: "User"
					_id: string
					siteId: string
					name: string
					username: string
					address?: string | null
					isBlacklisted?: boolean | null
					blacklistRemarks?: string | null
					typeId: string
					referenceNo?: string | null
					tagId?: string | null
					status: UserStatus
					createdAt: any
					updatedAt: any
					phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
					email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
			}>
		}>
		user?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
		userRole?: {
			__typename?: "ExtendedUserRole"
			_id: string
			siteId: string
			userId: string
			type: UserRoleTypes
			userRoleGroupId: string
			designation?: string | null
			startAt: any
			endAt?: any | null
			assignedAt: any
			removedAt?: any | null
			status: UserRoleStatus
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			user: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			userRoleGroup: {
				__typename?: "ExtendedUserRoleGroup"
				_id: string
				siteId: string
				type: UserRoleTypes
				allowedZoneIds?: Array<string> | null
				allowedZoneGroupIds?: Array<string> | null
				patrollingRouteId?: string | null
				rounds?: number | null
				departmentId: string
				workShiftId?: string | null
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				label: { __typename?: "Label"; name: string; description?: string | null }
				allowedZones?: Array<{
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}> | null
				allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
				patrollingRoute?: {
					__typename?: "ExtendedPatrollingRoute"
					_id: string
					siteId: string
					zoneIds: Array<string>
					minTime: number
					maxTime: number
					createdAt: any
					updatedAt: any
					site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
					label: { __typename?: "Label"; name: string; description?: string | null }
					zones: Array<{
						__typename?: "Zone"
						_id: string
						siteId: string
						readerIds?: Array<string> | null
						type: ZoneTypes
						isActive?: boolean | null
						isCrowded?: boolean | null
						crowdLimit: number
						idleSince?: any | null
						label: { __typename?: "Label"; name: string; description?: string | null }
						picture?: {
							__typename?: "Picture"
							original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
							variants?: {
								__typename?: "PictureVariants"
								small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							} | null
						} | null
					}>
				} | null
				department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
			}
		} | null
		zone?: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
		reader?: {
			__typename?: "Reader"
			_id: string
			siteId: string
			type: ReaderTypes
			readerHexId: string
			readerDecimalId: string
			assignedToZoneId?: string | null
			assignedAt?: any | null
			isActive?: boolean | null
			lastConnectedEndpoint?: string | null
			lastConnectedAt?: any | null
			lastHeartbeatAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		} | null
		tag?: {
			__typename?: "ExtendedTag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
			user?: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			} | null
		} | null
		userLocationRecord?: {
			__typename?: "ExtendedUserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			user: {
				__typename?: "ExtendedUser"
				_id: string
				siteId: string
				scopedSiteIds: Array<string>
				name: string
				username: string
				address?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
				type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
				tag?: {
					__typename?: "Tag"
					_id: string
					name?: string | null
					tagHexId: string
					tagDecimalId: string
					status: TagStatus
					batteryStatus: TagBatteryStatus
					assignedToUserId?: string | null
					assignedAt?: any | null
					createdAt: any
					updatedAt: any
				} | null
			}
		} | null
		userRouteRecord?: {
			__typename?: "ExtendedUserRouteRecord"
			_id: string
			siteId: string
			userId: string
			startAt: any
			endAt: any
			patrollingRouteId: string
			expectedRouteZoneIds: Array<string>
			expectedMinTimeInMinutes: number
			expectedMaxTimeInMinutes: number
			locationRecordIds?: Array<string> | null
			isRouteCorrect?: boolean | null
			isTimeCorrect?: boolean | null
			isTimeHigh?: boolean | null
			isTimeLow?: boolean | null
			isFinal?: boolean | null
			isPartiallyFinal?: boolean | null
			timeTakenInMinutes: number
			createdAt: any
			updatedAt: any
			expectedRouteZones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
			locationRecords?: Array<{
				__typename?: "RouteUserLocationRecord"
				_id: string
				siteId: string
				zoneId: string
				userId: string
				startAt: any
				endAt: any
				status: UserLocationRecordStatus
				isRestricted?: boolean | null
				createdAt: any
				updatedAt: any
				zone: {
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
			}> | null
			routePoints?: Array<{
				__typename?: "ExtendedRoutePoint"
				guardOnDuty?: boolean | null
				areaIntact?: boolean | null
				isSkipped?: boolean | null
				skipRemarks?: string | null
				zoneId: string
				locationRecordId?: string | null
				createdAt: any
				updatedAt: any
				guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
				areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
				zone: {
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
				locationRecord?: {
					__typename?: "UserLocationRecord"
					_id: string
					siteId: string
					zoneId: string
					userId: string
					startAt: any
					endAt: any
					status: UserLocationRecordStatus
					isRestricted?: boolean | null
					createdAt: any
					updatedAt: any
				} | null
			}> | null
			findings?: Array<{ __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }> | null
		} | null
	}
}

export type AlertFlowByIdQueryVariables = Exact<{
	alertFlowId: Scalars["String"]
}>

export type AlertFlowByIdQuery = {
	__typename?: "Query"
	alertFlowById?: {
		__typename?: "ExtendedAlertFlow"
		_id: string
		siteId: string
		type: AlertTypes
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		source?: {
			__typename?: "ExtendedAlertSource"
			type: AlertSourceTypes
			zoneIds?: Array<string> | null
			zoneGroupIds?: Array<string> | null
			userIds?: Array<string> | null
			userGroupIds?: Array<string> | null
			zones?: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			zoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
			users?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			userGroups?: Array<{ __typename?: "UserGroup"; _id: string; siteId: string; userIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
		} | null
		levels: Array<{
			__typename?: "ExtendedAlertTargetLevel"
			targetGroupIds: Array<string>
			timeout: number
			targetGroups: Array<{ __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		}>
	} | null
}

export type AlertFlowsQueryVariables = Exact<{ [key: string]: never }>

export type AlertFlowsQuery = {
	__typename?: "Query"
	alertFlows: Array<{
		__typename?: "AlertFlow"
		_id: string
		siteId: string
		type: AlertTypes
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		source?: { __typename?: "AlertSource"; type: AlertSourceTypes; zoneIds?: Array<string> | null; zoneGroupIds?: Array<string> | null; userIds?: Array<string> | null; userGroupIds?: Array<string> | null } | null
		levels: Array<{ __typename?: "AlertTargetLevel"; targetGroupIds: Array<string>; timeout: number }>
	}>
}

export type AlertFlowsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type AlertFlowsByKeywordQuery = {
	__typename?: "Query"
	alertFlowsByKeyword: {
		__typename?: "PaginatedAlertFlowsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alertFlows: Array<{
			__typename?: "AlertFlow"
			_id: string
			siteId: string
			type: AlertTypes
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			source?: { __typename?: "AlertSource"; type: AlertSourceTypes; zoneIds?: Array<string> | null; zoneGroupIds?: Array<string> | null; userIds?: Array<string> | null; userGroupIds?: Array<string> | null } | null
			levels: Array<{ __typename?: "AlertTargetLevel"; targetGroupIds: Array<string>; timeout: number }>
		}>
	}
}

export type AlertTargetGroupByIdQueryVariables = Exact<{
	alertTargetGroupId: Scalars["String"]
}>

export type AlertTargetGroupByIdQuery = {
	__typename?: "Query"
	alertTargetGroupById: {
		__typename?: "ExtendedAlertTargetGroup"
		_id: string
		siteId: string
		userIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type AlertTargetGroupsQueryVariables = Exact<{ [key: string]: never }>

export type AlertTargetGroupsQuery = {
	__typename?: "Query"
	alertTargetGroups: Array<{ __typename?: "AlertTargetGroup"; _id: string; siteId: string; userIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type AlertTargetGroupsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type AlertTargetGroupsByKeywordQuery = {
	__typename?: "Query"
	alertTargetGroupsByKeyword: {
		__typename?: "PaginatedAlertTargetGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alertTargetGroups: Array<{
			__typename?: "ExtendedAlertTargetGroup"
			_id: string
			siteId: string
			userIds: Array<string>
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			users: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		}>
	}
}

export type AlertsByFilterQueryVariables = Exact<{
	filter: AlertsFilterInput
	pagination: PaginatedInput
}>

export type AlertsByFilterQuery = {
	__typename?: "Query"
	alertsByFilter: {
		__typename?: "PaginatedAlertsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alerts: Array<{
			__typename?: "Alert"
			_id: string
			siteId: string
			type: AlertTypes
			alertFlowId: string
			emailMessage: string
			notificationMessage: string
			userId?: string | null
			userRoleId?: string | null
			zoneId?: string | null
			readerId?: string | null
			tagId?: string | null
			userLocationRecordId?: string | null
			userRouteRecordId?: string | null
			toBeEscalated?: boolean | null
			escalateAfter?: any | null
			isResolved?: boolean | null
			createdAt: any
			updatedAt: any
			levels: Array<{
				__typename?: "AlertLevel"
				alertedUserIds?: Array<string> | null
				emailedUserIds?: Array<string> | null
				responses: Array<{ __typename?: "AlertResponse"; userId: string; acknowlegedAt: any; acknowlegment?: string | null; resolvedAt?: any | null; resolution?: string | null }>
			}>
		}>
	}
}

export type AllAlertFlowsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllAlertFlowsQuery = {
	__typename?: "Query"
	allAlertFlows: {
		__typename?: "PaginatedAlertFlowsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alertFlows: Array<{
			__typename?: "AlertFlow"
			_id: string
			siteId: string
			type: AlertTypes
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			source?: { __typename?: "AlertSource"; type: AlertSourceTypes; zoneIds?: Array<string> | null; zoneGroupIds?: Array<string> | null; userIds?: Array<string> | null; userGroupIds?: Array<string> | null } | null
			levels: Array<{ __typename?: "AlertTargetLevel"; targetGroupIds: Array<string>; timeout: number }>
		}>
	}
}

export type AllAlertTargetGroupsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllAlertTargetGroupsQuery = {
	__typename?: "Query"
	allAlertTargetGroups: {
		__typename?: "PaginatedAlertTargetGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alertTargetGroups: Array<{
			__typename?: "ExtendedAlertTargetGroup"
			_id: string
			siteId: string
			userIds: Array<string>
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			users: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		}>
	}
}

export type AllAlertsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllAlertsQuery = {
	__typename?: "Query"
	allAlerts: {
		__typename?: "PaginatedAlertsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		alerts: Array<{
			__typename?: "Alert"
			_id: string
			siteId: string
			type: AlertTypes
			alertFlowId: string
			emailMessage: string
			notificationMessage: string
			userId?: string | null
			userRoleId?: string | null
			zoneId?: string | null
			readerId?: string | null
			tagId?: string | null
			userLocationRecordId?: string | null
			userRouteRecordId?: string | null
			toBeEscalated?: boolean | null
			escalateAfter?: any | null
			isResolved?: boolean | null
			createdAt: any
			updatedAt: any
			levels: Array<{
				__typename?: "AlertLevel"
				alertedUserIds?: Array<string> | null
				emailedUserIds?: Array<string> | null
				responses: Array<{ __typename?: "AlertResponse"; userId: string; acknowlegedAt: any; acknowlegment?: string | null; resolvedAt?: any | null; resolution?: string | null }>
			}>
		}>
	}
}

export type AllWorkShiftsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllWorkShiftsQuery = {
	__typename?: "Query"
	allWorkShifts: {
		__typename?: "PaginatedWorkShiftsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		workShifts: Array<{ __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type AllMobileAppsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllMobileAppsQuery = {
	__typename?: "Query"
	allMobileApps: {
		__typename?: "PaginatedMobileAppsResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		mobileApps: Array<{ __typename?: "MobileApp"; _id: string; type: MobileAppTypes; version: string; changes: string; url: string; createdAt: any; updatedAt: any }>
	}
}

export type AllPatrollingRoutesQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllPatrollingRoutesQuery = {
	__typename?: "Query"
	allPatrollingRoutes: {
		__typename?: "PaginatedPatrollingRoutesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		patrollingRoutes?: Array<{
			__typename?: "ExtendedPatrollingRoute"
			_id: string
			siteId: string
			zoneIds: Array<string>
			minTime: number
			maxTime: number
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			zones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		}> | null
	}
}

export type AllPermissionsGroupsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllPermissionsGroupsQuery = {
	__typename?: "Query"
	allPermissionsGroups: {
		__typename?: "PaginatedPermissionsGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		permissionsGroups: Array<{ __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type AllReadersQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllReadersQuery = {
	__typename?: "Query"
	allReaders: {
		__typename?: "PaginatedReadersResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		readers: Array<{
			__typename?: "Reader"
			_id: string
			siteId: string
			type: ReaderTypes
			readerHexId: string
			readerDecimalId: string
			assignedToZoneId?: string | null
			assignedAt?: any | null
			isActive?: boolean | null
			lastConnectedEndpoint?: string | null
			lastConnectedAt?: any | null
			lastHeartbeatAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type AllReportsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllReportsQuery = {
	__typename?: "Query"
	allReports: {
		__typename?: "PaginatedReportsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		reports: Array<{
			__typename?: "Report"
			_id: string
			siteId: string
			type: ReportTypes
			userIds?: Array<string> | null
			sinceDate: any
			untilDate: any
			status: ReportStatus
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
			json?: { __typename?: "ReportObject"; key: string; url: string } | null
			csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		}>
	}
}

export type AllServicesQueryVariables = Exact<{ [key: string]: never }>

export type AllServicesQuery = {
	__typename?: "Query"
	allServices: Array<{
		__typename?: "Service"
		_id: string
		key: string
		lastHeartbeatAt?: any | null
		isActive?: boolean | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type AllTagsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllTagsQuery = {
	__typename?: "Query"
	allTags: {
		__typename?: "PaginatedTagsResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		tags: Array<{
			__typename?: "ExtendedTag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
			user?: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			} | null
		}>
	}
}

export type AllUserGroupsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllUserGroupsQuery = {
	__typename?: "Query"
	allUserGroups: {
		__typename?: "PaginatedUserGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userGroups: Array<{
			__typename?: "ExtendedUserGroup"
			_id: string
			siteId: string
			userIds: Array<string>
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			users: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		}>
	}
}

export type AllUserRoleGroupsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllUserRoleGroupsQuery = {
	__typename?: "Query"
	allUserRoleGroups: {
		__typename?: "PaginatedUserRoleGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userRoleGroups: Array<{
			__typename?: "ExtendedUserRoleGroup"
			_id: string
			siteId: string
			type: UserRoleTypes
			allowedZoneIds?: Array<string> | null
			allowedZoneGroupIds?: Array<string> | null
			patrollingRouteId?: string | null
			rounds?: number | null
			departmentId: string
			workShiftId?: string | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			allowedZones?: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
			patrollingRoute?: {
				__typename?: "ExtendedPatrollingRoute"
				_id: string
				siteId: string
				zoneIds: Array<string>
				minTime: number
				maxTime: number
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				label: { __typename?: "Label"; name: string; description?: string | null }
				zones: Array<{
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}>
			} | null
			department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
		}>
	}
}

export type AllUserTypesQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllUserTypesQuery = {
	__typename?: "Query"
	allUserTypes: {
		__typename?: "PaginatedUserTypesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userTypes: Array<{ __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type AllUsersQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllUsersQuery = {
	__typename?: "Query"
	allUsers: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type AllDepartmentsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllDepartmentsQuery = {
	__typename?: "Query"
	allDepartments: {
		__typename?: "PaginatedDepartmentsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		departments: Array<{
			__typename?: "ExtendedDepartment"
			_id: string
			siteId: string
			zoneIds: Array<string>
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			zones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		}>
	}
}

export type AllZoneGroupsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllZoneGroupsQuery = {
	__typename?: "Query"
	allZoneGroups: {
		__typename?: "PaginatedZoneGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		zoneGroups: Array<{
			__typename?: "ExtendedZoneGroup"
			_id: string
			siteId: string
			zoneIds: Array<string>
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			zones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		}>
	}
}

export type AllZonesQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllZonesQuery = {
	__typename?: "Query"
	allZones: {
		__typename?: "PaginatedZonesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		zones: Array<{
			__typename?: "ExtendedZone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type DepartmentByIdQueryVariables = Exact<{
	departmentId: Scalars["String"]
}>

export type DepartmentByIdQuery = {
	__typename?: "Query"
	departmentById: {
		__typename?: "ExtendedDepartment"
		_id: string
		siteId: string
		zoneIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type DepartmentsQueryVariables = Exact<{ [key: string]: never }>

export type DepartmentsQuery = {
	__typename?: "Query"
	departments: Array<{ __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type DepartmentsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type DepartmentsByKeywordQuery = {
	__typename?: "Query"
	departmentsByKeyword: {
		__typename?: "PaginatedDepartmentsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		departments: Array<{
			__typename?: "ExtendedDepartment"
			_id: string
			siteId: string
			zoneIds: Array<string>
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			zones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		}>
	}
}

export type LastWeekRestrictedUserLocationRecordsQueryVariables = Exact<{ [key: string]: never }>

export type LastWeekRestrictedUserLocationRecordsQuery = {
	__typename?: "Query"
	lastWeekRestrictedUserLocationRecords: Array<{
		__typename?: "ExtendedUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		user: {
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}
	}>
}

export type LastestMobileAppQueryVariables = Exact<{ [key: string]: never }>

export type LastestMobileAppQuery = { __typename?: "Query"; lastestMobileApp: { __typename?: "MobileApp"; _id: string; type: MobileAppTypes; version: string; changes: string; url: string; createdAt: any; updatedAt: any } }

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "MeUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		permissionsGroupIds?: Array<string> | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
		permissionsGroups?: Array<{ __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
	} | null
}

export type MobileAppsQueryVariables = Exact<{ [key: string]: never }>

export type MobileAppsQuery = { __typename?: "Query"; mobileApps: Array<{ __typename?: "MobileApp"; _id: string; type: MobileAppTypes; version: string; changes: string; url: string; createdAt: any; updatedAt: any }> }

export type MobileAppsByFilterQueryVariables = Exact<{
	filter: MobileAppsFilterInput
	pagination: PaginatedInput
}>

export type MobileAppsByFilterQuery = {
	__typename?: "Query"
	mobileAppsByFilter: {
		__typename?: "PaginatedMobileAppsResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		mobileApps: Array<{ __typename?: "MobileApp"; _id: string; type: MobileAppTypes; version: string; changes: string; url: string; createdAt: any; updatedAt: any }>
	}
}

export type PatrollingRouteByIdQueryVariables = Exact<{
	patrollingRouteId: Scalars["String"]
}>

export type PatrollingRouteByIdQuery = {
	__typename?: "Query"
	patrollingRouteById: {
		__typename?: "ExtendedPatrollingRoute"
		_id: string
		siteId: string
		zoneIds: Array<string>
		minTime: number
		maxTime: number
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type PatrollingRoutesQueryVariables = Exact<{ [key: string]: never }>

export type PatrollingRoutesQuery = {
	__typename?: "Query"
	patrollingRoutes: Array<{
		__typename?: "ExtendedPatrollingRoute"
		_id: string
		siteId: string
		zoneIds: Array<string>
		minTime: number
		maxTime: number
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}>
}

export type PatrollingRoutesByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type PatrollingRoutesByKeywordQuery = {
	__typename?: "Query"
	patrollingRoutesByKeyword: {
		__typename?: "PaginatedPatrollingRoutesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		patrollingRoutes?: Array<{
			__typename?: "ExtendedPatrollingRoute"
			_id: string
			siteId: string
			zoneIds: Array<string>
			minTime: number
			maxTime: number
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			zones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		}> | null
	}
}

export type PatrollingUsersDashboardStatsQueryVariables = Exact<{ [key: string]: never }>

export type PatrollingUsersDashboardStatsQuery = {
	__typename?: "Query"
	patrollingUsersDashboardStats: Array<{
		__typename?: "PatrollingUserDashboardStatsResponse"
		completedRoundsWithTimeTaken: Array<number>
		expectedRounds: number
		assignedRoute: string
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
	}>
}

export type PermissionsGroupByIdQueryVariables = Exact<{
	permissionsGroupId: Scalars["String"]
}>

export type PermissionsGroupByIdQuery = {
	__typename?: "Query"
	permissionsGroupById: { __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type PermissionsGroupsQueryVariables = Exact<{ [key: string]: never }>

export type PermissionsGroupsQuery = {
	__typename?: "Query"
	permissionsGroups: Array<{ __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type PermissionsGroupsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type PermissionsGroupsByKeywordQuery = {
	__typename?: "Query"
	permissionsGroupsByKeyword: {
		__typename?: "PaginatedPermissionsGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		permissionsGroups: Array<{ __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type PermissionTagsQueryVariables = Exact<{ [key: string]: never }>

export type PermissionTagsQuery = { __typename?: "Query"; permissionTags: Array<string> }

export type PreviousWeekRestrictedUserLocationRecordsQueryVariables = Exact<{ [key: string]: never }>

export type PreviousWeekRestrictedUserLocationRecordsQuery = {
	__typename?: "Query"
	previousWeekRestrictedUserLocationRecords: Array<{
		__typename?: "ExtendedUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		user: {
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}
	}>
}

export type ReaderByIdQueryVariables = Exact<{
	readerId: Scalars["String"]
}>

export type ReaderByIdQuery = {
	__typename?: "Query"
	readerById?: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	} | null
}

export type ReadersQueryVariables = Exact<{ [key: string]: never }>

export type ReadersQuery = {
	__typename?: "Query"
	readers: Array<{
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type ReadersAtZoneQueryVariables = Exact<{
	zoneId: Scalars["String"]
}>

export type ReadersAtZoneQuery = {
	__typename?: "Query"
	readersAtZone: Array<{
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type ReadersByFilterQueryVariables = Exact<{
	filter: ReadersFilterInput
	pagination: PaginatedInput
}>

export type ReadersByFilterQuery = {
	__typename?: "Query"
	readersByFilter: {
		__typename?: "PaginatedReadersResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		readers: Array<{
			__typename?: "Reader"
			_id: string
			siteId: string
			type: ReaderTypes
			readerHexId: string
			readerDecimalId: string
			assignedToZoneId?: string | null
			assignedAt?: any | null
			isActive?: boolean | null
			lastConnectedEndpoint?: string | null
			lastConnectedAt?: any | null
			lastHeartbeatAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		}>
	}
}

export type ReportByIdQueryVariables = Exact<{
	reportId: Scalars["String"]
}>

export type ReportByIdQuery = {
	__typename?: "Query"
	reportById?: {
		__typename?: "ExtendedReport"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users?: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		lastUpdatedByUser?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	} | null
}

export type ReportsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	type?: InputMaybe<Scalars["String"]>
	status?: InputMaybe<Scalars["String"]>
	startDate?: InputMaybe<Scalars["DateTime"]>
	endDate?: InputMaybe<Scalars["DateTime"]>
	pagination: PaginatedInput
}>

export type ReportsByKeywordQuery = {
	__typename?: "Query"
	reportsByKeyword: {
		__typename?: "PaginatedReportsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		reports: Array<{
			__typename?: "Report"
			_id: string
			siteId: string
			type: ReportTypes
			userIds?: Array<string> | null
			sinceDate: any
			untilDate: any
			status: ReportStatus
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
			json?: { __typename?: "ReportObject"; key: string; url: string } | null
			csv?: { __typename?: "ReportObject"; key: string; url: string } | null
		}>
	}
}

export type RestrictedUserLocationRecordsAtZoneQueryVariables = Exact<{
	zoneId: Scalars["String"]
}>

export type RestrictedUserLocationRecordsAtZoneQuery = {
	__typename?: "Query"
	restrictedUserLocationRecordsAtZone: Array<{
		__typename?: "ExtendedUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		user: {
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}
	}>
}

export type RootTileQueryVariables = Exact<{ [key: string]: never }>

export type RootTileQuery = {
	__typename?: "Query"
	rootTile?: {
		__typename?: "Tile"
		_id: string
		embeddedZoneIds: Array<string>
		embedsAllZones: boolean
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	} | null
}

export type ServiceByIdQueryVariables = Exact<{
	serviceId: Scalars["String"]
}>

export type ServiceByIdQuery = {
	__typename?: "Query"
	serviceById: { __typename?: "Service"; _id: string; key: string; lastHeartbeatAt?: any | null; isActive?: boolean | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
}

export type ServicesByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
}>

export type ServicesByKeywordQuery = {
	__typename?: "Query"
	servicesByKeyword: Array<{
		__typename?: "Service"
		_id: string
		key: string
		lastHeartbeatAt?: any | null
		isActive?: boolean | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type StatusLogsOfReaderQueryVariables = Exact<{
	readerId: Scalars["String"]
	date: Scalars["DateTime"]
	pagination: PaginatedInput
}>

export type StatusLogsOfReaderQuery = {
	__typename?: "Query"
	statusLogsOfReader: {
		__typename?: "PaginatedReaderStatusLogsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		readerStatusLogs: Array<{ __typename?: "ReaderStatusLog"; _id: string; siteId: string; readerId: string; isActive?: boolean | null; connectedAt: any; disconnectedAt?: any | null; createdAt: any; updatedAt: any }>
	}
}

export type TagByIdQueryVariables = Exact<{
	tagId: Scalars["String"]
}>

export type TagByIdQuery = {
	__typename?: "Query"
	tagById?: {
		__typename?: "ExtendedTag"
		_id: string
		name?: string | null
		tagHexId: string
		tagDecimalId: string
		status: TagStatus
		batteryStatus: TagBatteryStatus
		assignedToUserId?: string | null
		assignedAt?: any | null
		createdAt: any
		updatedAt: any
		user?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
	} | null
}

export type TagsByFilterQueryVariables = Exact<{
	filter: TagsFilterInput
	pagination: PaginatedInput
}>

export type TagsByFilterQuery = {
	__typename?: "Query"
	tagsByFilter: {
		__typename?: "PaginatedTagsResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		tags: Array<{
			__typename?: "ExtendedTag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
			user?: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			} | null
		}>
	}
}

export type TileByIdQueryVariables = Exact<{
	tileId: Scalars["String"]
}>

export type TileByIdQuery = {
	__typename?: "Query"
	tileById?: {
		__typename?: "Tile"
		_id: string
		embeddedZoneIds: Array<string>
		embedsAllZones: boolean
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	} | null
}

export type TileEmbeddedZonesByTileIdQueryVariables = Exact<{
	tileId: Scalars["String"]
}>

export type TileEmbeddedZonesByTileIdQuery = {
	__typename?: "Query"
	tileEmbeddedZonesByTileId: Array<{
		__typename?: "ExtendedTileEmbeddedZone"
		_id: string
		siteId: string
		tileId: string
		zoneId: string
		position: { __typename?: "Position"; topPercent: number; leftPercent: number }
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
	}>
}

export type TilesQueryVariables = Exact<{ [key: string]: never }>

export type TilesQuery = {
	__typename?: "Query"
	tiles: Array<{
		__typename?: "Tile"
		_id: string
		embeddedZoneIds: Array<string>
		embedsAllZones: boolean
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}>
}

export type TodayAttendanceStatsQueryVariables = Exact<{
	date: Scalars["DateTime"]
}>

export type TodayAttendanceStatsQuery = {
	__typename?: "Query"
	todayAttendanceStats: {
		__typename?: "UserAttendanceStatsResponse"
		present: Array<{
			__typename?: "ExtendedUserAttendance"
			_id: string
			siteId: string
			userId: string
			inAt: any
			outAt?: any | null
			inImage?: string | null
			inRemarks?: string | null
			outRemarks?: string | null
			outImage?: string | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			user: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
		}>
		absent: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type TodayRestrictedUserLocationRecordsQueryVariables = Exact<{ [key: string]: never }>

export type TodayRestrictedUserLocationRecordsQuery = { __typename?: "Query"; todayRestrictedUserLocationRecords: Array<string> }

export type TodayTrackedUserIdsQueryVariables = Exact<{ [key: string]: never }>

export type TodayTrackedUserIdsQuery = { __typename?: "Query"; todayTrackedUserIds: Array<string> }

export type TodaysShiftWiseAttendanceStatsQueryVariables = Exact<{
	date: Scalars["DateTime"]
}>

export type TodaysShiftWiseAttendanceStatsQuery = {
	__typename?: "Query"
	todaysShiftWiseAttendanceStats: {
		__typename?: "UserShiftWiseAttendanceStatsResponse"
		morningPresent: Array<{
			__typename?: "ExtendedUserAttendance"
			_id: string
			siteId: string
			userId: string
			inAt: any
			outAt?: any | null
			inImage?: string | null
			inRemarks?: string | null
			outRemarks?: string | null
			outImage?: string | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			user: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
		}>
		morningAbsent: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
		eveningPresent: Array<{
			__typename?: "ExtendedUserAttendance"
			_id: string
			siteId: string
			userId: string
			inAt: any
			outAt?: any | null
			inImage?: string | null
			inRemarks?: string | null
			outRemarks?: string | null
			outImage?: string | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			user: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
		}>
		eveningAbsent: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type TodaysUserAttendanceQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type TodaysUserAttendanceQuery = {
	__typename?: "Query"
	todaysUserAttendance?: {
		__typename?: "ExtendedUserAttendance"
		_id: string
		siteId: string
		userId: string
		inAt: any
		outAt?: any | null
		inImage?: string | null
		inRemarks?: string | null
		outRemarks?: string | null
		outImage?: string | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
	} | null
}

export type UserActiveRouteRecordQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserActiveRouteRecordQuery = {
	__typename?: "Query"
	userActiveRouteRecord?: {
		__typename?: "ExtendedUserRouteRecord"
		_id: string
		siteId: string
		userId: string
		startAt: any
		endAt: any
		patrollingRouteId: string
		expectedRouteZoneIds: Array<string>
		expectedMinTimeInMinutes: number
		expectedMaxTimeInMinutes: number
		locationRecordIds?: Array<string> | null
		isRouteCorrect?: boolean | null
		isTimeCorrect?: boolean | null
		isTimeHigh?: boolean | null
		isTimeLow?: boolean | null
		isFinal?: boolean | null
		isPartiallyFinal?: boolean | null
		timeTakenInMinutes: number
		createdAt: any
		updatedAt: any
		expectedRouteZones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
		locationRecords?: Array<{
			__typename?: "RouteUserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
		}> | null
		routePoints?: Array<{
			__typename?: "ExtendedRoutePoint"
			guardOnDuty?: boolean | null
			areaIntact?: boolean | null
			isSkipped?: boolean | null
			skipRemarks?: string | null
			zoneId: string
			locationRecordId?: string | null
			createdAt: any
			updatedAt: any
			guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
			areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			locationRecord?: {
				__typename?: "UserLocationRecord"
				_id: string
				siteId: string
				zoneId: string
				userId: string
				startAt: any
				endAt: any
				status: UserLocationRecordStatus
				isRestricted?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
		}> | null
		findings?: Array<{ __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }> | null
	} | null
}

export type UserByIdQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserByIdQuery = {
	__typename?: "Query"
	userById?: {
		__typename?: "ExtendedUser"
		_id: string
		siteId: string
		scopedSiteIds: Array<string>
		name: string
		username: string
		address?: string | null
		typeId: string
		referenceNo?: string | null
		tagId?: string | null
		isBlacklisted?: boolean | null
		blacklistRemarks?: string | null
		status: UserStatus
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
		phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
		email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
		type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
		tag?: {
			__typename?: "Tag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
		} | null
	} | null
}

export type UserCurrentLocationRecordQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserCurrentLocationRecordQuery = {
	__typename?: "Query"
	userCurrentLocationRecord?: {
		__typename?: "ExtendedUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		user: {
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}
	} | null
}

export type UserCurrentRouteRecordQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserCurrentRouteRecordQuery = {
	__typename?: "Query"
	userCurrentRouteRecord?: {
		__typename?: "ExtendedUserRouteRecord"
		_id: string
		siteId: string
		userId: string
		startAt: any
		endAt: any
		patrollingRouteId: string
		expectedRouteZoneIds: Array<string>
		expectedMinTimeInMinutes: number
		expectedMaxTimeInMinutes: number
		locationRecordIds?: Array<string> | null
		isRouteCorrect?: boolean | null
		isTimeCorrect?: boolean | null
		isTimeHigh?: boolean | null
		isTimeLow?: boolean | null
		isFinal?: boolean | null
		isPartiallyFinal?: boolean | null
		timeTakenInMinutes: number
		createdAt: any
		updatedAt: any
		expectedRouteZones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
		locationRecords?: Array<{
			__typename?: "RouteUserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
		}> | null
		routePoints?: Array<{
			__typename?: "ExtendedRoutePoint"
			guardOnDuty?: boolean | null
			areaIntact?: boolean | null
			isSkipped?: boolean | null
			skipRemarks?: string | null
			zoneId: string
			locationRecordId?: string | null
			createdAt: any
			updatedAt: any
			guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
			areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			locationRecord?: {
				__typename?: "UserLocationRecord"
				_id: string
				siteId: string
				zoneId: string
				userId: string
				startAt: any
				endAt: any
				status: UserLocationRecordStatus
				isRestricted?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
		}> | null
		findings?: Array<{ __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }> | null
	} | null
}

export type UserCurrentUserRoleQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserCurrentUserRoleQuery = {
	__typename?: "Query"
	userCurrentUserRole?: {
		__typename?: "ExtendedUserRole"
		_id: string
		siteId: string
		userId: string
		type: UserRoleTypes
		userRoleGroupId: string
		designation?: string | null
		startAt: any
		endAt?: any | null
		assignedAt: any
		removedAt?: any | null
		status: UserRoleStatus
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		user: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		userRoleGroup: {
			__typename?: "ExtendedUserRoleGroup"
			_id: string
			siteId: string
			type: UserRoleTypes
			allowedZoneIds?: Array<string> | null
			allowedZoneGroupIds?: Array<string> | null
			patrollingRouteId?: string | null
			rounds?: number | null
			departmentId: string
			workShiftId?: string | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			allowedZones?: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
			patrollingRoute?: {
				__typename?: "ExtendedPatrollingRoute"
				_id: string
				siteId: string
				zoneIds: Array<string>
				minTime: number
				maxTime: number
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				label: { __typename?: "Label"; name: string; description?: string | null }
				zones: Array<{
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}>
			} | null
			department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
		}
	} | null
}

export type UserGroupByIdQueryVariables = Exact<{
	userGroupId: Scalars["String"]
}>

export type UserGroupByIdQuery = {
	__typename?: "Query"
	userGroupById: {
		__typename?: "ExtendedUserGroup"
		_id: string
		siteId: string
		userIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type UserGroupsQueryVariables = Exact<{ [key: string]: never }>

export type UserGroupsQuery = {
	__typename?: "Query"
	userGroups: Array<{
		__typename?: "ExtendedUserGroup"
		_id: string
		siteId: string
		userIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		users: Array<{
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}>
}

export type UserGroupsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type UserGroupsByKeywordQuery = {
	__typename?: "Query"
	userGroupsByKeyword: {
		__typename?: "PaginatedUserGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userGroups: Array<{
			__typename?: "ExtendedUserGroup"
			_id: string
			siteId: string
			userIds: Array<string>
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			users: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		}>
	}
}

export type UserLocationRecordsAtZoneQueryVariables = Exact<{
	zoneId: Scalars["String"]
	userTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>
}>

export type UserLocationRecordsAtZoneQuery = {
	__typename?: "Query"
	userLocationRecordsAtZone: Array<{
		__typename?: "ExtendedUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		user: {
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}
	}>
}

export type UserLocationStatsQueryVariables = Exact<{
	userId: Scalars["String"]
	startTimestamp: Scalars["DateTime"]
	endTimestamp: Scalars["DateTime"]
}>

export type UserLocationStatsQuery = { __typename?: "Query"; userLocationStats: Array<{ __typename?: "UserLocationStatsResponse"; startAt: any; endAt: any; zone: { __typename?: "UserLocationStatsZone"; _id: string; name: string } }> }

export type UserPastLocationRecordsQueryVariables = Exact<{
	userId: Scalars["String"]
	date: Scalars["DateTime"]
	pagination: PaginatedInput
}>

export type UserPastLocationRecordsQuery = {
	__typename?: "Query"
	userPastLocationRecords: {
		__typename?: "PaginatedUserLocationRecordsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userLocationRecords: Array<{
			__typename?: "ExtendedUserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			user: {
				__typename?: "ExtendedUser"
				_id: string
				siteId: string
				scopedSiteIds: Array<string>
				name: string
				username: string
				address?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
				type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
				tag?: {
					__typename?: "Tag"
					_id: string
					name?: string | null
					tagHexId: string
					tagDecimalId: string
					status: TagStatus
					batteryStatus: TagBatteryStatus
					assignedToUserId?: string | null
					assignedAt?: any | null
					createdAt: any
					updatedAt: any
				} | null
			}
		}>
	}
}

export type UserPastRouteRecordsQueryVariables = Exact<{
	userId: Scalars["String"]
	date: Scalars["DateTime"]
	pagination: PaginatedInput
}>

export type UserPastRouteRecordsQuery = {
	__typename?: "Query"
	userPastRouteRecords: {
		__typename?: "PaginatedUserRouteRecordsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userRouteRecords: Array<{
			__typename?: "ExtendedUserRouteRecord"
			_id: string
			siteId: string
			userId: string
			startAt: any
			endAt: any
			patrollingRouteId: string
			expectedRouteZoneIds: Array<string>
			expectedMinTimeInMinutes: number
			expectedMaxTimeInMinutes: number
			locationRecordIds?: Array<string> | null
			isRouteCorrect?: boolean | null
			isTimeCorrect?: boolean | null
			isTimeHigh?: boolean | null
			isTimeLow?: boolean | null
			isFinal?: boolean | null
			isPartiallyFinal?: boolean | null
			timeTakenInMinutes: number
			createdAt: any
			updatedAt: any
			expectedRouteZones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
			locationRecords?: Array<{
				__typename?: "RouteUserLocationRecord"
				_id: string
				siteId: string
				zoneId: string
				userId: string
				startAt: any
				endAt: any
				status: UserLocationRecordStatus
				isRestricted?: boolean | null
				createdAt: any
				updatedAt: any
				zone: {
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
			}> | null
			routePoints?: Array<{
				__typename?: "ExtendedRoutePoint"
				guardOnDuty?: boolean | null
				areaIntact?: boolean | null
				isSkipped?: boolean | null
				skipRemarks?: string | null
				zoneId: string
				locationRecordId?: string | null
				createdAt: any
				updatedAt: any
				guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
				areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
				zone: {
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
				locationRecord?: {
					__typename?: "UserLocationRecord"
					_id: string
					siteId: string
					zoneId: string
					userId: string
					startAt: any
					endAt: any
					status: UserLocationRecordStatus
					isRestricted?: boolean | null
					createdAt: any
					updatedAt: any
				} | null
			}> | null
			findings?: Array<{ __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }> | null
		}>
	}
}

export type UserPermissionsGroupsQueryVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserPermissionsGroupsQuery = {
	__typename?: "Query"
	userPermissionsGroups?: Array<{ __typename?: "PermissionsGroup"; _id: string; tags: Array<string>; isPrivateToAdmin?: boolean | null; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
}

export type UserRoleGroupByIdQueryVariables = Exact<{
	userRoleGroupId: Scalars["String"]
}>

export type UserRoleGroupByIdQuery = {
	__typename?: "Query"
	userRoleGroupById?: {
		__typename?: "ExtendedUserRoleGroup"
		_id: string
		siteId: string
		type: UserRoleTypes
		allowedZoneIds?: Array<string> | null
		allowedZoneGroupIds?: Array<string> | null
		patrollingRouteId?: string | null
		rounds?: number | null
		departmentId: string
		workShiftId?: string | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		allowedZones?: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}> | null
		allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
		patrollingRoute?: {
			__typename?: "ExtendedPatrollingRoute"
			_id: string
			siteId: string
			zoneIds: Array<string>
			minTime: number
			maxTime: number
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			zones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		} | null
		department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
	} | null
}

export type UserRoleGroupsQueryVariables = Exact<{
	type?: InputMaybe<UserRoleTypes>
}>

export type UserRoleGroupsQuery = {
	__typename?: "Query"
	userRoleGroups: Array<{
		__typename?: "UserRoleGroup"
		_id: string
		siteId: string
		type: UserRoleTypes
		allowedZoneIds?: Array<string> | null
		allowedZoneGroupIds?: Array<string> | null
		departmentId: string
		workShiftId?: string | null
		patrollingRouteId?: string | null
		rounds?: number | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}>
}

export type UserRoleGroupsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type UserRoleGroupsByKeywordQuery = {
	__typename?: "Query"
	userRoleGroupsByKeyword: {
		__typename?: "PaginatedUserRoleGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userRoleGroups: Array<{
			__typename?: "ExtendedUserRoleGroup"
			_id: string
			siteId: string
			type: UserRoleTypes
			allowedZoneIds?: Array<string> | null
			allowedZoneGroupIds?: Array<string> | null
			patrollingRouteId?: string | null
			rounds?: number | null
			departmentId: string
			workShiftId?: string | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			allowedZones?: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
			patrollingRoute?: {
				__typename?: "ExtendedPatrollingRoute"
				_id: string
				siteId: string
				zoneIds: Array<string>
				minTime: number
				maxTime: number
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				label: { __typename?: "Label"; name: string; description?: string | null }
				zones: Array<{
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}>
			} | null
			department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
		}>
	}
}

export type UserTypeByIdQueryVariables = Exact<{
	userTypeId: Scalars["String"]
}>

export type UserTypeByIdQuery = { __typename?: "Query"; userTypeById: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } } }

export type UserTypesQueryVariables = Exact<{ [key: string]: never }>

export type UserTypesQuery = { __typename?: "Query"; userTypes: Array<{ __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }> }

export type UserTypesByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type UserTypesByKeywordQuery = {
	__typename?: "Query"
	userTypesByKeyword: {
		__typename?: "PaginatedUserTypesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		userTypes: Array<{ __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type UsersQueryVariables = Exact<{
	filter: UsersFilterInput
	pagination: PaginatedInput
}>

export type UsersQuery = {
	__typename?: "Query"
	users: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type UsersByCurrentUserRoleGroupQueryVariables = Exact<{
	userRoleGroupId: Scalars["String"]
	pagination: PaginatedInput
}>

export type UsersByCurrentUserRoleGroupQuery = {
	__typename?: "Query"
	usersByCurrentUserRoleGroup: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type UsersByDepartmentQueryVariables = Exact<{
	departmentId: Scalars["String"]
	pagination: PaginatedInput
}>

export type UsersByDepartmentQuery = {
	__typename?: "Query"
	usersByDepartment: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type UsersByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	typeId?: InputMaybe<Scalars["String"]>
	pagination: PaginatedInput
}>

export type UsersByKeywordQuery = {
	__typename?: "Query"
	usersByKeyword: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type UsersByTypeQueryVariables = Exact<{
	typeId: Scalars["String"]
	pagination: PaginatedInput
}>

export type UsersByTypeQuery = {
	__typename?: "Query"
	usersByType: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type UsersByWorkShiftQueryVariables = Exact<{
	workShiftId: Scalars["String"]
	pagination: PaginatedInput
}>

export type UsersByWorkShiftQuery = {
	__typename?: "Query"
	usersByWorkShift: {
		__typename?: "PaginatedUsersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		users: Array<{
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}>
	}
}

export type WorkShiftByIdQueryVariables = Exact<{
	workShiftId: Scalars["String"]
}>

export type WorkShiftByIdQuery = {
	__typename?: "Query"
	workShiftById: {
		__typename?: "ExtendedWorkShift"
		_id: string
		siteId: string
		startAt: string
		endAt: string
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type WorkShiftsQueryVariables = Exact<{ [key: string]: never }>

export type WorkShiftsQuery = {
	__typename?: "Query"
	workShifts: Array<{ __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
}

export type WorkShiftsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type WorkShiftsByKeywordQuery = {
	__typename?: "Query"
	workShiftsByKeyword: {
		__typename?: "PaginatedWorkShiftsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		workShifts: Array<{ __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
	}
}

export type ZoneByIdQueryVariables = Exact<{
	zoneId: Scalars["String"]
}>

export type ZoneByIdQuery = {
	__typename?: "Query"
	zoneById: {
		__typename?: "ExtendedZone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}
}

export type ZoneGroupByIdQueryVariables = Exact<{
	zoneGroupId: Scalars["String"]
}>

export type ZoneGroupByIdQuery = {
	__typename?: "Query"
	zoneGroupById: {
		__typename?: "ExtendedZoneGroup"
		_id: string
		siteId: string
		zoneIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type ZoneGroupsQueryVariables = Exact<{ [key: string]: never }>

export type ZoneGroupsQuery = {
	__typename?: "Query"
	zoneGroups: Array<{
		__typename?: "ExtendedZoneGroup"
		_id: string
		siteId: string
		zoneIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}>
}

export type ZoneGroupsByFilterQueryVariables = Exact<{
	filter: ZoneGroupsFilterInput
}>

export type ZoneGroupsByFilterQuery = {
	__typename?: "Query"
	zoneGroupsByFilter: Array<{
		__typename?: "ExtendedZoneGroup"
		_id: string
		siteId: string
		zoneIds: Array<string>
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		label: { __typename?: "Label"; name: string; description?: string | null }
		zones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}>
}

export type ZoneGroupsByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type ZoneGroupsByKeywordQuery = {
	__typename?: "Query"
	zoneGroupsByKeyword: {
		__typename?: "PaginatedZoneGroupsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		zoneGroups: Array<{
			__typename?: "ExtendedZoneGroup"
			_id: string
			siteId: string
			zoneIds: Array<string>
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			zones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
		}>
	}
}

export type ZoneStatsQueryVariables = Exact<{
	zoneId: Scalars["String"]
	startTimestamp: Scalars["DateTime"]
	endTimestamp: Scalars["DateTime"]
}>

export type ZoneStatsQuery = { __typename?: "Query"; zoneStats: Array<{ __typename?: "ZoneStats"; _id: string; siteType: SiteTypes; zoneId: string; timeStamp: any; usersCount: number; createdAt: any; updatedAt: any }> }

export type ZonesQueryVariables = Exact<{ [key: string]: never }>

export type ZonesQuery = {
	__typename?: "Query"
	zones: Array<{
		__typename?: "Zone"
		_id: string
		siteId: string
		readerIds?: Array<string> | null
		type: ZoneTypes
		isActive?: boolean | null
		isCrowded?: boolean | null
		crowdLimit: number
		idleSince?: any | null
		label: { __typename?: "Label"; name: string; description?: string | null }
		picture?: {
			__typename?: "Picture"
			original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
			variants?: {
				__typename?: "PictureVariants"
				small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
			} | null
		} | null
	}>
}

export type ZonesByFilterQueryVariables = Exact<{
	filter: ZonesFilterInput
	pagination: PaginatedInput
}>

export type ZonesByFilterQuery = {
	__typename?: "Query"
	zonesByFilter: {
		__typename?: "PaginatedZonesResponse"
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		zones: Array<{
			__typename?: "ExtendedZone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type ZonesByKeywordQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type ZonesByKeywordQuery = {
	__typename?: "Query"
	zonesByKeyword: {
		__typename?: "PaginatedZonesResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		currentPage: number
		zones: Array<{
			__typename?: "ExtendedZone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
	}
}

export type AlertsUpdateSubscriptionVariables = Exact<{ [key: string]: never }>

export type AlertsUpdateSubscription = {
	__typename?: "Subscription"
	alertsUpdate: {
		__typename?: "ExtendedAlert"
		_id: string
		siteId: string
		type: AlertTypes
		alertFlowId: string
		emailMessage: string
		notificationMessage: string
		userId?: string | null
		userRoleId?: string | null
		zoneId?: string | null
		readerId?: string | null
		tagId?: string | null
		userLocationRecordId?: string | null
		userRouteRecordId?: string | null
		toBeEscalated?: boolean | null
		escalateAfter?: any | null
		isResolved?: boolean | null
		createdAt: any
		updatedAt: any
		site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
		alertFlow: {
			__typename?: "AlertFlow"
			_id: string
			siteId: string
			type: AlertTypes
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
			source?: { __typename?: "AlertSource"; type: AlertSourceTypes; zoneIds?: Array<string> | null; zoneGroupIds?: Array<string> | null; userIds?: Array<string> | null; userGroupIds?: Array<string> | null } | null
			levels: Array<{ __typename?: "AlertTargetLevel"; targetGroupIds: Array<string>; timeout: number }>
		}
		levels: Array<{
			__typename?: "ExtendedAlertLevel"
			alertedUserIds?: Array<string> | null
			emailedUserIds?: Array<string> | null
			alertedUsers?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			emailedUsers?: Array<{
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}> | null
			responses: Array<{
				__typename?: "ExtendedAlertResponse"
				userId: string
				acknowlegedAt: any
				acknowlegment?: string | null
				resolvedAt?: any | null
				resolution?: string | null
				user: {
					__typename?: "User"
					_id: string
					siteId: string
					name: string
					username: string
					address?: string | null
					isBlacklisted?: boolean | null
					blacklistRemarks?: string | null
					typeId: string
					referenceNo?: string | null
					tagId?: string | null
					status: UserStatus
					createdAt: any
					updatedAt: any
					phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
					email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
			}>
		}>
		user?: {
			__typename?: "User"
			_id: string
			siteId: string
			name: string
			username: string
			address?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
		userRole?: {
			__typename?: "ExtendedUserRole"
			_id: string
			siteId: string
			userId: string
			type: UserRoleTypes
			userRoleGroupId: string
			designation?: string | null
			startAt: any
			endAt?: any | null
			assignedAt: any
			removedAt?: any | null
			status: UserRoleStatus
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			user: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			userRoleGroup: {
				__typename?: "ExtendedUserRoleGroup"
				_id: string
				siteId: string
				type: UserRoleTypes
				allowedZoneIds?: Array<string> | null
				allowedZoneGroupIds?: Array<string> | null
				patrollingRouteId?: string | null
				rounds?: number | null
				departmentId: string
				workShiftId?: string | null
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				label: { __typename?: "Label"; name: string; description?: string | null }
				allowedZones?: Array<{
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}> | null
				allowedZoneGroups?: Array<{ __typename?: "ZoneGroup"; _id: string; siteId: string; zoneIds: Array<string>; label: { __typename?: "Label"; name: string; description?: string | null } }> | null
				patrollingRoute?: {
					__typename?: "ExtendedPatrollingRoute"
					_id: string
					siteId: string
					zoneIds: Array<string>
					minTime: number
					maxTime: number
					createdAt: any
					updatedAt: any
					site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
					label: { __typename?: "Label"; name: string; description?: string | null }
					zones: Array<{
						__typename?: "Zone"
						_id: string
						siteId: string
						readerIds?: Array<string> | null
						type: ZoneTypes
						isActive?: boolean | null
						isCrowded?: boolean | null
						crowdLimit: number
						idleSince?: any | null
						label: { __typename?: "Label"; name: string; description?: string | null }
						picture?: {
							__typename?: "Picture"
							original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
							variants?: {
								__typename?: "PictureVariants"
								small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
								thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							} | null
						} | null
					}>
				} | null
				department: { __typename?: "Department"; _id: string; siteId: string; zoneIds: Array<string>; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				workShift?: { __typename?: "WorkShift"; _id: string; siteId: string; startAt: string; endAt: string; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } } | null
			}
		} | null
		zone?: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		} | null
		reader?: {
			__typename?: "Reader"
			_id: string
			siteId: string
			type: ReaderTypes
			readerHexId: string
			readerDecimalId: string
			assignedToZoneId?: string | null
			assignedAt?: any | null
			isActive?: boolean | null
			lastConnectedEndpoint?: string | null
			lastConnectedAt?: any | null
			lastHeartbeatAt?: any | null
			createdAt: any
			updatedAt: any
			label: { __typename?: "Label"; name: string; description?: string | null }
		} | null
		tag?: {
			__typename?: "ExtendedTag"
			_id: string
			name?: string | null
			tagHexId: string
			tagDecimalId: string
			status: TagStatus
			batteryStatus: TagBatteryStatus
			assignedToUserId?: string | null
			assignedAt?: any | null
			createdAt: any
			updatedAt: any
			user?: {
				__typename?: "User"
				_id: string
				siteId: string
				name: string
				username: string
				address?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			} | null
		} | null
		userLocationRecord?: {
			__typename?: "ExtendedUserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			user: {
				__typename?: "ExtendedUser"
				_id: string
				siteId: string
				scopedSiteIds: Array<string>
				name: string
				username: string
				address?: string | null
				typeId: string
				referenceNo?: string | null
				tagId?: string | null
				isBlacklisted?: boolean | null
				blacklistRemarks?: string | null
				status: UserStatus
				createdAt: any
				updatedAt: any
				site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
				scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
				phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
				email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
				type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
				tag?: {
					__typename?: "Tag"
					_id: string
					name?: string | null
					tagHexId: string
					tagDecimalId: string
					status: TagStatus
					batteryStatus: TagBatteryStatus
					assignedToUserId?: string | null
					assignedAt?: any | null
					createdAt: any
					updatedAt: any
				} | null
			}
		} | null
		userRouteRecord?: {
			__typename?: "ExtendedUserRouteRecord"
			_id: string
			siteId: string
			userId: string
			startAt: any
			endAt: any
			patrollingRouteId: string
			expectedRouteZoneIds: Array<string>
			expectedMinTimeInMinutes: number
			expectedMaxTimeInMinutes: number
			locationRecordIds?: Array<string> | null
			isRouteCorrect?: boolean | null
			isTimeCorrect?: boolean | null
			isTimeHigh?: boolean | null
			isTimeLow?: boolean | null
			isFinal?: boolean | null
			isPartiallyFinal?: boolean | null
			timeTakenInMinutes: number
			createdAt: any
			updatedAt: any
			expectedRouteZones: Array<{
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}>
			locationRecords?: Array<{
				__typename?: "RouteUserLocationRecord"
				_id: string
				siteId: string
				zoneId: string
				userId: string
				startAt: any
				endAt: any
				status: UserLocationRecordStatus
				isRestricted?: boolean | null
				createdAt: any
				updatedAt: any
				zone: {
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
			}> | null
			routePoints?: Array<{
				__typename?: "ExtendedRoutePoint"
				guardOnDuty?: boolean | null
				areaIntact?: boolean | null
				isSkipped?: boolean | null
				skipRemarks?: string | null
				zoneId: string
				locationRecordId?: string | null
				createdAt: any
				updatedAt: any
				guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
				areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
				zone: {
					__typename?: "Zone"
					_id: string
					siteId: string
					readerIds?: Array<string> | null
					type: ZoneTypes
					isActive?: boolean | null
					isCrowded?: boolean | null
					crowdLimit: number
					idleSince?: any | null
					label: { __typename?: "Label"; name: string; description?: string | null }
					picture?: {
						__typename?: "Picture"
						original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
						variants?: {
							__typename?: "PictureVariants"
							small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
							thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						} | null
					} | null
				}
				locationRecord?: {
					__typename?: "UserLocationRecord"
					_id: string
					siteId: string
					zoneId: string
					userId: string
					startAt: any
					endAt: any
					status: UserLocationRecordStatus
					isRestricted?: boolean | null
					createdAt: any
					updatedAt: any
				} | null
			}> | null
			findings?: Array<{ __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }> | null
		} | null
	}
}

export type ReaderUpdateSubscriptionVariables = Exact<{
	readerId: Scalars["String"]
}>

export type ReaderUpdateSubscription = {
	__typename?: "Subscription"
	readerUpdate: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type ReaderUpdateAtZoneSubscriptionVariables = Exact<{
	zoneId: Scalars["String"]
}>

export type ReaderUpdateAtZoneSubscription = {
	__typename?: "Subscription"
	readerUpdateAtZone: {
		__typename?: "Reader"
		_id: string
		siteId: string
		type: ReaderTypes
		readerHexId: string
		readerDecimalId: string
		assignedToZoneId?: string | null
		assignedAt?: any | null
		isActive?: boolean | null
		lastConnectedEndpoint?: string | null
		lastConnectedAt?: any | null
		lastHeartbeatAt?: any | null
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
	}
}

export type ReportUpdateSubscriptionVariables = Exact<{
	reportId: Scalars["String"]
}>

export type ReportUpdateSubscription = {
	__typename?: "Subscription"
	reportUpdate: {
		__typename?: "Report"
		_id: string
		siteId: string
		type: ReportTypes
		userIds?: Array<string> | null
		sinceDate: any
		untilDate: any
		status: ReportStatus
		createdAt: any
		updatedAt: any
		label: { __typename?: "Label"; name: string; description?: string | null }
		completeJson?: { __typename?: "ReportObject"; key: string; url: string } | null
		json?: { __typename?: "ReportObject"; key: string; url: string } | null
		csv?: { __typename?: "ReportObject"; key: string; url: string } | null
	}
}

export type RestrictedUserLocationRecordsUpdateSubscriptionVariables = Exact<{
	recordIds: Array<Scalars["String"]> | Scalars["String"]
}>

export type RestrictedUserLocationRecordsUpdateSubscription = {
	__typename?: "Subscription"
	restrictedUserLocationRecordsUpdate: {
		__typename?: "ExtendedUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		user: {
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}
	}
}

export type UserLocationRecordUpdateSubscriptionVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserLocationRecordUpdateSubscription = {
	__typename?: "Subscription"
	userLocationRecordUpdate: {
		__typename?: "ExtendedUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		user: {
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}
	}
}

export type UserLocationRecordUpdateAtZoneSubscriptionVariables = Exact<{
	zoneId: Scalars["String"]
}>

export type UserLocationRecordUpdateAtZoneSubscription = {
	__typename?: "Subscription"
	userLocationRecordUpdateAtZone: {
		__typename?: "ExtendedUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		user: {
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}
	}
}

export type UserLocationRecordsUpdateSubscriptionVariables = Exact<{ [key: string]: never }>

export type UserLocationRecordsUpdateSubscription = {
	__typename?: "Subscription"
	userLocationRecordsUpdate: {
		__typename?: "ExtendedUserLocationRecord"
		_id: string
		siteId: string
		zoneId: string
		userId: string
		startAt: any
		endAt: any
		status: UserLocationRecordStatus
		isRestricted?: boolean | null
		createdAt: any
		updatedAt: any
		zone: {
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}
		user: {
			__typename?: "ExtendedUser"
			_id: string
			siteId: string
			scopedSiteIds: Array<string>
			name: string
			username: string
			address?: string | null
			typeId: string
			referenceNo?: string | null
			tagId?: string | null
			isBlacklisted?: boolean | null
			blacklistRemarks?: string | null
			status: UserStatus
			createdAt: any
			updatedAt: any
			site: { __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }
			scopedSites: Array<{ __typename?: "Site"; _id: string; manpowerCount?: number | null; createdAt: any; updatedAt: any; label: { __typename?: "Label"; name: string; description?: string | null } }>
			phone?: { __typename?: "Phone"; countryCode: string; number: string } | null
			email?: { __typename?: "Email"; address: string; isVerified: boolean; verifiedAt?: any | null } | null
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
			type: { __typename?: "UserType"; _id: string; siteId: string; roleType: UserRoleTypes; label: { __typename?: "Label"; name: string; description?: string | null } }
			tag?: {
				__typename?: "Tag"
				_id: string
				name?: string | null
				tagHexId: string
				tagDecimalId: string
				status: TagStatus
				batteryStatus: TagBatteryStatus
				assignedToUserId?: string | null
				assignedAt?: any | null
				createdAt: any
				updatedAt: any
			} | null
		}
	}
}

export type UserRouteRecordsUpdateSubscriptionVariables = Exact<{
	userId: Scalars["String"]
}>

export type UserRouteRecordsUpdateSubscription = {
	__typename?: "Subscription"
	userRouteRecordsUpdate?: {
		__typename?: "ExtendedUserRouteRecord"
		_id: string
		siteId: string
		userId: string
		startAt: any
		endAt: any
		patrollingRouteId: string
		expectedRouteZoneIds: Array<string>
		expectedMinTimeInMinutes: number
		expectedMaxTimeInMinutes: number
		locationRecordIds?: Array<string> | null
		isRouteCorrect?: boolean | null
		isTimeCorrect?: boolean | null
		isTimeHigh?: boolean | null
		isTimeLow?: boolean | null
		isFinal?: boolean | null
		isPartiallyFinal?: boolean | null
		timeTakenInMinutes: number
		createdAt: any
		updatedAt: any
		expectedRouteZones: Array<{
			__typename?: "Zone"
			_id: string
			siteId: string
			readerIds?: Array<string> | null
			type: ZoneTypes
			isActive?: boolean | null
			isCrowded?: boolean | null
			crowdLimit: number
			idleSince?: any | null
			label: { __typename?: "Label"; name: string; description?: string | null }
			picture?: {
				__typename?: "Picture"
				original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
				variants?: {
					__typename?: "PictureVariants"
					small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
				} | null
			} | null
		}>
		locationRecords?: Array<{
			__typename?: "RouteUserLocationRecord"
			_id: string
			siteId: string
			zoneId: string
			userId: string
			startAt: any
			endAt: any
			status: UserLocationRecordStatus
			isRestricted?: boolean | null
			createdAt: any
			updatedAt: any
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
		}> | null
		routePoints?: Array<{
			__typename?: "ExtendedRoutePoint"
			guardOnDuty?: boolean | null
			areaIntact?: boolean | null
			isSkipped?: boolean | null
			skipRemarks?: string | null
			zoneId: string
			locationRecordId?: string | null
			createdAt: any
			updatedAt: any
			guardImage?: { __typename?: "PictureObject"; key: string; url: string } | null
			areaImage?: { __typename?: "PictureObject"; key: string; url: string } | null
			zone: {
				__typename?: "Zone"
				_id: string
				siteId: string
				readerIds?: Array<string> | null
				type: ZoneTypes
				isActive?: boolean | null
				isCrowded?: boolean | null
				crowdLimit: number
				idleSince?: any | null
				label: { __typename?: "Label"; name: string; description?: string | null }
				picture?: {
					__typename?: "Picture"
					original: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null }
					variants?: {
						__typename?: "PictureVariants"
						small?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						medium?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						large?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
						thumbnail?: { __typename?: "PictureObject"; key: string; url: string; height?: number | null; width?: number | null } | null
					} | null
				} | null
			}
			locationRecord?: {
				__typename?: "UserLocationRecord"
				_id: string
				siteId: string
				zoneId: string
				userId: string
				startAt: any
				endAt: any
				status: UserLocationRecordStatus
				isRestricted?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
		}> | null
		findings?: Array<{ __typename?: "RouteFinding"; lastRoutePointIndex: number; remarks: string; createdAt: any; updatedAt: any; image: { __typename?: "PictureObject"; key: string; url: string } }> | null
	} | null
}

export const AlertResponseFragmentDoc = gql`
	fragment AlertResponse on AlertResponse {
		userId
		acknowlegedAt
		acknowlegment
		resolvedAt
		resolution
	}
`
export const AlertLevelFragmentDoc = gql`
	fragment AlertLevel on AlertLevel {
		alertedUserIds
		emailedUserIds
		responses {
			...AlertResponse
		}
	}
	${AlertResponseFragmentDoc}
`
export const AlertFragmentDoc = gql`
	fragment Alert on Alert {
		_id
		siteId
		type
		alertFlowId
		emailMessage
		notificationMessage
		levels {
			...AlertLevel
		}
		userId
		userRoleId
		zoneId
		readerId
		tagId
		userLocationRecordId
		userRouteRecordId
		toBeEscalated
		escalateAfter
		isResolved
		createdAt
		updatedAt
	}
	${AlertLevelFragmentDoc}
`
export const LabelFragmentDoc = gql`
	fragment Label on Label {
		name
		description
	}
`
export const SiteFragmentDoc = gql`
	fragment Site on Site {
		_id
		label {
			...Label
		}
		manpowerCount
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const AlertTargetLevelFragmentDoc = gql`
	fragment AlertTargetLevel on AlertTargetLevel {
		targetGroupIds
		timeout
	}
`
export const AlertFlowFragmentDoc = gql`
	fragment AlertFlow on AlertFlow {
		_id
		siteId
		label {
			...Label
		}
		source {
			type
			zoneIds
			zoneGroupIds
			userIds
			userGroupIds
		}
		type
		levels {
			...AlertTargetLevel
		}
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
	${AlertTargetLevelFragmentDoc}
`
export const EmailFragmentDoc = gql`
	fragment Email on Email {
		address
		isVerified
		verifiedAt
	}
`
export const PictureObjectFragmentDoc = gql`
	fragment PictureObject on PictureObject {
		key
		url
		height
		width
	}
`
export const PictureFragmentDoc = gql`
	fragment Picture on Picture {
		original {
			...PictureObject
		}
		variants {
			small {
				...PictureObject
			}
			medium {
				...PictureObject
			}
			large {
				...PictureObject
			}
			thumbnail {
				...PictureObject
			}
		}
	}
	${PictureObjectFragmentDoc}
`
export const UserFragmentDoc = gql`
	fragment User on User {
		_id
		siteId
		name
		phone {
			countryCode
			number
		}
		email {
			...Email
		}
		username
		address
		picture {
			...Picture
		}
		isBlacklisted
		blacklistRemarks
		typeId
		referenceNo
		tagId
		status
		createdAt
		updatedAt
	}
	${EmailFragmentDoc}
	${PictureFragmentDoc}
`
export const ExtendedAlertResponseFragmentDoc = gql`
	fragment ExtendedAlertResponse on ExtendedAlertResponse {
		userId
		user {
			...User
		}
		acknowlegedAt
		acknowlegment
		resolvedAt
		resolution
	}
	${UserFragmentDoc}
`
export const ExtendedAlertLevelFragmentDoc = gql`
	fragment ExtendedAlertLevel on ExtendedAlertLevel {
		alertedUserIds
		alertedUsers {
			...User
		}
		emailedUserIds
		emailedUsers {
			...User
		}
		responses {
			...ExtendedAlertResponse
		}
	}
	${UserFragmentDoc}
	${ExtendedAlertResponseFragmentDoc}
`
export const ZoneFragmentDoc = gql`
	fragment Zone on Zone {
		_id
		siteId
		label {
			...Label
		}
		readerIds
		picture {
			...Picture
		}
		type
		isActive
		isCrowded
		crowdLimit
		idleSince
	}
	${LabelFragmentDoc}
	${PictureFragmentDoc}
`
export const ZoneGroupFragmentDoc = gql`
	fragment ZoneGroup on ZoneGroup {
		_id
		siteId
		label {
			...Label
		}
		zoneIds
	}
	${LabelFragmentDoc}
`
export const ExtendedPatrollingRouteFragmentDoc = gql`
	fragment ExtendedPatrollingRoute on ExtendedPatrollingRoute {
		_id
		siteId
		site {
			...Site
		}
		label {
			...Label
		}
		zoneIds
		zones {
			...Zone
		}
		minTime
		maxTime
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
	${ZoneFragmentDoc}
`
export const DepartmentFragmentDoc = gql`
	fragment Department on Department {
		_id
		siteId
		label {
			...Label
		}
		zoneIds
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const WorkShiftFragmentDoc = gql`
	fragment WorkShift on WorkShift {
		_id
		siteId
		label {
			...Label
		}
		startAt
		endAt
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const ExtendedUserRoleGroupFragmentDoc = gql`
	fragment ExtendedUserRoleGroup on ExtendedUserRoleGroup {
		_id
		siteId
		site {
			...Site
		}
		type
		label {
			...Label
		}
		allowedZoneIds
		allowedZones {
			...Zone
		}
		allowedZoneGroupIds
		allowedZoneGroups {
			...ZoneGroup
		}
		patrollingRouteId
		patrollingRoute {
			...ExtendedPatrollingRoute
		}
		rounds
		departmentId
		department {
			...Department
		}
		workShiftId
		workShift {
			...WorkShift
		}
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
	${ZoneFragmentDoc}
	${ZoneGroupFragmentDoc}
	${ExtendedPatrollingRouteFragmentDoc}
	${DepartmentFragmentDoc}
	${WorkShiftFragmentDoc}
`
export const ExtendedUserRoleFragmentDoc = gql`
	fragment ExtendedUserRole on ExtendedUserRole {
		_id
		siteId
		site {
			...Site
		}
		userId
		user {
			...User
		}
		type
		userRoleGroupId
		userRoleGroup {
			...ExtendedUserRoleGroup
		}
		designation
		startAt
		endAt
		assignedAt
		removedAt
		status
	}
	${SiteFragmentDoc}
	${UserFragmentDoc}
	${ExtendedUserRoleGroupFragmentDoc}
`
export const ReaderFragmentDoc = gql`
	fragment Reader on Reader {
		_id
		siteId
		label {
			...Label
		}
		type
		readerHexId
		readerDecimalId
		assignedToZoneId
		assignedAt
		isActive
		lastConnectedEndpoint
		lastConnectedAt
		lastHeartbeatAt
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const ExtendedTagFragmentDoc = gql`
	fragment ExtendedTag on ExtendedTag {
		_id
		name
		tagHexId
		tagDecimalId
		status
		batteryStatus
		assignedToUserId
		user {
			...User
		}
		assignedAt
		createdAt
		updatedAt
	}
	${UserFragmentDoc}
`
export const UserTypeFragmentDoc = gql`
	fragment UserType on UserType {
		_id
		siteId
		label {
			...Label
		}
		roleType
	}
	${LabelFragmentDoc}
`
export const TagFragmentDoc = gql`
	fragment Tag on Tag {
		_id
		name
		tagHexId
		tagDecimalId
		status
		batteryStatus
		assignedToUserId
		assignedAt
		createdAt
		updatedAt
	}
`
export const ExtendedUserFragmentDoc = gql`
	fragment ExtendedUser on ExtendedUser {
		_id
		siteId
		site {
			...Site
		}
		scopedSiteIds
		scopedSites {
			...Site
		}
		name
		phone {
			countryCode
			number
		}
		email {
			...Email
		}
		username
		address
		picture {
			...Picture
		}
		typeId
		type {
			...UserType
		}
		referenceNo
		tagId
		tag {
			...Tag
		}
		isBlacklisted
		blacklistRemarks
		status
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${EmailFragmentDoc}
	${PictureFragmentDoc}
	${UserTypeFragmentDoc}
	${TagFragmentDoc}
`
export const ExtendedUserLocationRecordFragmentDoc = gql`
	fragment ExtendedUserLocationRecord on ExtendedUserLocationRecord {
		_id
		siteId
		zoneId
		zone {
			...Zone
		}
		userId
		user {
			...ExtendedUser
		}
		startAt
		endAt
		status
		isRestricted
		createdAt
		updatedAt
	}
	${ZoneFragmentDoc}
	${ExtendedUserFragmentDoc}
`
export const RouteUserLocationRecordFragmentDoc = gql`
	fragment RouteUserLocationRecord on RouteUserLocationRecord {
		_id
		siteId
		zoneId
		zone {
			...Zone
		}
		userId
		startAt
		endAt
		status
		isRestricted
		createdAt
		updatedAt
	}
	${ZoneFragmentDoc}
`
export const UserLocationRecordFragmentDoc = gql`
	fragment UserLocationRecord on UserLocationRecord {
		_id
		siteId
		zoneId
		userId
		startAt
		endAt
		status
		isRestricted
		createdAt
		updatedAt
	}
`
export const ExtendedRoutePointFragmentDoc = gql`
	fragment ExtendedRoutePoint on ExtendedRoutePoint {
		guardOnDuty
		areaIntact
		guardImage {
			key
			url
		}
		areaImage {
			key
			url
		}
		isSkipped
		skipRemarks
		zoneId
		zone {
			...Zone
		}
		locationRecordId
		locationRecord {
			...UserLocationRecord
		}
		createdAt
		updatedAt
	}
	${ZoneFragmentDoc}
	${UserLocationRecordFragmentDoc}
`
export const RouteFindingFragmentDoc = gql`
	fragment RouteFinding on RouteFinding {
		lastRoutePointIndex
		image {
			key
			url
		}
		remarks
		createdAt
		updatedAt
	}
`
export const ExtendedUserRouteRecordFragmentDoc = gql`
	fragment ExtendedUserRouteRecord on ExtendedUserRouteRecord {
		_id
		siteId
		userId
		startAt
		endAt
		patrollingRouteId
		expectedRouteZoneIds
		expectedRouteZones {
			...Zone
		}
		expectedMinTimeInMinutes
		expectedMaxTimeInMinutes
		locationRecordIds
		locationRecords {
			...RouteUserLocationRecord
		}
		routePoints {
			...ExtendedRoutePoint
		}
		findings {
			...RouteFinding
		}
		isRouteCorrect
		isTimeCorrect
		isTimeHigh
		isTimeLow
		isFinal
		isPartiallyFinal
		timeTakenInMinutes
		createdAt
		updatedAt
	}
	${ZoneFragmentDoc}
	${RouteUserLocationRecordFragmentDoc}
	${ExtendedRoutePointFragmentDoc}
	${RouteFindingFragmentDoc}
`
export const ExtendedAlertFragmentDoc = gql`
	fragment ExtendedAlert on ExtendedAlert {
		_id
		siteId
		site {
			...Site
		}
		type
		alertFlowId
		alertFlow {
			...AlertFlow
		}
		emailMessage
		notificationMessage
		levels {
			...ExtendedAlertLevel
		}
		userId
		user {
			...User
		}
		userRoleId
		userRole {
			...ExtendedUserRole
		}
		zoneId
		zone {
			...Zone
		}
		readerId
		reader {
			...Reader
		}
		tagId
		tag {
			...ExtendedTag
		}
		userLocationRecordId
		userLocationRecord {
			...ExtendedUserLocationRecord
		}
		userRouteRecordId
		userRouteRecord {
			...ExtendedUserRouteRecord
		}
		toBeEscalated
		escalateAfter
		isResolved
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${AlertFlowFragmentDoc}
	${ExtendedAlertLevelFragmentDoc}
	${UserFragmentDoc}
	${ExtendedUserRoleFragmentDoc}
	${ZoneFragmentDoc}
	${ReaderFragmentDoc}
	${ExtendedTagFragmentDoc}
	${ExtendedUserLocationRecordFragmentDoc}
	${ExtendedUserRouteRecordFragmentDoc}
`
export const UserGroupFragmentDoc = gql`
	fragment UserGroup on UserGroup {
		_id
		siteId
		label {
			...Label
		}
		userIds
	}
	${LabelFragmentDoc}
`
export const AlertTargetGroupFragmentDoc = gql`
	fragment AlertTargetGroup on AlertTargetGroup {
		_id
		siteId
		label {
			...Label
		}
		userIds
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const ExtendedAlertTargetLevelFragmentDoc = gql`
	fragment ExtendedAlertTargetLevel on ExtendedAlertTargetLevel {
		targetGroupIds
		targetGroups {
			...AlertTargetGroup
		}
		timeout
	}
	${AlertTargetGroupFragmentDoc}
`
export const ExtendedAlertFlowFragmentDoc = gql`
	fragment ExtendedAlertFlow on ExtendedAlertFlow {
		_id
		siteId
		site {
			...Site
		}
		label {
			...Label
		}
		source {
			type
			zoneIds
			zones {
				...Zone
			}
			zoneGroupIds
			zoneGroups {
				...ZoneGroup
			}
			userIds
			users {
				...User
			}
			userGroupIds
			userGroups {
				...UserGroup
			}
		}
		type
		levels {
			...ExtendedAlertTargetLevel
		}
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
	${ZoneFragmentDoc}
	${ZoneGroupFragmentDoc}
	${UserFragmentDoc}
	${UserGroupFragmentDoc}
	${ExtendedAlertTargetLevelFragmentDoc}
`
export const ExtendedAlertTargetGroupFragmentDoc = gql`
	fragment ExtendedAlertTargetGroup on ExtendedAlertTargetGroup {
		_id
		siteId
		site {
			...Site
		}
		label {
			...Label
		}
		userIds
		users {
			...User
		}
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
	${UserFragmentDoc}
`
export const ExtendedDepartmentFragmentDoc = gql`
	fragment ExtendedDepartment on ExtendedDepartment {
		_id
		siteId
		site {
			...Site
		}
		label {
			...Label
		}
		zoneIds
		zones {
			...Zone
		}
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
	${ZoneFragmentDoc}
`
export const ExtendedReportFragmentDoc = gql`
	fragment ExtendedReport on ExtendedReport {
		_id
		siteId
		site {
			...Site
		}
		label {
			...Label
		}
		type
		userIds
		users {
			...User
		}
		sinceDate
		untilDate
		status
		completeJson {
			key
			url
		}
		json {
			key
			url
		}
		csv {
			key
			url
		}
		lastUpdatedByUser {
			...User
		}
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
	${UserFragmentDoc}
`
export const ExtendedTileEmbeddedZoneFragmentDoc = gql`
	fragment ExtendedTileEmbeddedZone on ExtendedTileEmbeddedZone {
		_id
		siteId
		tileId
		zoneId
		position {
			topPercent
			leftPercent
		}
		zone {
			...Zone
		}
	}
	${ZoneFragmentDoc}
`
export const ExtendedUserGroupFragmentDoc = gql`
	fragment ExtendedUserGroup on ExtendedUserGroup {
		_id
		siteId
		site {
			...Site
		}
		label {
			...Label
		}
		userIds
		users {
			...User
		}
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
	${UserFragmentDoc}
`
export const ExtendedWorkShiftFragmentDoc = gql`
	fragment ExtendedWorkShift on ExtendedWorkShift {
		_id
		siteId
		site {
			...Site
		}
		label {
			...Label
		}
		startAt
		endAt
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
`
export const ExtendedZoneFragmentDoc = gql`
	fragment ExtendedZone on ExtendedZone {
		_id
		siteId
		site {
			...Site
		}
		label {
			...Label
		}
		readerIds
		picture {
			...Picture
		}
		type
		isActive
		isCrowded
		crowdLimit
		idleSince
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
	${PictureFragmentDoc}
`
export const ExtendedZoneGroupFragmentDoc = gql`
	fragment ExtendedZoneGroup on ExtendedZoneGroup {
		_id
		siteId
		site {
			...Site
		}
		label {
			...Label
		}
		zoneIds
		zones {
			...Zone
		}
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${LabelFragmentDoc}
	${ZoneFragmentDoc}
`
export const MobileAppFragmentDoc = gql`
	fragment MobileApp on MobileApp {
		_id
		type
		version
		changes
		url
		createdAt
		updatedAt
	}
`
export const PatrollingUserDashboardStatsResponseFragmentDoc = gql`
	fragment PatrollingUserDashboardStatsResponse on PatrollingUserDashboardStatsResponse {
		user {
			...User
		}
		completedRoundsWithTimeTaken
		expectedRounds
		assignedRoute
	}
	${UserFragmentDoc}
`
export const ReaderStatusLogFragmentDoc = gql`
	fragment ReaderStatusLog on ReaderStatusLog {
		_id
		siteId
		readerId
		isActive
		connectedAt
		disconnectedAt
		createdAt
		updatedAt
	}
`
export const ReportFragmentDoc = gql`
	fragment Report on Report {
		_id
		siteId
		label {
			...Label
		}
		type
		userIds
		sinceDate
		untilDate
		status
		completeJson {
			key
			url
		}
		json {
			key
			url
		}
		csv {
			key
			url
		}
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const ServiceFragmentDoc = gql`
	fragment Service on Service {
		_id
		key
		label {
			...Label
		}
		lastHeartbeatAt
		isActive
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const TileFragmentDoc = gql`
	fragment Tile on Tile {
		_id
		label {
			...Label
		}
		picture {
			...Picture
		}
		embeddedZoneIds
		embedsAllZones
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
	${PictureFragmentDoc}
`
export const ExtendedUserAttendanceFragmentDoc = gql`
	fragment ExtendedUserAttendance on ExtendedUserAttendance {
		_id
		siteId
		site {
			...Site
		}
		userId
		user {
			...User
		}
		inAt
		outAt
		inImage
		inRemarks
		outRemarks
		outImage
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${UserFragmentDoc}
`
export const UserAttendanceStatsResponseFragmentDoc = gql`
	fragment UserAttendanceStatsResponse on UserAttendanceStatsResponse {
		present {
			...ExtendedUserAttendance
		}
		absent {
			...User
		}
	}
	${ExtendedUserAttendanceFragmentDoc}
	${UserFragmentDoc}
`
export const FieldErrorFragmentDoc = gql`
	fragment FieldError on FieldError {
		field
		error
	}
`
export const PermissionsGroupFragmentDoc = gql`
	fragment PermissionsGroup on PermissionsGroup {
		_id
		tags
		label {
			...Label
		}
		isPrivateToAdmin
	}
	${LabelFragmentDoc}
`
export const MeUserFragmentDoc = gql`
	fragment MeUser on MeUser {
		_id
		siteId
		site {
			...Site
		}
		scopedSiteIds
		scopedSites {
			...Site
		}
		name
		phone {
			countryCode
			number
		}
		email {
			...Email
		}
		username
		address
		picture {
			...Picture
		}
		isBlacklisted
		blacklistRemarks
		typeId
		type {
			...UserType
		}
		referenceNo
		tagId
		tag {
			...Tag
		}
		permissionsGroupIds
		permissionsGroups {
			...PermissionsGroup
		}
		status
		createdAt
		updatedAt
	}
	${SiteFragmentDoc}
	${EmailFragmentDoc}
	${PictureFragmentDoc}
	${UserTypeFragmentDoc}
	${TagFragmentDoc}
	${PermissionsGroupFragmentDoc}
`
export const UserAuthResponseFragmentDoc = gql`
	fragment UserAuthResponse on UserAuthResponse {
		errors {
			...FieldError
		}
		user {
			...MeUser
		}
		sessionToken
	}
	${FieldErrorFragmentDoc}
	${MeUserFragmentDoc}
`
export const UserRoleGroupFragmentDoc = gql`
	fragment UserRoleGroup on UserRoleGroup {
		_id
		siteId
		type
		label {
			...Label
		}
		allowedZoneIds
		allowedZoneGroupIds
		departmentId
		workShiftId
		patrollingRouteId
		rounds
		createdAt
		updatedAt
	}
	${LabelFragmentDoc}
`
export const ZoneStatsFragmentDoc = gql`
	fragment ZoneStats on ZoneStats {
		_id
		siteType
		zoneId
		timeStamp
		usersCount
		createdAt
		updatedAt
	}
`
export const AcknowledgeAlertDocument = gql`
	mutation AcknowledgeAlert($alertId: String!, $message: String!, $level: Float!) {
		acknowledgeAlert(alertId: $alertId, message: $message, level: $level) {
			...ExtendedAlert
		}
	}
	${ExtendedAlertFragmentDoc}
`

export function useAcknowledgeAlertMutation() {
	return Urql.useMutation<AcknowledgeAlertMutation, AcknowledgeAlertMutationVariables>(AcknowledgeAlertDocument)
}
export const ActivateTagOfUserDocument = gql`
	mutation ActivateTagOfUser($userId: String!) {
		activateTagOfUser(userId: $userId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useActivateTagOfUserMutation() {
	return Urql.useMutation<ActivateTagOfUserMutation, ActivateTagOfUserMutationVariables>(ActivateTagOfUserDocument)
}
export const ActivateUserDocument = gql`
	mutation ActivateUser($userId: String!) {
		activateUser(userId: $userId) {
			...ExtendedUser
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useActivateUserMutation() {
	return Urql.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument)
}
export const AddReaderToZoneDocument = gql`
	mutation AddReaderToZone($zoneId: String!, $readerDecimalId: String!) {
		addReaderToZone(zoneId: $zoneId, readerDecimalId: $readerDecimalId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useAddReaderToZoneMutation() {
	return Urql.useMutation<AddReaderToZoneMutation, AddReaderToZoneMutationVariables>(AddReaderToZoneDocument)
}
export const AssignTagDocument = gql`
	mutation AssignTag($userId: String!, $tagDecimalId: String!) {
		assignTag(userId: $userId, tagDecimalId: $tagDecimalId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useAssignTagMutation() {
	return Urql.useMutation<AssignTagMutation, AssignTagMutationVariables>(AssignTagDocument)
}
export const AssignUserRoleDocument = gql`
	mutation AssignUserRole($userId: String!, $input: UserRoleInput!) {
		assignUserRole(userId: $userId, input: $input) {
			...ExtendedUserRole
		}
	}
	${ExtendedUserRoleFragmentDoc}
`

export function useAssignUserRoleMutation() {
	return Urql.useMutation<AssignUserRoleMutation, AssignUserRoleMutationVariables>(AssignUserRoleDocument)
}
export const BlacklistUserDocument = gql`
	mutation BlacklistUser($userId: String!, $remarks: String!) {
		blacklistUser(userId: $userId, remarks: $remarks) {
			...ExtendedUser
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useBlacklistUserMutation() {
	return Urql.useMutation<BlacklistUserMutation, BlacklistUserMutationVariables>(BlacklistUserDocument)
}
export const ChangePasswordDocument = gql`
	mutation ChangePassword($currentPassword: String!, $newPassword: String!, $shouldLogOutOnOtherDevices: Boolean) {
		changePassword(currentPassword: $currentPassword, newPassword: $newPassword, shouldLogOutOnOtherDevices: $shouldLogOutOnOtherDevices)
	}
`

export function useChangePasswordMutation() {
	return Urql.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument)
}
export const CreateAlertFlowDocument = gql`
	mutation CreateAlertFlow($input: AlertFlowInput!) {
		createAlertFlow(input: $input) {
			...ExtendedAlertFlow
		}
	}
	${ExtendedAlertFlowFragmentDoc}
`

export function useCreateAlertFlowMutation() {
	return Urql.useMutation<CreateAlertFlowMutation, CreateAlertFlowMutationVariables>(CreateAlertFlowDocument)
}
export const CreateAlertTargetGroupDocument = gql`
	mutation CreateAlertTargetGroup($input: AlertTargetGroupInput!) {
		createAlertTargetGroup(input: $input) {
			...ExtendedAlertTargetGroup
		}
	}
	${ExtendedAlertTargetGroupFragmentDoc}
`

export function useCreateAlertTargetGroupMutation() {
	return Urql.useMutation<CreateAlertTargetGroupMutation, CreateAlertTargetGroupMutationVariables>(CreateAlertTargetGroupDocument)
}
export const CreateDepartmentDocument = gql`
	mutation CreateDepartment($input: DepartmentInput!) {
		createDepartment(input: $input) {
			...ExtendedDepartment
		}
	}
	${ExtendedDepartmentFragmentDoc}
`

export function useCreateDepartmentMutation() {
	return Urql.useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(CreateDepartmentDocument)
}
export const CreatePatrollingRouteDocument = gql`
	mutation CreatePatrollingRoute($input: PatrollingRouteInput!) {
		createPatrollingRoute(input: $input) {
			...ExtendedPatrollingRoute
		}
	}
	${ExtendedPatrollingRouteFragmentDoc}
`

export function useCreatePatrollingRouteMutation() {
	return Urql.useMutation<CreatePatrollingRouteMutation, CreatePatrollingRouteMutationVariables>(CreatePatrollingRouteDocument)
}
export const CreatePermissionsGroupDocument = gql`
	mutation CreatePermissionsGroup($input: PermissionsGroupInput!) {
		createPermissionsGroup(input: $input) {
			...PermissionsGroup
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function useCreatePermissionsGroupMutation() {
	return Urql.useMutation<CreatePermissionsGroupMutation, CreatePermissionsGroupMutationVariables>(CreatePermissionsGroupDocument)
}
export const CreateReaderDocument = gql`
	mutation CreateReader($input: ReaderInput!) {
		createReader(input: $input) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useCreateReaderMutation() {
	return Urql.useMutation<CreateReaderMutation, CreateReaderMutationVariables>(CreateReaderDocument)
}
export const CreateServiceDocument = gql`
	mutation CreateService($input: ServiceInput!) {
		createService(input: $input) {
			...Service
		}
	}
	${ServiceFragmentDoc}
`

export function useCreateServiceMutation() {
	return Urql.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument)
}
export const CreateTagDocument = gql`
	mutation CreateTag($tagDecimalId: String!, $name: String) {
		createTag(tagDecimalId: $tagDecimalId, name: $name) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useCreateTagMutation() {
	return Urql.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument)
}
export const CreateUserDocument = gql`
	mutation CreateUser($input: UserInput!) {
		createUser(input: $input) {
			...ExtendedUser
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useCreateUserMutation() {
	return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument)
}
export const CreateUserGroupDocument = gql`
	mutation CreateUserGroup($input: UserGroupInput!) {
		createUserGroup(input: $input) {
			...ExtendedUserGroup
		}
	}
	${ExtendedUserGroupFragmentDoc}
`

export function useCreateUserGroupMutation() {
	return Urql.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(CreateUserGroupDocument)
}
export const CreateUserRoleGroupDocument = gql`
	mutation CreateUserRoleGroup($input: UserRoleGroupInput!) {
		createUserRoleGroup(input: $input) {
			...ExtendedUserRoleGroup
		}
	}
	${ExtendedUserRoleGroupFragmentDoc}
`

export function useCreateUserRoleGroupMutation() {
	return Urql.useMutation<CreateUserRoleGroupMutation, CreateUserRoleGroupMutationVariables>(CreateUserRoleGroupDocument)
}
export const CreateUserTypeDocument = gql`
	mutation CreateUserType($input: UserTypeInput!) {
		createUserType(input: $input) {
			...UserType
		}
	}
	${UserTypeFragmentDoc}
`

export function useCreateUserTypeMutation() {
	return Urql.useMutation<CreateUserTypeMutation, CreateUserTypeMutationVariables>(CreateUserTypeDocument)
}
export const CreateWorkShiftDocument = gql`
	mutation CreateWorkShift($input: WorkShiftInput!) {
		createWorkShift(input: $input) {
			...ExtendedWorkShift
		}
	}
	${ExtendedWorkShiftFragmentDoc}
`

export function useCreateWorkShiftMutation() {
	return Urql.useMutation<CreateWorkShiftMutation, CreateWorkShiftMutationVariables>(CreateWorkShiftDocument)
}
export const CreateZoneGroupDocument = gql`
	mutation CreateZoneGroup($input: ZoneGroupInput!) {
		createZoneGroup(input: $input) {
			...ExtendedZoneGroup
		}
	}
	${ExtendedZoneGroupFragmentDoc}
`

export function useCreateZoneGroupMutation() {
	return Urql.useMutation<CreateZoneGroupMutation, CreateZoneGroupMutationVariables>(CreateZoneGroupDocument)
}
export const DeactivateTagOfUserDocument = gql`
	mutation DeactivateTagOfUser($userId: String!) {
		deactivateTagOfUser(userId: $userId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useDeactivateTagOfUserMutation() {
	return Urql.useMutation<DeactivateTagOfUserMutation, DeactivateTagOfUserMutationVariables>(DeactivateTagOfUserDocument)
}
export const DeactivateUserDocument = gql`
	mutation DeactivateUser($userId: String!) {
		deactivateUser(userId: $userId) {
			...ExtendedUser
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useDeactivateUserMutation() {
	return Urql.useMutation<DeactivateUserMutation, DeactivateUserMutationVariables>(DeactivateUserDocument)
}
export const DeleteServiceDocument = gql`
	mutation DeleteService($serviceId: String!) {
		deleteService(serviceId: $serviceId)
	}
`

export function useDeleteServiceMutation() {
	return Urql.useMutation<DeleteServiceMutation, DeleteServiceMutationVariables>(DeleteServiceDocument)
}
export const FinishAvatarUploadDocument = gql`
	mutation FinishAvatarUpload($userId: String!, $input: FinishUploadInput!) {
		finishAvatarUpload(userId: $userId, input: $input) {
			...Picture
		}
	}
	${PictureFragmentDoc}
`

export function useFinishAvatarUploadMutation() {
	return Urql.useMutation<FinishAvatarUploadMutation, FinishAvatarUploadMutationVariables>(FinishAvatarUploadDocument)
}
export const FinishMobileAppUploadDocument = gql`
	mutation FinishMobileAppUpload($input: FinishUploadInput!) {
		finishMobileAppUpload(input: $input) {
			type
			url
		}
	}
`

export function useFinishMobileAppUploadMutation() {
	return Urql.useMutation<FinishMobileAppUploadMutation, FinishMobileAppUploadMutationVariables>(FinishMobileAppUploadDocument)
}
export const FlushTagDocument = gql`
	mutation FlushTag($userId: String!) {
		flushTag(userId: $userId) {
			...Tag
		}
	}
	${TagFragmentDoc}
`

export function useFlushTagMutation() {
	return Urql.useMutation<FlushTagMutation, FlushTagMutationVariables>(FlushTagDocument)
}
export const GenerateComprehensiveUserAttendanceReportDocument = gql`
	mutation GenerateComprehensiveUserAttendanceReport($input: ComprehensiveUserAttendanceReportInput!) {
		generateComprehensiveUserAttendanceReport(input: $input) {
			...ExtendedReport
		}
	}
	${ExtendedReportFragmentDoc}
`

export function useGenerateComprehensiveUserAttendanceReportMutation() {
	return Urql.useMutation<GenerateComprehensiveUserAttendanceReportMutation, GenerateComprehensiveUserAttendanceReportMutationVariables>(GenerateComprehensiveUserAttendanceReportDocument)
}
export const GenerateReadersStatusLogsReportDocument = gql`
	mutation GenerateReadersStatusLogsReport($input: ReadersStatusLogsReportInput!) {
		generateReadersStatusLogsReport(input: $input) {
			...ExtendedReport
		}
	}
	${ExtendedReportFragmentDoc}
`

export function useGenerateReadersStatusLogsReportMutation() {
	return Urql.useMutation<GenerateReadersStatusLogsReportMutation, GenerateReadersStatusLogsReportMutationVariables>(GenerateReadersStatusLogsReportDocument)
}
export const GenerateSummarizedUserAttendanceReportDocument = gql`
	mutation GenerateSummarizedUserAttendanceReport($input: SummarizedUserAttendanceReportInput!) {
		generateSummarizedUserAttendanceReport(input: $input) {
			...ExtendedReport
		}
	}
	${ExtendedReportFragmentDoc}
`

export function useGenerateSummarizedUserAttendanceReportMutation() {
	return Urql.useMutation<GenerateSummarizedUserAttendanceReportMutation, GenerateSummarizedUserAttendanceReportMutationVariables>(GenerateSummarizedUserAttendanceReportDocument)
}
export const GenerateUserLocationRecordsReportDocument = gql`
	mutation GenerateUserLocationRecordsReport($input: UserLocationRecordsReportInput!) {
		generateUserLocationRecordsReport(input: $input) {
			...ExtendedReport
		}
	}
	${ExtendedReportFragmentDoc}
`

export function useGenerateUserLocationRecordsReportMutation() {
	return Urql.useMutation<GenerateUserLocationRecordsReportMutation, GenerateUserLocationRecordsReportMutationVariables>(GenerateUserLocationRecordsReportDocument)
}
export const GenerateUserPatrollingRouteReportDocument = gql`
	mutation GenerateUserPatrollingRouteReport($input: UserPatrollingRouteReportInput!) {
		generateUserPatrollingRouteReport(input: $input) {
			...ExtendedReport
		}
	}
	${ExtendedReportFragmentDoc}
`

export function useGenerateUserPatrollingRouteReportMutation() {
	return Urql.useMutation<GenerateUserPatrollingRouteReportMutation, GenerateUserPatrollingRouteReportMutationVariables>(GenerateUserPatrollingRouteReportDocument)
}
export const GenerateUserRestrictedEntriesReportDocument = gql`
	mutation GenerateUserRestrictedEntriesReport($input: UserRestrictedEntriesReportInput!) {
		generateUserRestrictedEntriesReport(input: $input) {
			...ExtendedReport
		}
	}
	${ExtendedReportFragmentDoc}
`

export function useGenerateUserRestrictedEntriesReportMutation() {
	return Urql.useMutation<GenerateUserRestrictedEntriesReportMutation, GenerateUserRestrictedEntriesReportMutationVariables>(GenerateUserRestrictedEntriesReportDocument)
}
export const GenerateZoneGroupRestrictedEntriesReportDocument = gql`
	mutation GenerateZoneGroupRestrictedEntriesReport($input: ZoneGroupRestrictedEntriesReportInput!) {
		generateZoneGroupRestrictedEntriesReport(input: $input) {
			...ExtendedReport
		}
	}
	${ExtendedReportFragmentDoc}
`

export function useGenerateZoneGroupRestrictedEntriesReportMutation() {
	return Urql.useMutation<GenerateZoneGroupRestrictedEntriesReportMutation, GenerateZoneGroupRestrictedEntriesReportMutationVariables>(GenerateZoneGroupRestrictedEntriesReportDocument)
}
export const GenerateZoneRestrictedEntriesReportDocument = gql`
	mutation GenerateZoneRestrictedEntriesReport($input: ZoneRestrictedEntriesReportInput!) {
		generateZoneRestrictedEntriesReport(input: $input) {
			...ExtendedReport
		}
	}
	${ExtendedReportFragmentDoc}
`

export function useGenerateZoneRestrictedEntriesReportMutation() {
	return Urql.useMutation<GenerateZoneRestrictedEntriesReportMutation, GenerateZoneRestrictedEntriesReportMutationVariables>(GenerateZoneRestrictedEntriesReportDocument)
}
export const LoginDocument = gql`
	mutation Login($username: String!, $password: String!, $siteType: SiteTypes!) {
		login(username: $username, password: $password, siteType: $siteType) {
			...UserAuthResponse
		}
	}
	${UserAuthResponseFragmentDoc}
`

export function useLoginMutation() {
	return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument)
}
export const LogoutDocument = gql`
	mutation Logout {
		logout
	}
`

export function useLogoutMutation() {
	return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument)
}
export const MarkUserAttendanceBulkInDocument = gql`
	mutation MarkUserAttendanceBulkIn($input: MarkUserAttendanceBulkInInput!) {
		markUserAttendanceBulkIn(input: $input) {
			...ExtendedUserAttendance
		}
	}
	${ExtendedUserAttendanceFragmentDoc}
`

export function useMarkUserAttendanceBulkInMutation() {
	return Urql.useMutation<MarkUserAttendanceBulkInMutation, MarkUserAttendanceBulkInMutationVariables>(MarkUserAttendanceBulkInDocument)
}
export const MarkUserAttendanceBulkOutDocument = gql`
	mutation MarkUserAttendanceBulkOut($input: MarkUserAttendanceBulkOutInput!) {
		markUserAttendanceBulkOut(input: $input) {
			...ExtendedUserAttendance
		}
	}
	${ExtendedUserAttendanceFragmentDoc}
`

export function useMarkUserAttendanceBulkOutMutation() {
	return Urql.useMutation<MarkUserAttendanceBulkOutMutation, MarkUserAttendanceBulkOutMutationVariables>(MarkUserAttendanceBulkOutDocument)
}
export const MarkUserAttendanceInDocument = gql`
	mutation MarkUserAttendanceIn($userId: String!, $inRemarks: String!, $inTime: DateTime!) {
		markUserAttendanceIn(userId: $userId, inRemarks: $inRemarks, inTime: $inTime) {
			...ExtendedUserAttendance
		}
	}
	${ExtendedUserAttendanceFragmentDoc}
`

export function useMarkUserAttendanceInMutation() {
	return Urql.useMutation<MarkUserAttendanceInMutation, MarkUserAttendanceInMutationVariables>(MarkUserAttendanceInDocument)
}
export const MarkUserAttendanceOutDocument = gql`
	mutation MarkUserAttendanceOut($userId: String!, $outRemarks: String!, $outTime: DateTime!) {
		markUserAttendanceOut(userId: $userId, outRemarks: $outRemarks, outTime: $outTime) {
			...ExtendedUserAttendance
		}
	}
	${ExtendedUserAttendanceFragmentDoc}
`

export function useMarkUserAttendanceOutMutation() {
	return Urql.useMutation<MarkUserAttendanceOutMutation, MarkUserAttendanceOutMutationVariables>(MarkUserAttendanceOutDocument)
}
export const RemoveCurrentUserRoleDocument = gql`
	mutation RemoveCurrentUserRole($userId: String!) {
		removeCurrentUserRole(userId: $userId)
	}
`

export function useRemoveCurrentUserRoleMutation() {
	return Urql.useMutation<RemoveCurrentUserRoleMutation, RemoveCurrentUserRoleMutationVariables>(RemoveCurrentUserRoleDocument)
}
export const RemoveReaderFromZoneDocument = gql`
	mutation RemoveReaderFromZone($zoneId: String!, $readerId: String!) {
		removeReaderFromZone(zoneId: $zoneId, readerId: $readerId)
	}
`

export function useRemoveReaderFromZoneMutation() {
	return Urql.useMutation<RemoveReaderFromZoneMutation, RemoveReaderFromZoneMutationVariables>(RemoveReaderFromZoneDocument)
}
export const ResetUserPasswordDocument = gql`
	mutation ResetUserPassword($userId: String!) {
		resetUserPassword(userId: $userId) {
			...ExtendedUser
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useResetUserPasswordMutation() {
	return Urql.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument)
}
export const ResolveAlertDocument = gql`
	mutation ResolveAlert($alertId: String!, $message: String!, $level: Float!) {
		resolveAlert(alertId: $alertId, message: $message, level: $level) {
			...ExtendedAlert
		}
	}
	${ExtendedAlertFragmentDoc}
`

export function useResolveAlertMutation() {
	return Urql.useMutation<ResolveAlertMutation, ResolveAlertMutationVariables>(ResolveAlertDocument)
}
export const SendEmailVerificationSecretCodeDocument = gql`
	mutation SendEmailVerificationSecretCode {
		sendEmailVerificationSecretCode
	}
`

export function useSendEmailVerificationSecretCodeMutation() {
	return Urql.useMutation<SendEmailVerificationSecretCodeMutation, SendEmailVerificationSecretCodeMutationVariables>(SendEmailVerificationSecretCodeDocument)
}
export const SignAvatarUploadDocument = gql`
	mutation SignAvatarUpload($userId: String!, $format: String!) {
		signAvatarUpload(userId: $userId, format: $format) {
			signedUrl
			key
		}
	}
`

export function useSignAvatarUploadMutation() {
	return Urql.useMutation<SignAvatarUploadMutation, SignAvatarUploadMutationVariables>(SignAvatarUploadDocument)
}
export const SignMobileAppUploadDocument = gql`
	mutation SignMobileAppUpload($type: MobileAppTypes!, $version: String!, $format: String!) {
		signMobileAppUpload(type: $type, version: $version, format: $format) {
			signedUrl
			key
		}
	}
`

export function useSignMobileAppUploadMutation() {
	return Urql.useMutation<SignMobileAppUploadMutation, SignMobileAppUploadMutationVariables>(SignMobileAppUploadDocument)
}
export const SwitchSiteDocument = gql`
	mutation SwitchSite($userId: String!, $siteId: String!) {
		switchSite(userId: $userId, siteId: $siteId) {
			...ExtendedUser
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useSwitchSiteMutation() {
	return Urql.useMutation<SwitchSiteMutation, SwitchSiteMutationVariables>(SwitchSiteDocument)
}
export const UpdateAlertFlowDocument = gql`
	mutation UpdateAlertFlow($alertFlowId: String!, $input: AlertFlowInput!) {
		updateAlertFlow(alertFlowId: $alertFlowId, input: $input) {
			...ExtendedAlertFlow
		}
	}
	${ExtendedAlertFlowFragmentDoc}
`

export function useUpdateAlertFlowMutation() {
	return Urql.useMutation<UpdateAlertFlowMutation, UpdateAlertFlowMutationVariables>(UpdateAlertFlowDocument)
}
export const UpdateAlertTargetGroupDocument = gql`
	mutation UpdateAlertTargetGroup($alertTargetGroupId: String!, $input: AlertTargetGroupInput!) {
		updateAlertTargetGroup(alertTargetGroupId: $alertTargetGroupId, input: $input) {
			...ExtendedAlertTargetGroup
		}
	}
	${ExtendedAlertTargetGroupFragmentDoc}
`

export function useUpdateAlertTargetGroupMutation() {
	return Urql.useMutation<UpdateAlertTargetGroupMutation, UpdateAlertTargetGroupMutationVariables>(UpdateAlertTargetGroupDocument)
}
export const UpdateManpowerCountDocument = gql`
	mutation UpdateManpowerCount($manpowerCount: Float!) {
		updateManpowerCount(manpowerCount: $manpowerCount) {
			...Site
		}
	}
	${SiteFragmentDoc}
`

export function useUpdateManpowerCountMutation() {
	return Urql.useMutation<UpdateManpowerCountMutation, UpdateManpowerCountMutationVariables>(UpdateManpowerCountDocument)
}
export const UpdateServiceDocument = gql`
	mutation UpdateService($serviceId: String!, $input: ServiceInput!) {
		updateService(serviceId: $serviceId, input: $input) {
			...Service
		}
	}
	${ServiceFragmentDoc}
`

export function useUpdateServiceMutation() {
	return Urql.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument)
}
export const UpdateUserDocument = gql`
	mutation UpdateUser($userId: String!, $input: UserInput!) {
		updateUser(userId: $userId, input: $input) {
			...ExtendedUser
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useUpdateUserMutation() {
	return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument)
}
export const UpdateUserGroupDocument = gql`
	mutation UpdateUserGroup($userGroupId: String!, $input: UserGroupInput!) {
		updateUserGroup(userGroupId: $userGroupId, input: $input) {
			...ExtendedUserGroup
		}
	}
	${ExtendedUserGroupFragmentDoc}
`

export function useUpdateUserGroupMutation() {
	return Urql.useMutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(UpdateUserGroupDocument)
}
export const UpdateUserPermissionsGroupsDocument = gql`
	mutation UpdateUserPermissionsGroups($userId: String!, $permissionsGroupIds: [String!]!) {
		updateUserPermissionsGroups(userId: $userId, permissionsGroupIds: $permissionsGroupIds) {
			...PermissionsGroup
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function useUpdateUserPermissionsGroupsMutation() {
	return Urql.useMutation<UpdateUserPermissionsGroupsMutation, UpdateUserPermissionsGroupsMutationVariables>(UpdateUserPermissionsGroupsDocument)
}
export const UpdateUserScopedSitesDocument = gql`
	mutation UpdateUserScopedSites($userId: String!, $siteIds: [String!]!) {
		updateUserScopedSites(userId: $userId, siteIds: $siteIds) {
			...ExtendedUser
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useUpdateUserScopedSitesMutation() {
	return Urql.useMutation<UpdateUserScopedSitesMutation, UpdateUserScopedSitesMutationVariables>(UpdateUserScopedSitesDocument)
}
export const UpdateZoneDocument = gql`
	mutation UpdateZone($zoneId: String!, $input: ZoneInput!) {
		updateZone(zoneId: $zoneId, input: $input) {
			...ExtendedZone
		}
	}
	${ExtendedZoneFragmentDoc}
`

export function useUpdateZoneMutation() {
	return Urql.useMutation<UpdateZoneMutation, UpdateZoneMutationVariables>(UpdateZoneDocument)
}
export const UploadMobileAppDocument = gql`
	mutation UploadMobileApp($input: MobileAppInput!) {
		uploadMobileApp(input: $input) {
			...MobileApp
		}
	}
	${MobileAppFragmentDoc}
`

export function useUploadMobileAppMutation() {
	return Urql.useMutation<UploadMobileAppMutation, UploadMobileAppMutationVariables>(UploadMobileAppDocument)
}
export const VerifyEmailWithSecretCodeDocument = gql`
	mutation VerifyEmailWithSecretCode($code: String!) {
		verifyEmailWithSecretCode(code: $code)
	}
`

export function useVerifyEmailWithSecretCodeMutation() {
	return Urql.useMutation<VerifyEmailWithSecretCodeMutation, VerifyEmailWithSecretCodeMutationVariables>(VerifyEmailWithSecretCodeDocument)
}
export const WhitelistUserDocument = gql`
	mutation WhitelistUser($userId: String!) {
		whitelistUser(userId: $userId) {
			...ExtendedUser
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useWhitelistUserMutation() {
	return Urql.useMutation<WhitelistUserMutation, WhitelistUserMutationVariables>(WhitelistUserDocument)
}
export const ActiveRestrictedEntriesDocument = gql`
	query ActiveRestrictedEntries {
		activeRestrictedEntries {
			recordIds
			zoneWiseRecordIds {
				zoneId
				recordIds
			}
		}
	}
`

export function useActiveRestrictedEntriesQuery(options?: Omit<Urql.UseQueryArgs<ActiveRestrictedEntriesQueryVariables>, "query">) {
	return Urql.useQuery<ActiveRestrictedEntriesQuery, ActiveRestrictedEntriesQueryVariables>({ query: ActiveRestrictedEntriesDocument, ...options })
}
export const ActiveUsersDocument = gql`
	query ActiveUsers {
		activeUsers {
			userIds
			zoneWiseUserIds {
				zoneId
				userIds
			}
		}
	}
`

export function useActiveUsersQuery(options?: Omit<Urql.UseQueryArgs<ActiveUsersQueryVariables>, "query">) {
	return Urql.useQuery<ActiveUsersQuery, ActiveUsersQueryVariables>({ query: ActiveUsersDocument, ...options })
}
export const AlertByIdDocument = gql`
	query AlertById($alertId: String!) {
		alertById(alertId: $alertId) {
			...ExtendedAlert
		}
	}
	${ExtendedAlertFragmentDoc}
`

export function useAlertByIdQuery(options: Omit<Urql.UseQueryArgs<AlertByIdQueryVariables>, "query">) {
	return Urql.useQuery<AlertByIdQuery, AlertByIdQueryVariables>({ query: AlertByIdDocument, ...options })
}
export const AlertFlowByIdDocument = gql`
	query AlertFlowById($alertFlowId: String!) {
		alertFlowById(alertFlowId: $alertFlowId) {
			...ExtendedAlertFlow
		}
	}
	${ExtendedAlertFlowFragmentDoc}
`

export function useAlertFlowByIdQuery(options: Omit<Urql.UseQueryArgs<AlertFlowByIdQueryVariables>, "query">) {
	return Urql.useQuery<AlertFlowByIdQuery, AlertFlowByIdQueryVariables>({ query: AlertFlowByIdDocument, ...options })
}
export const AlertFlowsDocument = gql`
	query AlertFlows {
		alertFlows {
			...AlertFlow
		}
	}
	${AlertFlowFragmentDoc}
`

export function useAlertFlowsQuery(options?: Omit<Urql.UseQueryArgs<AlertFlowsQueryVariables>, "query">) {
	return Urql.useQuery<AlertFlowsQuery, AlertFlowsQueryVariables>({ query: AlertFlowsDocument, ...options })
}
export const AlertFlowsByKeywordDocument = gql`
	query AlertFlowsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		alertFlowsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alertFlows {
				...AlertFlow
			}
		}
	}
	${AlertFlowFragmentDoc}
`

export function useAlertFlowsByKeywordQuery(options: Omit<Urql.UseQueryArgs<AlertFlowsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<AlertFlowsByKeywordQuery, AlertFlowsByKeywordQueryVariables>({ query: AlertFlowsByKeywordDocument, ...options })
}
export const AlertTargetGroupByIdDocument = gql`
	query AlertTargetGroupById($alertTargetGroupId: String!) {
		alertTargetGroupById(alertTargetGroupId: $alertTargetGroupId) {
			...ExtendedAlertTargetGroup
		}
	}
	${ExtendedAlertTargetGroupFragmentDoc}
`

export function useAlertTargetGroupByIdQuery(options: Omit<Urql.UseQueryArgs<AlertTargetGroupByIdQueryVariables>, "query">) {
	return Urql.useQuery<AlertTargetGroupByIdQuery, AlertTargetGroupByIdQueryVariables>({ query: AlertTargetGroupByIdDocument, ...options })
}
export const AlertTargetGroupsDocument = gql`
	query AlertTargetGroups {
		alertTargetGroups {
			...AlertTargetGroup
		}
	}
	${AlertTargetGroupFragmentDoc}
`

export function useAlertTargetGroupsQuery(options?: Omit<Urql.UseQueryArgs<AlertTargetGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AlertTargetGroupsQuery, AlertTargetGroupsQueryVariables>({ query: AlertTargetGroupsDocument, ...options })
}
export const AlertTargetGroupsByKeywordDocument = gql`
	query AlertTargetGroupsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		alertTargetGroupsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alertTargetGroups {
				...ExtendedAlertTargetGroup
			}
		}
	}
	${ExtendedAlertTargetGroupFragmentDoc}
`

export function useAlertTargetGroupsByKeywordQuery(options: Omit<Urql.UseQueryArgs<AlertTargetGroupsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<AlertTargetGroupsByKeywordQuery, AlertTargetGroupsByKeywordQueryVariables>({ query: AlertTargetGroupsByKeywordDocument, ...options })
}
export const AlertsByFilterDocument = gql`
	query AlertsByFilter($filter: AlertsFilterInput!, $pagination: PaginatedInput!) {
		alertsByFilter(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alerts {
				...Alert
			}
		}
	}
	${AlertFragmentDoc}
`

export function useAlertsByFilterQuery(options: Omit<Urql.UseQueryArgs<AlertsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<AlertsByFilterQuery, AlertsByFilterQueryVariables>({ query: AlertsByFilterDocument, ...options })
}
export const AllAlertFlowsDocument = gql`
	query AllAlertFlows($pagination: PaginatedInput!) {
		allAlertFlows(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alertFlows {
				...AlertFlow
			}
		}
	}
	${AlertFlowFragmentDoc}
`

export function useAllAlertFlowsQuery(options: Omit<Urql.UseQueryArgs<AllAlertFlowsQueryVariables>, "query">) {
	return Urql.useQuery<AllAlertFlowsQuery, AllAlertFlowsQueryVariables>({ query: AllAlertFlowsDocument, ...options })
}
export const AllAlertTargetGroupsDocument = gql`
	query AllAlertTargetGroups($pagination: PaginatedInput!) {
		allAlertTargetGroups(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alertTargetGroups {
				...ExtendedAlertTargetGroup
			}
		}
	}
	${ExtendedAlertTargetGroupFragmentDoc}
`

export function useAllAlertTargetGroupsQuery(options: Omit<Urql.UseQueryArgs<AllAlertTargetGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AllAlertTargetGroupsQuery, AllAlertTargetGroupsQueryVariables>({ query: AllAlertTargetGroupsDocument, ...options })
}
export const AllAlertsDocument = gql`
	query AllAlerts($pagination: PaginatedInput!) {
		allAlerts(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			alerts {
				...Alert
			}
		}
	}
	${AlertFragmentDoc}
`

export function useAllAlertsQuery(options: Omit<Urql.UseQueryArgs<AllAlertsQueryVariables>, "query">) {
	return Urql.useQuery<AllAlertsQuery, AllAlertsQueryVariables>({ query: AllAlertsDocument, ...options })
}
export const AllWorkShiftsDocument = gql`
	query AllWorkShifts($pagination: PaginatedInput!) {
		allWorkShifts(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			workShifts {
				...WorkShift
			}
		}
	}
	${WorkShiftFragmentDoc}
`

export function useAllWorkShiftsQuery(options: Omit<Urql.UseQueryArgs<AllWorkShiftsQueryVariables>, "query">) {
	return Urql.useQuery<AllWorkShiftsQuery, AllWorkShiftsQueryVariables>({ query: AllWorkShiftsDocument, ...options })
}
export const AllMobileAppsDocument = gql`
	query AllMobileApps($pagination: PaginatedInput!) {
		allMobileApps(pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			mobileApps {
				...MobileApp
			}
		}
	}
	${MobileAppFragmentDoc}
`

export function useAllMobileAppsQuery(options: Omit<Urql.UseQueryArgs<AllMobileAppsQueryVariables>, "query">) {
	return Urql.useQuery<AllMobileAppsQuery, AllMobileAppsQueryVariables>({ query: AllMobileAppsDocument, ...options })
}
export const AllPatrollingRoutesDocument = gql`
	query AllPatrollingRoutes($pagination: PaginatedInput!) {
		allPatrollingRoutes(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			patrollingRoutes {
				...ExtendedPatrollingRoute
			}
		}
	}
	${ExtendedPatrollingRouteFragmentDoc}
`

export function useAllPatrollingRoutesQuery(options: Omit<Urql.UseQueryArgs<AllPatrollingRoutesQueryVariables>, "query">) {
	return Urql.useQuery<AllPatrollingRoutesQuery, AllPatrollingRoutesQueryVariables>({ query: AllPatrollingRoutesDocument, ...options })
}
export const AllPermissionsGroupsDocument = gql`
	query AllPermissionsGroups($pagination: PaginatedInput!) {
		allPermissionsGroups(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			permissionsGroups {
				...PermissionsGroup
			}
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function useAllPermissionsGroupsQuery(options: Omit<Urql.UseQueryArgs<AllPermissionsGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AllPermissionsGroupsQuery, AllPermissionsGroupsQueryVariables>({ query: AllPermissionsGroupsDocument, ...options })
}
export const AllReadersDocument = gql`
	query AllReaders($pagination: PaginatedInput!) {
		allReaders(pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			readers {
				...Reader
			}
		}
	}
	${ReaderFragmentDoc}
`

export function useAllReadersQuery(options: Omit<Urql.UseQueryArgs<AllReadersQueryVariables>, "query">) {
	return Urql.useQuery<AllReadersQuery, AllReadersQueryVariables>({ query: AllReadersDocument, ...options })
}
export const AllReportsDocument = gql`
	query AllReports($pagination: PaginatedInput!) {
		allReports(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			reports {
				...Report
			}
		}
	}
	${ReportFragmentDoc}
`

export function useAllReportsQuery(options: Omit<Urql.UseQueryArgs<AllReportsQueryVariables>, "query">) {
	return Urql.useQuery<AllReportsQuery, AllReportsQueryVariables>({ query: AllReportsDocument, ...options })
}
export const AllServicesDocument = gql`
	query AllServices {
		allServices {
			...Service
		}
	}
	${ServiceFragmentDoc}
`

export function useAllServicesQuery(options?: Omit<Urql.UseQueryArgs<AllServicesQueryVariables>, "query">) {
	return Urql.useQuery<AllServicesQuery, AllServicesQueryVariables>({ query: AllServicesDocument, ...options })
}
export const AllTagsDocument = gql`
	query AllTags($pagination: PaginatedInput!) {
		allTags(pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			tags {
				...ExtendedTag
			}
		}
	}
	${ExtendedTagFragmentDoc}
`

export function useAllTagsQuery(options: Omit<Urql.UseQueryArgs<AllTagsQueryVariables>, "query">) {
	return Urql.useQuery<AllTagsQuery, AllTagsQueryVariables>({ query: AllTagsDocument, ...options })
}
export const AllUserGroupsDocument = gql`
	query AllUserGroups($pagination: PaginatedInput!) {
		allUserGroups(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userGroups {
				...ExtendedUserGroup
			}
		}
	}
	${ExtendedUserGroupFragmentDoc}
`

export function useAllUserGroupsQuery(options: Omit<Urql.UseQueryArgs<AllUserGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AllUserGroupsQuery, AllUserGroupsQueryVariables>({ query: AllUserGroupsDocument, ...options })
}
export const AllUserRoleGroupsDocument = gql`
	query AllUserRoleGroups($pagination: PaginatedInput!) {
		allUserRoleGroups(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userRoleGroups {
				...ExtendedUserRoleGroup
			}
		}
	}
	${ExtendedUserRoleGroupFragmentDoc}
`

export function useAllUserRoleGroupsQuery(options: Omit<Urql.UseQueryArgs<AllUserRoleGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AllUserRoleGroupsQuery, AllUserRoleGroupsQueryVariables>({ query: AllUserRoleGroupsDocument, ...options })
}
export const AllUserTypesDocument = gql`
	query AllUserTypes($pagination: PaginatedInput!) {
		allUserTypes(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userTypes {
				...UserType
			}
		}
	}
	${UserTypeFragmentDoc}
`

export function useAllUserTypesQuery(options: Omit<Urql.UseQueryArgs<AllUserTypesQueryVariables>, "query">) {
	return Urql.useQuery<AllUserTypesQuery, AllUserTypesQueryVariables>({ query: AllUserTypesDocument, ...options })
}
export const AllUsersDocument = gql`
	query AllUsers($pagination: PaginatedInput!) {
		allUsers(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...ExtendedUser
			}
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useAllUsersQuery(options: Omit<Urql.UseQueryArgs<AllUsersQueryVariables>, "query">) {
	return Urql.useQuery<AllUsersQuery, AllUsersQueryVariables>({ query: AllUsersDocument, ...options })
}
export const AllDepartmentsDocument = gql`
	query AllDepartments($pagination: PaginatedInput!) {
		allDepartments(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			departments {
				...ExtendedDepartment
			}
		}
	}
	${ExtendedDepartmentFragmentDoc}
`

export function useAllDepartmentsQuery(options: Omit<Urql.UseQueryArgs<AllDepartmentsQueryVariables>, "query">) {
	return Urql.useQuery<AllDepartmentsQuery, AllDepartmentsQueryVariables>({ query: AllDepartmentsDocument, ...options })
}
export const AllZoneGroupsDocument = gql`
	query AllZoneGroups($pagination: PaginatedInput!) {
		allZoneGroups(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			zoneGroups {
				...ExtendedZoneGroup
			}
		}
	}
	${ExtendedZoneGroupFragmentDoc}
`

export function useAllZoneGroupsQuery(options: Omit<Urql.UseQueryArgs<AllZoneGroupsQueryVariables>, "query">) {
	return Urql.useQuery<AllZoneGroupsQuery, AllZoneGroupsQueryVariables>({ query: AllZoneGroupsDocument, ...options })
}
export const AllZonesDocument = gql`
	query AllZones($pagination: PaginatedInput!) {
		allZones(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			zones {
				...ExtendedZone
			}
		}
	}
	${ExtendedZoneFragmentDoc}
`

export function useAllZonesQuery(options: Omit<Urql.UseQueryArgs<AllZonesQueryVariables>, "query">) {
	return Urql.useQuery<AllZonesQuery, AllZonesQueryVariables>({ query: AllZonesDocument, ...options })
}
export const DepartmentByIdDocument = gql`
	query DepartmentById($departmentId: String!) {
		departmentById(departmentId: $departmentId) {
			...ExtendedDepartment
		}
	}
	${ExtendedDepartmentFragmentDoc}
`

export function useDepartmentByIdQuery(options: Omit<Urql.UseQueryArgs<DepartmentByIdQueryVariables>, "query">) {
	return Urql.useQuery<DepartmentByIdQuery, DepartmentByIdQueryVariables>({ query: DepartmentByIdDocument, ...options })
}
export const DepartmentsDocument = gql`
	query Departments {
		departments {
			...Department
		}
	}
	${DepartmentFragmentDoc}
`

export function useDepartmentsQuery(options?: Omit<Urql.UseQueryArgs<DepartmentsQueryVariables>, "query">) {
	return Urql.useQuery<DepartmentsQuery, DepartmentsQueryVariables>({ query: DepartmentsDocument, ...options })
}
export const DepartmentsByKeywordDocument = gql`
	query DepartmentsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		departmentsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			departments {
				...ExtendedDepartment
			}
		}
	}
	${ExtendedDepartmentFragmentDoc}
`

export function useDepartmentsByKeywordQuery(options: Omit<Urql.UseQueryArgs<DepartmentsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<DepartmentsByKeywordQuery, DepartmentsByKeywordQueryVariables>({ query: DepartmentsByKeywordDocument, ...options })
}
export const LastWeekRestrictedUserLocationRecordsDocument = gql`
	query LastWeekRestrictedUserLocationRecords {
		lastWeekRestrictedUserLocationRecords {
			...ExtendedUserLocationRecord
		}
	}
	${ExtendedUserLocationRecordFragmentDoc}
`

export function useLastWeekRestrictedUserLocationRecordsQuery(options?: Omit<Urql.UseQueryArgs<LastWeekRestrictedUserLocationRecordsQueryVariables>, "query">) {
	return Urql.useQuery<LastWeekRestrictedUserLocationRecordsQuery, LastWeekRestrictedUserLocationRecordsQueryVariables>({ query: LastWeekRestrictedUserLocationRecordsDocument, ...options })
}
export const LastestMobileAppDocument = gql`
	query LastestMobileApp {
		lastestMobileApp {
			...MobileApp
		}
	}
	${MobileAppFragmentDoc}
`

export function useLastestMobileAppQuery(options?: Omit<Urql.UseQueryArgs<LastestMobileAppQueryVariables>, "query">) {
	return Urql.useQuery<LastestMobileAppQuery, LastestMobileAppQueryVariables>({ query: LastestMobileAppDocument, ...options })
}
export const MeDocument = gql`
	query Me {
		me {
			...MeUser
		}
	}
	${MeUserFragmentDoc}
`

export function useMeQuery(options?: Omit<Urql.UseQueryArgs<MeQueryVariables>, "query">) {
	return Urql.useQuery<MeQuery, MeQueryVariables>({ query: MeDocument, ...options })
}
export const MobileAppsDocument = gql`
	query MobileApps {
		mobileApps {
			...MobileApp
		}
	}
	${MobileAppFragmentDoc}
`

export function useMobileAppsQuery(options?: Omit<Urql.UseQueryArgs<MobileAppsQueryVariables>, "query">) {
	return Urql.useQuery<MobileAppsQuery, MobileAppsQueryVariables>({ query: MobileAppsDocument, ...options })
}
export const MobileAppsByFilterDocument = gql`
	query MobileAppsByFilter($filter: MobileAppsFilterInput!, $pagination: PaginatedInput!) {
		mobileAppsByFilter(filter: $filter, pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			mobileApps {
				...MobileApp
			}
		}
	}
	${MobileAppFragmentDoc}
`

export function useMobileAppsByFilterQuery(options: Omit<Urql.UseQueryArgs<MobileAppsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<MobileAppsByFilterQuery, MobileAppsByFilterQueryVariables>({ query: MobileAppsByFilterDocument, ...options })
}
export const PatrollingRouteByIdDocument = gql`
	query PatrollingRouteById($patrollingRouteId: String!) {
		patrollingRouteById(patrollingRouteId: $patrollingRouteId) {
			...ExtendedPatrollingRoute
		}
	}
	${ExtendedPatrollingRouteFragmentDoc}
`

export function usePatrollingRouteByIdQuery(options: Omit<Urql.UseQueryArgs<PatrollingRouteByIdQueryVariables>, "query">) {
	return Urql.useQuery<PatrollingRouteByIdQuery, PatrollingRouteByIdQueryVariables>({ query: PatrollingRouteByIdDocument, ...options })
}
export const PatrollingRoutesDocument = gql`
	query PatrollingRoutes {
		patrollingRoutes {
			...ExtendedPatrollingRoute
		}
	}
	${ExtendedPatrollingRouteFragmentDoc}
`

export function usePatrollingRoutesQuery(options?: Omit<Urql.UseQueryArgs<PatrollingRoutesQueryVariables>, "query">) {
	return Urql.useQuery<PatrollingRoutesQuery, PatrollingRoutesQueryVariables>({ query: PatrollingRoutesDocument, ...options })
}
export const PatrollingRoutesByKeywordDocument = gql`
	query PatrollingRoutesByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		patrollingRoutesByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			patrollingRoutes {
				...ExtendedPatrollingRoute
			}
		}
	}
	${ExtendedPatrollingRouteFragmentDoc}
`

export function usePatrollingRoutesByKeywordQuery(options: Omit<Urql.UseQueryArgs<PatrollingRoutesByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<PatrollingRoutesByKeywordQuery, PatrollingRoutesByKeywordQueryVariables>({ query: PatrollingRoutesByKeywordDocument, ...options })
}
export const PatrollingUsersDashboardStatsDocument = gql`
	query PatrollingUsersDashboardStats {
		patrollingUsersDashboardStats {
			...PatrollingUserDashboardStatsResponse
		}
	}
	${PatrollingUserDashboardStatsResponseFragmentDoc}
`

export function usePatrollingUsersDashboardStatsQuery(options?: Omit<Urql.UseQueryArgs<PatrollingUsersDashboardStatsQueryVariables>, "query">) {
	return Urql.useQuery<PatrollingUsersDashboardStatsQuery, PatrollingUsersDashboardStatsQueryVariables>({ query: PatrollingUsersDashboardStatsDocument, ...options })
}
export const PermissionsGroupByIdDocument = gql`
	query PermissionsGroupById($permissionsGroupId: String!) {
		permissionsGroupById(permissionsGroupId: $permissionsGroupId) {
			...PermissionsGroup
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function usePermissionsGroupByIdQuery(options: Omit<Urql.UseQueryArgs<PermissionsGroupByIdQueryVariables>, "query">) {
	return Urql.useQuery<PermissionsGroupByIdQuery, PermissionsGroupByIdQueryVariables>({ query: PermissionsGroupByIdDocument, ...options })
}
export const PermissionsGroupsDocument = gql`
	query PermissionsGroups {
		permissionsGroups {
			...PermissionsGroup
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function usePermissionsGroupsQuery(options?: Omit<Urql.UseQueryArgs<PermissionsGroupsQueryVariables>, "query">) {
	return Urql.useQuery<PermissionsGroupsQuery, PermissionsGroupsQueryVariables>({ query: PermissionsGroupsDocument, ...options })
}
export const PermissionsGroupsByKeywordDocument = gql`
	query PermissionsGroupsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		permissionsGroupsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			permissionsGroups {
				...PermissionsGroup
			}
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function usePermissionsGroupsByKeywordQuery(options: Omit<Urql.UseQueryArgs<PermissionsGroupsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<PermissionsGroupsByKeywordQuery, PermissionsGroupsByKeywordQueryVariables>({ query: PermissionsGroupsByKeywordDocument, ...options })
}
export const PermissionTagsDocument = gql`
	query PermissionTags {
		permissionTags
	}
`

export function usePermissionTagsQuery(options?: Omit<Urql.UseQueryArgs<PermissionTagsQueryVariables>, "query">) {
	return Urql.useQuery<PermissionTagsQuery, PermissionTagsQueryVariables>({ query: PermissionTagsDocument, ...options })
}
export const PreviousWeekRestrictedUserLocationRecordsDocument = gql`
	query PreviousWeekRestrictedUserLocationRecords {
		previousWeekRestrictedUserLocationRecords {
			...ExtendedUserLocationRecord
		}
	}
	${ExtendedUserLocationRecordFragmentDoc}
`

export function usePreviousWeekRestrictedUserLocationRecordsQuery(options?: Omit<Urql.UseQueryArgs<PreviousWeekRestrictedUserLocationRecordsQueryVariables>, "query">) {
	return Urql.useQuery<PreviousWeekRestrictedUserLocationRecordsQuery, PreviousWeekRestrictedUserLocationRecordsQueryVariables>({ query: PreviousWeekRestrictedUserLocationRecordsDocument, ...options })
}
export const ReaderByIdDocument = gql`
	query ReaderById($readerId: String!) {
		readerById(readerId: $readerId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReaderByIdQuery(options: Omit<Urql.UseQueryArgs<ReaderByIdQueryVariables>, "query">) {
	return Urql.useQuery<ReaderByIdQuery, ReaderByIdQueryVariables>({ query: ReaderByIdDocument, ...options })
}
export const ReadersDocument = gql`
	query Readers {
		readers {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReadersQuery(options?: Omit<Urql.UseQueryArgs<ReadersQueryVariables>, "query">) {
	return Urql.useQuery<ReadersQuery, ReadersQueryVariables>({ query: ReadersDocument, ...options })
}
export const ReadersAtZoneDocument = gql`
	query ReadersAtZone($zoneId: String!) {
		readersAtZone(zoneId: $zoneId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReadersAtZoneQuery(options: Omit<Urql.UseQueryArgs<ReadersAtZoneQueryVariables>, "query">) {
	return Urql.useQuery<ReadersAtZoneQuery, ReadersAtZoneQueryVariables>({ query: ReadersAtZoneDocument, ...options })
}
export const ReadersByFilterDocument = gql`
	query ReadersByFilter($filter: ReadersFilterInput!, $pagination: PaginatedInput!) {
		readersByFilter(filter: $filter, pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			readers {
				...Reader
			}
		}
	}
	${ReaderFragmentDoc}
`

export function useReadersByFilterQuery(options: Omit<Urql.UseQueryArgs<ReadersByFilterQueryVariables>, "query">) {
	return Urql.useQuery<ReadersByFilterQuery, ReadersByFilterQueryVariables>({ query: ReadersByFilterDocument, ...options })
}
export const ReportByIdDocument = gql`
	query ReportById($reportId: String!) {
		reportById(reportId: $reportId) {
			...ExtendedReport
		}
	}
	${ExtendedReportFragmentDoc}
`

export function useReportByIdQuery(options: Omit<Urql.UseQueryArgs<ReportByIdQueryVariables>, "query">) {
	return Urql.useQuery<ReportByIdQuery, ReportByIdQueryVariables>({ query: ReportByIdDocument, ...options })
}
export const ReportsByKeywordDocument = gql`
	query ReportsByKeyword($keyword: String!, $type: String, $status: String, $startDate: DateTime, $endDate: DateTime, $pagination: PaginatedInput!) {
		reportsByKeyword(keyword: $keyword, type: $type, status: $status, startDate: $startDate, endDate: $endDate, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			reports {
				...Report
			}
		}
	}
	${ReportFragmentDoc}
`

export function useReportsByKeywordQuery(options: Omit<Urql.UseQueryArgs<ReportsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<ReportsByKeywordQuery, ReportsByKeywordQueryVariables>({ query: ReportsByKeywordDocument, ...options })
}
export const RestrictedUserLocationRecordsAtZoneDocument = gql`
	query RestrictedUserLocationRecordsAtZone($zoneId: String!) {
		restrictedUserLocationRecordsAtZone(zoneId: $zoneId) {
			...ExtendedUserLocationRecord
		}
	}
	${ExtendedUserLocationRecordFragmentDoc}
`

export function useRestrictedUserLocationRecordsAtZoneQuery(options: Omit<Urql.UseQueryArgs<RestrictedUserLocationRecordsAtZoneQueryVariables>, "query">) {
	return Urql.useQuery<RestrictedUserLocationRecordsAtZoneQuery, RestrictedUserLocationRecordsAtZoneQueryVariables>({ query: RestrictedUserLocationRecordsAtZoneDocument, ...options })
}
export const RootTileDocument = gql`
	query RootTile {
		rootTile {
			...Tile
		}
	}
	${TileFragmentDoc}
`

export function useRootTileQuery(options?: Omit<Urql.UseQueryArgs<RootTileQueryVariables>, "query">) {
	return Urql.useQuery<RootTileQuery, RootTileQueryVariables>({ query: RootTileDocument, ...options })
}
export const ServiceByIdDocument = gql`
	query ServiceById($serviceId: String!) {
		serviceById(serviceId: $serviceId) {
			...Service
		}
	}
	${ServiceFragmentDoc}
`

export function useServiceByIdQuery(options: Omit<Urql.UseQueryArgs<ServiceByIdQueryVariables>, "query">) {
	return Urql.useQuery<ServiceByIdQuery, ServiceByIdQueryVariables>({ query: ServiceByIdDocument, ...options })
}
export const ServicesByKeywordDocument = gql`
	query ServicesByKeyword($keyword: String!) {
		servicesByKeyword(keyword: $keyword) {
			...Service
		}
	}
	${ServiceFragmentDoc}
`

export function useServicesByKeywordQuery(options: Omit<Urql.UseQueryArgs<ServicesByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<ServicesByKeywordQuery, ServicesByKeywordQueryVariables>({ query: ServicesByKeywordDocument, ...options })
}
export const StatusLogsOfReaderDocument = gql`
	query StatusLogsOfReader($readerId: String!, $date: DateTime!, $pagination: PaginatedInput!) {
		statusLogsOfReader(readerId: $readerId, date: $date, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			readerStatusLogs {
				...ReaderStatusLog
			}
		}
	}
	${ReaderStatusLogFragmentDoc}
`

export function useStatusLogsOfReaderQuery(options: Omit<Urql.UseQueryArgs<StatusLogsOfReaderQueryVariables>, "query">) {
	return Urql.useQuery<StatusLogsOfReaderQuery, StatusLogsOfReaderQueryVariables>({ query: StatusLogsOfReaderDocument, ...options })
}
export const TagByIdDocument = gql`
	query TagById($tagId: String!) {
		tagById(tagId: $tagId) {
			...ExtendedTag
		}
	}
	${ExtendedTagFragmentDoc}
`

export function useTagByIdQuery(options: Omit<Urql.UseQueryArgs<TagByIdQueryVariables>, "query">) {
	return Urql.useQuery<TagByIdQuery, TagByIdQueryVariables>({ query: TagByIdDocument, ...options })
}
export const TagsByFilterDocument = gql`
	query TagsByFilter($filter: TagsFilterInput!, $pagination: PaginatedInput!) {
		tagsByFilter(filter: $filter, pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			tags {
				...ExtendedTag
			}
		}
	}
	${ExtendedTagFragmentDoc}
`

export function useTagsByFilterQuery(options: Omit<Urql.UseQueryArgs<TagsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<TagsByFilterQuery, TagsByFilterQueryVariables>({ query: TagsByFilterDocument, ...options })
}
export const TileByIdDocument = gql`
	query TileById($tileId: String!) {
		tileById(tileId: $tileId) {
			...Tile
		}
	}
	${TileFragmentDoc}
`

export function useTileByIdQuery(options: Omit<Urql.UseQueryArgs<TileByIdQueryVariables>, "query">) {
	return Urql.useQuery<TileByIdQuery, TileByIdQueryVariables>({ query: TileByIdDocument, ...options })
}
export const TileEmbeddedZonesByTileIdDocument = gql`
	query TileEmbeddedZonesByTileId($tileId: String!) {
		tileEmbeddedZonesByTileId(tileId: $tileId) {
			...ExtendedTileEmbeddedZone
		}
	}
	${ExtendedTileEmbeddedZoneFragmentDoc}
`

export function useTileEmbeddedZonesByTileIdQuery(options: Omit<Urql.UseQueryArgs<TileEmbeddedZonesByTileIdQueryVariables>, "query">) {
	return Urql.useQuery<TileEmbeddedZonesByTileIdQuery, TileEmbeddedZonesByTileIdQueryVariables>({ query: TileEmbeddedZonesByTileIdDocument, ...options })
}
export const TilesDocument = gql`
	query Tiles {
		tiles {
			...Tile
		}
	}
	${TileFragmentDoc}
`

export function useTilesQuery(options?: Omit<Urql.UseQueryArgs<TilesQueryVariables>, "query">) {
	return Urql.useQuery<TilesQuery, TilesQueryVariables>({ query: TilesDocument, ...options })
}
export const TodayAttendanceStatsDocument = gql`
	query TodayAttendanceStats($date: DateTime!) {
		todayAttendanceStats(date: $date) {
			...UserAttendanceStatsResponse
		}
	}
	${UserAttendanceStatsResponseFragmentDoc}
`

export function useTodayAttendanceStatsQuery(options: Omit<Urql.UseQueryArgs<TodayAttendanceStatsQueryVariables>, "query">) {
	return Urql.useQuery<TodayAttendanceStatsQuery, TodayAttendanceStatsQueryVariables>({ query: TodayAttendanceStatsDocument, ...options })
}
export const TodayRestrictedUserLocationRecordsDocument = gql`
	query TodayRestrictedUserLocationRecords {
		todayRestrictedUserLocationRecords
	}
`

export function useTodayRestrictedUserLocationRecordsQuery(options?: Omit<Urql.UseQueryArgs<TodayRestrictedUserLocationRecordsQueryVariables>, "query">) {
	return Urql.useQuery<TodayRestrictedUserLocationRecordsQuery, TodayRestrictedUserLocationRecordsQueryVariables>({ query: TodayRestrictedUserLocationRecordsDocument, ...options })
}
export const TodayTrackedUserIdsDocument = gql`
	query TodayTrackedUserIds {
		todayTrackedUserIds
	}
`

export function useTodayTrackedUserIdsQuery(options?: Omit<Urql.UseQueryArgs<TodayTrackedUserIdsQueryVariables>, "query">) {
	return Urql.useQuery<TodayTrackedUserIdsQuery, TodayTrackedUserIdsQueryVariables>({ query: TodayTrackedUserIdsDocument, ...options })
}
export const TodaysShiftWiseAttendanceStatsDocument = gql`
	query TodaysShiftWiseAttendanceStats($date: DateTime!) {
		todaysShiftWiseAttendanceStats(date: $date) {
			morningPresent {
				...ExtendedUserAttendance
			}
			morningAbsent {
				...User
			}
			eveningPresent {
				...ExtendedUserAttendance
			}
			eveningAbsent {
				...User
			}
		}
	}
	${ExtendedUserAttendanceFragmentDoc}
	${UserFragmentDoc}
`

export function useTodaysShiftWiseAttendanceStatsQuery(options: Omit<Urql.UseQueryArgs<TodaysShiftWiseAttendanceStatsQueryVariables>, "query">) {
	return Urql.useQuery<TodaysShiftWiseAttendanceStatsQuery, TodaysShiftWiseAttendanceStatsQueryVariables>({ query: TodaysShiftWiseAttendanceStatsDocument, ...options })
}
export const TodaysUserAttendanceDocument = gql`
	query TodaysUserAttendance($userId: String!) {
		todaysUserAttendance(userId: $userId) {
			...ExtendedUserAttendance
		}
	}
	${ExtendedUserAttendanceFragmentDoc}
`

export function useTodaysUserAttendanceQuery(options: Omit<Urql.UseQueryArgs<TodaysUserAttendanceQueryVariables>, "query">) {
	return Urql.useQuery<TodaysUserAttendanceQuery, TodaysUserAttendanceQueryVariables>({ query: TodaysUserAttendanceDocument, ...options })
}
export const UserActiveRouteRecordDocument = gql`
	query UserActiveRouteRecord($userId: String!) {
		userActiveRouteRecord(userId: $userId) {
			...ExtendedUserRouteRecord
		}
	}
	${ExtendedUserRouteRecordFragmentDoc}
`

export function useUserActiveRouteRecordQuery(options: Omit<Urql.UseQueryArgs<UserActiveRouteRecordQueryVariables>, "query">) {
	return Urql.useQuery<UserActiveRouteRecordQuery, UserActiveRouteRecordQueryVariables>({ query: UserActiveRouteRecordDocument, ...options })
}
export const UserByIdDocument = gql`
	query UserById($userId: String!) {
		userById(userId: $userId) {
			...ExtendedUser
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useUserByIdQuery(options: Omit<Urql.UseQueryArgs<UserByIdQueryVariables>, "query">) {
	return Urql.useQuery<UserByIdQuery, UserByIdQueryVariables>({ query: UserByIdDocument, ...options })
}
export const UserCurrentLocationRecordDocument = gql`
	query UserCurrentLocationRecord($userId: String!) {
		userCurrentLocationRecord(userId: $userId) {
			...ExtendedUserLocationRecord
		}
	}
	${ExtendedUserLocationRecordFragmentDoc}
`

export function useUserCurrentLocationRecordQuery(options: Omit<Urql.UseQueryArgs<UserCurrentLocationRecordQueryVariables>, "query">) {
	return Urql.useQuery<UserCurrentLocationRecordQuery, UserCurrentLocationRecordQueryVariables>({ query: UserCurrentLocationRecordDocument, ...options })
}
export const UserCurrentRouteRecordDocument = gql`
	query UserCurrentRouteRecord($userId: String!) {
		userCurrentRouteRecord(userId: $userId) {
			...ExtendedUserRouteRecord
		}
	}
	${ExtendedUserRouteRecordFragmentDoc}
`

export function useUserCurrentRouteRecordQuery(options: Omit<Urql.UseQueryArgs<UserCurrentRouteRecordQueryVariables>, "query">) {
	return Urql.useQuery<UserCurrentRouteRecordQuery, UserCurrentRouteRecordQueryVariables>({ query: UserCurrentRouteRecordDocument, ...options })
}
export const UserCurrentUserRoleDocument = gql`
	query UserCurrentUserRole($userId: String!) {
		userCurrentUserRole(userId: $userId) {
			...ExtendedUserRole
		}
	}
	${ExtendedUserRoleFragmentDoc}
`

export function useUserCurrentUserRoleQuery(options: Omit<Urql.UseQueryArgs<UserCurrentUserRoleQueryVariables>, "query">) {
	return Urql.useQuery<UserCurrentUserRoleQuery, UserCurrentUserRoleQueryVariables>({ query: UserCurrentUserRoleDocument, ...options })
}
export const UserGroupByIdDocument = gql`
	query UserGroupById($userGroupId: String!) {
		userGroupById(userGroupId: $userGroupId) {
			...ExtendedUserGroup
		}
	}
	${ExtendedUserGroupFragmentDoc}
`

export function useUserGroupByIdQuery(options: Omit<Urql.UseQueryArgs<UserGroupByIdQueryVariables>, "query">) {
	return Urql.useQuery<UserGroupByIdQuery, UserGroupByIdQueryVariables>({ query: UserGroupByIdDocument, ...options })
}
export const UserGroupsDocument = gql`
	query UserGroups {
		userGroups {
			...ExtendedUserGroup
		}
	}
	${ExtendedUserGroupFragmentDoc}
`

export function useUserGroupsQuery(options?: Omit<Urql.UseQueryArgs<UserGroupsQueryVariables>, "query">) {
	return Urql.useQuery<UserGroupsQuery, UserGroupsQueryVariables>({ query: UserGroupsDocument, ...options })
}
export const UserGroupsByKeywordDocument = gql`
	query UserGroupsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		userGroupsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userGroups {
				...ExtendedUserGroup
			}
		}
	}
	${ExtendedUserGroupFragmentDoc}
`

export function useUserGroupsByKeywordQuery(options: Omit<Urql.UseQueryArgs<UserGroupsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<UserGroupsByKeywordQuery, UserGroupsByKeywordQueryVariables>({ query: UserGroupsByKeywordDocument, ...options })
}
export const UserLocationRecordsAtZoneDocument = gql`
	query UserLocationRecordsAtZone($zoneId: String!, $userTypeIds: [String!]) {
		userLocationRecordsAtZone(zoneId: $zoneId, userTypeIds: $userTypeIds) {
			...ExtendedUserLocationRecord
		}
	}
	${ExtendedUserLocationRecordFragmentDoc}
`

export function useUserLocationRecordsAtZoneQuery(options: Omit<Urql.UseQueryArgs<UserLocationRecordsAtZoneQueryVariables>, "query">) {
	return Urql.useQuery<UserLocationRecordsAtZoneQuery, UserLocationRecordsAtZoneQueryVariables>({ query: UserLocationRecordsAtZoneDocument, ...options })
}
export const UserLocationStatsDocument = gql`
	query UserLocationStats($userId: String!, $startTimestamp: DateTime!, $endTimestamp: DateTime!) {
		userLocationStats(userId: $userId, startTimestamp: $startTimestamp, endTimestamp: $endTimestamp) {
			zone {
				_id
				name
			}
			startAt
			endAt
		}
	}
`

export function useUserLocationStatsQuery(options: Omit<Urql.UseQueryArgs<UserLocationStatsQueryVariables>, "query">) {
	return Urql.useQuery<UserLocationStatsQuery, UserLocationStatsQueryVariables>({ query: UserLocationStatsDocument, ...options })
}
export const UserPastLocationRecordsDocument = gql`
	query UserPastLocationRecords($userId: String!, $date: DateTime!, $pagination: PaginatedInput!) {
		userPastLocationRecords(userId: $userId, date: $date, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userLocationRecords {
				...ExtendedUserLocationRecord
			}
		}
	}
	${ExtendedUserLocationRecordFragmentDoc}
`

export function useUserPastLocationRecordsQuery(options: Omit<Urql.UseQueryArgs<UserPastLocationRecordsQueryVariables>, "query">) {
	return Urql.useQuery<UserPastLocationRecordsQuery, UserPastLocationRecordsQueryVariables>({ query: UserPastLocationRecordsDocument, ...options })
}
export const UserPastRouteRecordsDocument = gql`
	query UserPastRouteRecords($userId: String!, $date: DateTime!, $pagination: PaginatedInput!) {
		userPastRouteRecords(userId: $userId, date: $date, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userRouteRecords {
				...ExtendedUserRouteRecord
			}
		}
	}
	${ExtendedUserRouteRecordFragmentDoc}
`

export function useUserPastRouteRecordsQuery(options: Omit<Urql.UseQueryArgs<UserPastRouteRecordsQueryVariables>, "query">) {
	return Urql.useQuery<UserPastRouteRecordsQuery, UserPastRouteRecordsQueryVariables>({ query: UserPastRouteRecordsDocument, ...options })
}
export const UserPermissionsGroupsDocument = gql`
	query UserPermissionsGroups($userId: String!) {
		userPermissionsGroups(userId: $userId) {
			...PermissionsGroup
		}
	}
	${PermissionsGroupFragmentDoc}
`

export function useUserPermissionsGroupsQuery(options: Omit<Urql.UseQueryArgs<UserPermissionsGroupsQueryVariables>, "query">) {
	return Urql.useQuery<UserPermissionsGroupsQuery, UserPermissionsGroupsQueryVariables>({ query: UserPermissionsGroupsDocument, ...options })
}
export const UserRoleGroupByIdDocument = gql`
	query UserRoleGroupById($userRoleGroupId: String!) {
		userRoleGroupById(userRoleGroupId: $userRoleGroupId) {
			...ExtendedUserRoleGroup
		}
	}
	${ExtendedUserRoleGroupFragmentDoc}
`

export function useUserRoleGroupByIdQuery(options: Omit<Urql.UseQueryArgs<UserRoleGroupByIdQueryVariables>, "query">) {
	return Urql.useQuery<UserRoleGroupByIdQuery, UserRoleGroupByIdQueryVariables>({ query: UserRoleGroupByIdDocument, ...options })
}
export const UserRoleGroupsDocument = gql`
	query UserRoleGroups($type: UserRoleTypes) {
		userRoleGroups(type: $type) {
			...UserRoleGroup
		}
	}
	${UserRoleGroupFragmentDoc}
`

export function useUserRoleGroupsQuery(options?: Omit<Urql.UseQueryArgs<UserRoleGroupsQueryVariables>, "query">) {
	return Urql.useQuery<UserRoleGroupsQuery, UserRoleGroupsQueryVariables>({ query: UserRoleGroupsDocument, ...options })
}
export const UserRoleGroupsByKeywordDocument = gql`
	query UserRoleGroupsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		userRoleGroupsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userRoleGroups {
				...ExtendedUserRoleGroup
			}
		}
	}
	${ExtendedUserRoleGroupFragmentDoc}
`

export function useUserRoleGroupsByKeywordQuery(options: Omit<Urql.UseQueryArgs<UserRoleGroupsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<UserRoleGroupsByKeywordQuery, UserRoleGroupsByKeywordQueryVariables>({ query: UserRoleGroupsByKeywordDocument, ...options })
}
export const UserTypeByIdDocument = gql`
	query UserTypeById($userTypeId: String!) {
		userTypeById(userTypeId: $userTypeId) {
			...UserType
		}
	}
	${UserTypeFragmentDoc}
`

export function useUserTypeByIdQuery(options: Omit<Urql.UseQueryArgs<UserTypeByIdQueryVariables>, "query">) {
	return Urql.useQuery<UserTypeByIdQuery, UserTypeByIdQueryVariables>({ query: UserTypeByIdDocument, ...options })
}
export const UserTypesDocument = gql`
	query UserTypes {
		userTypes {
			...UserType
		}
	}
	${UserTypeFragmentDoc}
`

export function useUserTypesQuery(options?: Omit<Urql.UseQueryArgs<UserTypesQueryVariables>, "query">) {
	return Urql.useQuery<UserTypesQuery, UserTypesQueryVariables>({ query: UserTypesDocument, ...options })
}
export const UserTypesByKeywordDocument = gql`
	query UserTypesByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		userTypesByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			userTypes {
				...UserType
			}
		}
	}
	${UserTypeFragmentDoc}
`

export function useUserTypesByKeywordQuery(options: Omit<Urql.UseQueryArgs<UserTypesByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<UserTypesByKeywordQuery, UserTypesByKeywordQueryVariables>({ query: UserTypesByKeywordDocument, ...options })
}
export const UsersDocument = gql`
	query Users($filter: UsersFilterInput!, $pagination: PaginatedInput!) {
		users(filter: $filter, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...ExtendedUser
			}
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useUsersQuery(options: Omit<Urql.UseQueryArgs<UsersQueryVariables>, "query">) {
	return Urql.useQuery<UsersQuery, UsersQueryVariables>({ query: UsersDocument, ...options })
}
export const UsersByCurrentUserRoleGroupDocument = gql`
	query UsersByCurrentUserRoleGroup($userRoleGroupId: String!, $pagination: PaginatedInput!) {
		usersByCurrentUserRoleGroup(userRoleGroupId: $userRoleGroupId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...ExtendedUser
			}
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useUsersByCurrentUserRoleGroupQuery(options: Omit<Urql.UseQueryArgs<UsersByCurrentUserRoleGroupQueryVariables>, "query">) {
	return Urql.useQuery<UsersByCurrentUserRoleGroupQuery, UsersByCurrentUserRoleGroupQueryVariables>({ query: UsersByCurrentUserRoleGroupDocument, ...options })
}
export const UsersByDepartmentDocument = gql`
	query UsersByDepartment($departmentId: String!, $pagination: PaginatedInput!) {
		usersByDepartment(departmentId: $departmentId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...ExtendedUser
			}
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useUsersByDepartmentQuery(options: Omit<Urql.UseQueryArgs<UsersByDepartmentQueryVariables>, "query">) {
	return Urql.useQuery<UsersByDepartmentQuery, UsersByDepartmentQueryVariables>({ query: UsersByDepartmentDocument, ...options })
}
export const UsersByKeywordDocument = gql`
	query UsersByKeyword($keyword: String!, $typeId: String, $pagination: PaginatedInput!) {
		usersByKeyword(keyword: $keyword, typeId: $typeId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...ExtendedUser
			}
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useUsersByKeywordQuery(options: Omit<Urql.UseQueryArgs<UsersByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<UsersByKeywordQuery, UsersByKeywordQueryVariables>({ query: UsersByKeywordDocument, ...options })
}
export const UsersByTypeDocument = gql`
	query UsersByType($typeId: String!, $pagination: PaginatedInput!) {
		usersByType(typeId: $typeId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...ExtendedUser
			}
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useUsersByTypeQuery(options: Omit<Urql.UseQueryArgs<UsersByTypeQueryVariables>, "query">) {
	return Urql.useQuery<UsersByTypeQuery, UsersByTypeQueryVariables>({ query: UsersByTypeDocument, ...options })
}
export const UsersByWorkShiftDocument = gql`
	query UsersByWorkShift($workShiftId: String!, $pagination: PaginatedInput!) {
		usersByWorkShift(workShiftId: $workShiftId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			users {
				...ExtendedUser
			}
		}
	}
	${ExtendedUserFragmentDoc}
`

export function useUsersByWorkShiftQuery(options: Omit<Urql.UseQueryArgs<UsersByWorkShiftQueryVariables>, "query">) {
	return Urql.useQuery<UsersByWorkShiftQuery, UsersByWorkShiftQueryVariables>({ query: UsersByWorkShiftDocument, ...options })
}
export const WorkShiftByIdDocument = gql`
	query WorkShiftById($workShiftId: String!) {
		workShiftById(workShiftId: $workShiftId) {
			...ExtendedWorkShift
		}
	}
	${ExtendedWorkShiftFragmentDoc}
`

export function useWorkShiftByIdQuery(options: Omit<Urql.UseQueryArgs<WorkShiftByIdQueryVariables>, "query">) {
	return Urql.useQuery<WorkShiftByIdQuery, WorkShiftByIdQueryVariables>({ query: WorkShiftByIdDocument, ...options })
}
export const WorkShiftsDocument = gql`
	query WorkShifts {
		workShifts {
			...WorkShift
		}
	}
	${WorkShiftFragmentDoc}
`

export function useWorkShiftsQuery(options?: Omit<Urql.UseQueryArgs<WorkShiftsQueryVariables>, "query">) {
	return Urql.useQuery<WorkShiftsQuery, WorkShiftsQueryVariables>({ query: WorkShiftsDocument, ...options })
}
export const WorkShiftsByKeywordDocument = gql`
	query WorkShiftsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		workShiftsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			workShifts {
				...WorkShift
			}
		}
	}
	${WorkShiftFragmentDoc}
`

export function useWorkShiftsByKeywordQuery(options: Omit<Urql.UseQueryArgs<WorkShiftsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<WorkShiftsByKeywordQuery, WorkShiftsByKeywordQueryVariables>({ query: WorkShiftsByKeywordDocument, ...options })
}
export const ZoneByIdDocument = gql`
	query ZoneById($zoneId: String!) {
		zoneById(zoneId: $zoneId) {
			...ExtendedZone
		}
	}
	${ExtendedZoneFragmentDoc}
`

export function useZoneByIdQuery(options: Omit<Urql.UseQueryArgs<ZoneByIdQueryVariables>, "query">) {
	return Urql.useQuery<ZoneByIdQuery, ZoneByIdQueryVariables>({ query: ZoneByIdDocument, ...options })
}
export const ZoneGroupByIdDocument = gql`
	query ZoneGroupById($zoneGroupId: String!) {
		zoneGroupById(zoneGroupId: $zoneGroupId) {
			...ExtendedZoneGroup
		}
	}
	${ExtendedZoneGroupFragmentDoc}
`

export function useZoneGroupByIdQuery(options: Omit<Urql.UseQueryArgs<ZoneGroupByIdQueryVariables>, "query">) {
	return Urql.useQuery<ZoneGroupByIdQuery, ZoneGroupByIdQueryVariables>({ query: ZoneGroupByIdDocument, ...options })
}
export const ZoneGroupsDocument = gql`
	query ZoneGroups {
		zoneGroups {
			...ExtendedZoneGroup
		}
	}
	${ExtendedZoneGroupFragmentDoc}
`

export function useZoneGroupsQuery(options?: Omit<Urql.UseQueryArgs<ZoneGroupsQueryVariables>, "query">) {
	return Urql.useQuery<ZoneGroupsQuery, ZoneGroupsQueryVariables>({ query: ZoneGroupsDocument, ...options })
}
export const ZoneGroupsByFilterDocument = gql`
	query ZoneGroupsByFilter($filter: ZoneGroupsFilterInput!) {
		zoneGroupsByFilter(filter: $filter) {
			...ExtendedZoneGroup
		}
	}
	${ExtendedZoneGroupFragmentDoc}
`

export function useZoneGroupsByFilterQuery(options: Omit<Urql.UseQueryArgs<ZoneGroupsByFilterQueryVariables>, "query">) {
	return Urql.useQuery<ZoneGroupsByFilterQuery, ZoneGroupsByFilterQueryVariables>({ query: ZoneGroupsByFilterDocument, ...options })
}
export const ZoneGroupsByKeywordDocument = gql`
	query ZoneGroupsByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		zoneGroupsByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			zoneGroups {
				...ExtendedZoneGroup
			}
		}
	}
	${ExtendedZoneGroupFragmentDoc}
`

export function useZoneGroupsByKeywordQuery(options: Omit<Urql.UseQueryArgs<ZoneGroupsByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<ZoneGroupsByKeywordQuery, ZoneGroupsByKeywordQueryVariables>({ query: ZoneGroupsByKeywordDocument, ...options })
}
export const ZoneStatsDocument = gql`
	query ZoneStats($zoneId: String!, $startTimestamp: DateTime!, $endTimestamp: DateTime!) {
		zoneStats(zoneId: $zoneId, startTimestamp: $startTimestamp, endTimestamp: $endTimestamp) {
			...ZoneStats
		}
	}
	${ZoneStatsFragmentDoc}
`

export function useZoneStatsQuery(options: Omit<Urql.UseQueryArgs<ZoneStatsQueryVariables>, "query">) {
	return Urql.useQuery<ZoneStatsQuery, ZoneStatsQueryVariables>({ query: ZoneStatsDocument, ...options })
}
export const ZonesDocument = gql`
	query Zones {
		zones {
			...Zone
		}
	}
	${ZoneFragmentDoc}
`

export function useZonesQuery(options?: Omit<Urql.UseQueryArgs<ZonesQueryVariables>, "query">) {
	return Urql.useQuery<ZonesQuery, ZonesQueryVariables>({ query: ZonesDocument, ...options })
}
export const ZonesByFilterDocument = gql`
	query ZonesByFilter($filter: ZonesFilterInput!, $pagination: PaginatedInput!) {
		zonesByFilter(filter: $filter, pagination: $pagination) {
			hasNextPage
			hasPrevPage
			currentPage
			zones {
				...ExtendedZone
			}
		}
	}
	${ExtendedZoneFragmentDoc}
`

export function useZonesByFilterQuery(options: Omit<Urql.UseQueryArgs<ZonesByFilterQueryVariables>, "query">) {
	return Urql.useQuery<ZonesByFilterQuery, ZonesByFilterQueryVariables>({ query: ZonesByFilterDocument, ...options })
}
export const ZonesByKeywordDocument = gql`
	query ZonesByKeyword($keyword: String!, $pagination: PaginatedInput!) {
		zonesByKeyword(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			currentPage
			zones {
				...ExtendedZone
			}
		}
	}
	${ExtendedZoneFragmentDoc}
`

export function useZonesByKeywordQuery(options: Omit<Urql.UseQueryArgs<ZonesByKeywordQueryVariables>, "query">) {
	return Urql.useQuery<ZonesByKeywordQuery, ZonesByKeywordQueryVariables>({ query: ZonesByKeywordDocument, ...options })
}
export const AlertsUpdateDocument = gql`
	subscription AlertsUpdate {
		alertsUpdate {
			...ExtendedAlert
		}
	}
	${ExtendedAlertFragmentDoc}
`

export function useAlertsUpdateSubscription<TData = AlertsUpdateSubscription>(options: Omit<Urql.UseSubscriptionArgs<AlertsUpdateSubscriptionVariables>, "query"> = {}, handler?: Urql.SubscriptionHandler<AlertsUpdateSubscription, TData>) {
	return Urql.useSubscription<AlertsUpdateSubscription, TData, AlertsUpdateSubscriptionVariables>({ query: AlertsUpdateDocument, ...options }, handler)
}
export const ReaderUpdateDocument = gql`
	subscription ReaderUpdate($readerId: String!) {
		readerUpdate(readerId: $readerId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReaderUpdateSubscription<TData = ReaderUpdateSubscription>(options: Omit<Urql.UseSubscriptionArgs<ReaderUpdateSubscriptionVariables>, "query"> = {}, handler?: Urql.SubscriptionHandler<ReaderUpdateSubscription, TData>) {
	return Urql.useSubscription<ReaderUpdateSubscription, TData, ReaderUpdateSubscriptionVariables>({ query: ReaderUpdateDocument, ...options }, handler)
}
export const ReaderUpdateAtZoneDocument = gql`
	subscription ReaderUpdateAtZone($zoneId: String!) {
		readerUpdateAtZone(zoneId: $zoneId) {
			...Reader
		}
	}
	${ReaderFragmentDoc}
`

export function useReaderUpdateAtZoneSubscription<TData = ReaderUpdateAtZoneSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<ReaderUpdateAtZoneSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<ReaderUpdateAtZoneSubscription, TData>
) {
	return Urql.useSubscription<ReaderUpdateAtZoneSubscription, TData, ReaderUpdateAtZoneSubscriptionVariables>({ query: ReaderUpdateAtZoneDocument, ...options }, handler)
}
export const ReportUpdateDocument = gql`
	subscription ReportUpdate($reportId: String!) {
		reportUpdate(reportId: $reportId) {
			...Report
		}
	}
	${ReportFragmentDoc}
`

export function useReportUpdateSubscription<TData = ReportUpdateSubscription>(options: Omit<Urql.UseSubscriptionArgs<ReportUpdateSubscriptionVariables>, "query"> = {}, handler?: Urql.SubscriptionHandler<ReportUpdateSubscription, TData>) {
	return Urql.useSubscription<ReportUpdateSubscription, TData, ReportUpdateSubscriptionVariables>({ query: ReportUpdateDocument, ...options }, handler)
}
export const RestrictedUserLocationRecordsUpdateDocument = gql`
	subscription RestrictedUserLocationRecordsUpdate($recordIds: [String!]!) {
		restrictedUserLocationRecordsUpdate(recordIds: $recordIds) {
			...ExtendedUserLocationRecord
		}
	}
	${ExtendedUserLocationRecordFragmentDoc}
`

export function useRestrictedUserLocationRecordsUpdateSubscription<TData = RestrictedUserLocationRecordsUpdateSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<RestrictedUserLocationRecordsUpdateSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<RestrictedUserLocationRecordsUpdateSubscription, TData>
) {
	return Urql.useSubscription<RestrictedUserLocationRecordsUpdateSubscription, TData, RestrictedUserLocationRecordsUpdateSubscriptionVariables>({ query: RestrictedUserLocationRecordsUpdateDocument, ...options }, handler)
}
export const UserLocationRecordUpdateDocument = gql`
	subscription UserLocationRecordUpdate($userId: String!) {
		userLocationRecordUpdate(userId: $userId) {
			...ExtendedUserLocationRecord
		}
	}
	${ExtendedUserLocationRecordFragmentDoc}
`

export function useUserLocationRecordUpdateSubscription<TData = UserLocationRecordUpdateSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<UserLocationRecordUpdateSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<UserLocationRecordUpdateSubscription, TData>
) {
	return Urql.useSubscription<UserLocationRecordUpdateSubscription, TData, UserLocationRecordUpdateSubscriptionVariables>({ query: UserLocationRecordUpdateDocument, ...options }, handler)
}
export const UserLocationRecordUpdateAtZoneDocument = gql`
	subscription UserLocationRecordUpdateAtZone($zoneId: String!) {
		userLocationRecordUpdateAtZone(zoneId: $zoneId) {
			...ExtendedUserLocationRecord
		}
	}
	${ExtendedUserLocationRecordFragmentDoc}
`

export function useUserLocationRecordUpdateAtZoneSubscription<TData = UserLocationRecordUpdateAtZoneSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<UserLocationRecordUpdateAtZoneSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<UserLocationRecordUpdateAtZoneSubscription, TData>
) {
	return Urql.useSubscription<UserLocationRecordUpdateAtZoneSubscription, TData, UserLocationRecordUpdateAtZoneSubscriptionVariables>({ query: UserLocationRecordUpdateAtZoneDocument, ...options }, handler)
}
export const UserLocationRecordsUpdateDocument = gql`
	subscription UserLocationRecordsUpdate {
		userLocationRecordsUpdate {
			...ExtendedUserLocationRecord
		}
	}
	${ExtendedUserLocationRecordFragmentDoc}
`

export function useUserLocationRecordsUpdateSubscription<TData = UserLocationRecordsUpdateSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<UserLocationRecordsUpdateSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<UserLocationRecordsUpdateSubscription, TData>
) {
	return Urql.useSubscription<UserLocationRecordsUpdateSubscription, TData, UserLocationRecordsUpdateSubscriptionVariables>({ query: UserLocationRecordsUpdateDocument, ...options }, handler)
}
export const UserRouteRecordsUpdateDocument = gql`
	subscription UserRouteRecordsUpdate($userId: String!) {
		userRouteRecordsUpdate(userId: $userId) {
			...ExtendedUserRouteRecord
		}
	}
	${ExtendedUserRouteRecordFragmentDoc}
`

export function useUserRouteRecordsUpdateSubscription<TData = UserRouteRecordsUpdateSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<UserRouteRecordsUpdateSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<UserRouteRecordsUpdateSubscription, TData>
) {
	return Urql.useSubscription<UserRouteRecordsUpdateSubscription, TData, UserRouteRecordsUpdateSubscriptionVariables>({ query: UserRouteRecordsUpdateDocument, ...options }, handler)
}
