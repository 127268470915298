import { AspectRatio, Box, HStack, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { SITE_LOGO_URL } from "../../constants"

export const Logo: React.FC = () => {
	return (
		<VStack w="full" spacing={0}>
			<AspectRatio w="full" maxW="20" ratio={3 / 4}>
				<Box w="full" h="full" backgroundImage={SITE_LOGO_URL ?? "/logo-192.png"} backgroundRepeat="no-repeat" backgroundPosition="center" backgroundSize="contain" />
			</AspectRatio>
			{SITE_LOGO_URL && (
				<HStack w="full" justify="center" mt="5px !important" spacing={1}>
					<Text fontSize="xs" color="grayscale.label">
						powered by
					</Text>
					<AspectRatio w="full" maxW="16" ratio={20 / 5}>
						<Box w="full" h="full" backgroundImage="/logo-192.png" backgroundRepeat="no-repeat" backgroundPosition="center" backgroundSize="contain" />
					</AspectRatio>
				</HStack>
			)}
		</VStack>
	)
}
