import { cacheExchange as cE } from "@urql/exchange-graphcache"
import {
	ActivateTagOfUserMutationVariables,
	ActivateUserMutation,
	ActivateUserMutationVariables,
	ActiveRestrictedEntriesDocument,
	ActiveRestrictedEntriesQuery,
	AddReaderToZoneMutation,
	AddReaderToZoneMutationVariables,
	AlertFlowFragment,
	AlertFlowFragmentDoc,
	AlertFlowsByKeywordQueryVariables,
	AlertsByFilterQueryVariables,
	AlertTargetGroupFragment,
	AlertTargetGroupFragmentDoc,
	AlertTargetGroupsByKeywordQueryVariables,
	AllReadersDocument,
	AllReadersQuery,
	AllReadersQueryVariables,
	AllTagsDocument,
	AllTagsQuery,
	AllTagsQueryVariables,
	AssignTagMutation,
	AssignTagMutationVariables,
	AssignUserRoleMutation,
	AssignUserRoleMutationVariables,
	CreateReaderMutation,
	CreateTagMutation,
	DeactivateTagOfUserMutationVariables,
	DeactivateUserMutation,
	DeactivateUserMutationVariables,
	DepartmentsByKeywordQueryVariables,
	ExtendedAlertFlowFragment,
	ExtendedAlertFlowFragmentDoc,
	ExtendedAlertTargetGroupFragment,
	ExtendedAlertTargetGroupFragmentDoc,
	ExtendedReportFragment,
	ExtendedTag,
	ExtendedUser,
	ExtendedUserFragment,
	ExtendedUserFragmentDoc,
	ExtendedUserLocationRecordFragment,
	ExtendedZone,
	ExtendedZoneFragment,
	ExtendedZoneFragmentDoc,
	FlushTagMutationVariables,
	LastestMobileAppDocument,
	LastestMobileAppQuery,
	LoginMutation,
	MeDocument,
	MeQuery,
	MeUser,
	MeUserFragment,
	MeUserFragmentDoc,
	MobileApp,
	MobileAppsDocument,
	MobileAppsQuery,
	PermissionsGroupsByKeywordQueryVariables,
	ReaderByIdDocument,
	ReaderByIdQuery,
	ReaderByIdQueryVariables,
	ReaderFragment,
	ReadersAtZoneDocument,
	ReadersAtZoneQuery,
	ReadersAtZoneQueryVariables,
	ReadersByFilterQueryVariables,
	ReaderUpdateAtZoneSubscription,
	ReaderUpdateAtZoneSubscriptionVariables,
	ReaderUpdateSubscription,
	ReaderUpdateSubscriptionVariables,
	RemoveCurrentUserRoleMutation,
	RemoveCurrentUserRoleMutationVariables,
	RemoveReaderFromZoneMutation,
	RemoveReaderFromZoneMutationVariables,
	ReportByIdDocument,
	ReportByIdQuery,
	ReportByIdQueryVariables,
	ReportsByKeywordQueryVariables,
	ReportUpdateSubscription,
	ReportUpdateSubscriptionVariables,
	RestrictedUserLocationRecordsUpdateSubscription,
	Tag,
	TagFragment,
	TagFragmentDoc,
	TagsByFilterQueryVariables,
	TagStatus,
	UpdateAlertFlowMutation,
	UpdateAlertFlowMutationVariables,
	UpdateAlertTargetGroupMutation,
	UpdateAlertTargetGroupMutationVariables,
	UpdateUserMutation,
	UpdateUserMutationVariables,
	UpdateUserPermissionsGroupsMutation,
	UpdateUserPermissionsGroupsMutationVariables,
	UpdateZoneMutation,
	UpdateZoneMutationVariables,
	UploadMobileAppMutation,
	UserActiveRouteRecordDocument,
	UserActiveRouteRecordQuery,
	UserActiveRouteRecordQueryVariables,
	UserCurrentLocationRecordDocument,
	UserCurrentLocationRecordQuery,
	UserCurrentLocationRecordQueryVariables,
	UserCurrentUserRoleDocument,
	UserCurrentUserRoleQuery,
	UserCurrentUserRoleQueryVariables,
	UserFragment,
	UserFragmentDoc,
	UserGroupsByKeywordQueryVariables,
	UserLocationRecordsAtZoneDocument,
	UserLocationRecordsAtZoneQuery,
	UserLocationRecordsAtZoneQueryVariables,
	UserLocationRecordStatus,
	UserLocationRecordUpdateAtZoneSubscription,
	UserLocationRecordUpdateAtZoneSubscriptionVariables,
	UserLocationRecordUpdateSubscription,
	UserLocationRecordUpdateSubscriptionVariables,
	UserPastLocationRecordsDocument,
	UserPastLocationRecordsQuery,
	UserPastLocationRecordsQueryVariables,
	UserPermissionsGroupsDocument,
	UserPermissionsGroupsQuery,
	UserPermissionsGroupsQueryVariables,
	UserRoleGroupsByKeywordQueryVariables,
	UserRouteRecordsUpdateSubscription,
	UserRouteRecordsUpdateSubscriptionVariables,
	UsersByCurrentUserRoleGroupQueryVariables,
	UsersByDepartmentQueryVariables,
	UsersByKeywordQueryVariables,
	UsersByTypeQueryVariables,
	UsersByWorkShiftQueryVariables,
	UsersQueryVariables,
	UserTypesByKeywordQueryVariables,
	VerifyEmailWithSecretCodeMutation,
	WorkShiftsByKeywordQueryVariables,
	ZoneFragment,
	ZoneFragmentDoc,
	ZoneGroupsByKeywordQueryVariables,
	ZonesByFilterQueryVariables,
	ZonesByKeywordQueryVariables,
} from "../../graphql"
import { betterQueryUpdate } from "../../utils"

import {
	FinishAvatarUploadMutation,
	FinishAvatarUploadMutationVariables,
	MarkUserAttendanceInMutation,
	MarkUserAttendanceInMutationVariables,
	MarkUserAttendanceOutMutation,
	MarkUserAttendanceOutMutationVariables,
	SwitchSiteMutation,
	SwitchSiteMutationVariables,
	TodaysUserAttendanceDocument,
	TodaysUserAttendanceQuery,
	TodaysUserAttendanceQueryVariables,
	UpdateUserScopedSitesMutation,
	UpdateUserScopedSitesMutationVariables,
	User,
} from "../generated"
import { customPagination } from "../pagination"

import schema from "../generated/schema.json"

export const cacheExchange = cE({
	schema: schema as any,
	keys: {
		Label: () => null,
		Phone: () => null,
		Email: () => null,
		Picture: () => null,
		PictureObject: () => null,
		Position: () => null,
		PaginatedUsersResponse: () => null,
		Tag: (data: any) => data._id,
		PaginatedUserTypesResponse: () => null,
		PaginatedZonesResponse: () => null,
		PaginatedZoneGroupsResponse: () => null,
		PaginatedAlertTargetGroupsResponse: () => null,
		PaginatedReportsResponse: () => null,
		PaginatedPermissionsGroupsResponse: () => null,
		PaginatedUserRoleGroupsResponse: () => null,
		PaginatedUserLocationRecordsResponse: () => null,
		PaginatedDepartmentsResponse: () => null,
		PaginatedWorkShiftsResponse: () => null,
		PaginatedReadersResponse: () => null,
		PaginatedTagsResponse: () => null,
		PaginatedAlertFlowsResponse: () => null,
		PaginatedAlertsResponse: () => null,
		ZoneWiseActiveUsersResponse: () => null,
		ActiveUsersResponse: () => null,
		UserLocationStatsResponse: () => null,
	},
	resolvers: {
		Query: {
			allUsers: customPagination("users", "PaginatedUsersResponse"),
			usersByKeyword: customPagination("users", "PaginatedUsersResponse", (args: UsersByKeywordQueryVariables, vars: UsersByKeywordQueryVariables) => args.keyword === vars.keyword && args.typeId === vars.typeId),
			usersByType: customPagination("users", "PaginatedUsersResponse", (args: UsersByTypeQueryVariables, vars: UsersByTypeQueryVariables) => args.typeId === vars.typeId),
			usersByDepartment: customPagination("users", "PaginatedUsersResponse", (args: UsersByDepartmentQueryVariables, vars: UsersByDepartmentQueryVariables) => args.departmentId === vars.departmentId),
			usersByCurrentUserRoleGroup: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: UsersByCurrentUserRoleGroupQueryVariables, vars: UsersByCurrentUserRoleGroupQueryVariables) => args.userRoleGroupId === vars.userRoleGroupId
			),
			usersByWorkShift: customPagination("users", "PaginatedUsersResponse", (args: UsersByWorkShiftQueryVariables, vars: UsersByWorkShiftQueryVariables) => args.workShiftId === vars.workShiftId),
			users: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: UsersQueryVariables, vars: UsersQueryVariables) => args.filter.departmentId === vars.filter.departmentId && args.filter.keyword === vars.filter.keyword && args.filter.userTypeId === vars.filter.userTypeId
			),
			allUserGroups: customPagination("userGroups", "PaginatedUserGroupsResponse"),
			userGroupsByKeyword: customPagination("userGroups", "PaginatedUserGroupsResponse", (args: UserGroupsByKeywordQueryVariables, vars: UserGroupsByKeywordQueryVariables) => args.keyword === vars.keyword),
			allUserTypes: customPagination("userTypes", "PaginatedUserTypesResponse"),
			userTypesByKeyword: customPagination("userTypes", "PaginatedUserTypesResponse", (args: UserTypesByKeywordQueryVariables, vars: UserTypesByKeywordQueryVariables) => args.keyword === vars.keyword),
			allZones: customPagination("zones", "PaginatedZonesResponse"),
			zonesByKeyword: customPagination("zones", "PaginatedZonesResponse", (args: ZonesByKeywordQueryVariables, vars: ZonesByKeywordQueryVariables) => args.keyword === vars.keyword),
			zonesByFilter: customPagination(
				"zones",
				"PaginatedZonesResponse",
				(args: ZonesByFilterQueryVariables, vars: ZonesByFilterQueryVariables) => args.filter.keyword === vars.filter.keyword && args.filter.type === vars.filter.type && args.filter.isActive === vars.filter.isActive
			),
			allZoneGroups: customPagination("zoneGroups", "PaginatedZoneGroupsResponse"),
			zoneGroupsByKeyword: customPagination("zoneGroups", "PaginatedZoneGroupsResponse", (args: ZoneGroupsByKeywordQueryVariables, vars: ZoneGroupsByKeywordQueryVariables) => args.keyword === vars.keyword),
			allReports: customPagination("reports", "PaginatedReportsResponse"),
			reportsByKeyword: customPagination(
				"reports",
				"PaginatedReportsResponse",
				(args: ReportsByKeywordQueryVariables, vars: ReportsByKeywordQueryVariables) =>
					args.keyword === vars.keyword &&
					args.type === vars.type &&
					args.status === vars.status &&
					(args.startDate && vars.startDate ? new Date(args.startDate).getTime() === new Date(vars.startDate).getTime() : true) &&
					(args.endDate && vars.endDate ? new Date(args.endDate).getTime() === new Date(vars.endDate).getTime() : true)
			),
			allPermissionsGroups: customPagination("permissionsGroups", "PaginatedPermissionsGroupsResponse"),
			permissionsGroupsByKeyword: customPagination(
				"permissionsGroups",
				"PaginatedPermissionsGroupsResponse",
				(args: PermissionsGroupsByKeywordQueryVariables, vars: PermissionsGroupsByKeywordQueryVariables) => args.keyword === vars.keyword
			),
			allUserRoleGroups: customPagination("userRoleGroups", "PaginatedUserRoleGroupsResponse"),
			userRoleGroupsByKeyword: customPagination("userRoleGroups", "PaginatedUserRoleGroupsResponse", (args: UserRoleGroupsByKeywordQueryVariables, vars: UserRoleGroupsByKeywordQueryVariables) => args.keyword === vars.keyword),
			userPastLocationRecords: customPagination(
				"userLocationRecords",
				"PaginatedUserLocationRecordsResponse",
				(args: UserPastLocationRecordsQueryVariables, vars: UserPastLocationRecordsQueryVariables) => args.userId === vars.userId && new Date(args.date).getTime() === new Date(vars.date).getTime()
			),
			allDepartments: customPagination("departments", "PaginatedDepartmentsResponse"),
			departmentsByKeyword: customPagination("departments", "PaginatedDepartmentsResponse", (args: DepartmentsByKeywordQueryVariables, vars: DepartmentsByKeywordQueryVariables) => args.keyword === vars.keyword),
			allWorkShifts: customPagination("workShifts", "PaginatedWorkShiftsResponse"),
			workShiftsByKeyword: customPagination("workShifts", "PaginatedWorkShiftsResponse", (args: WorkShiftsByKeywordQueryVariables, vars: WorkShiftsByKeywordQueryVariables) => args.keyword === vars.keyword),
			allReaders: customPagination("readers", "PaginatedReadersResponse"),
			readersByFilter: customPagination(
				"readers",
				"PaginatedReadersResponse",
				(args: ReadersByFilterQueryVariables, vars: ReadersByFilterQueryVariables) => args.filter.keyword === vars.filter.keyword && args.filter.type === vars.filter.type && args.filter.status === vars.filter.status
			),
			allTags: customPagination("tags", "PaginatedTagsResponse"),
			tagsByFilter: customPagination(
				"tags",
				"PaginatedTagsResponse",
				(args: TagsByFilterQueryVariables, vars: TagsByFilterQueryVariables) => args.filter.keyword === vars.filter.keyword && args.filter.status === vars.filter.status && args.filter.batteryStatus === vars.filter.batteryStatus
			),
			allAlertTargetGroups: customPagination("alertTargetGroups", "PaginatedAlertTargetGroupsResponse"),
			alertTargetGroupsByKeyword: customPagination(
				"alertTargetGroups",
				"PaginatedAlertTargetGroupsResponse",
				(args: AlertTargetGroupsByKeywordQueryVariables, vars: AlertTargetGroupsByKeywordQueryVariables) => args.keyword === vars.keyword
			),
			allAlerts: customPagination("alerts", "PaginatedAlertsResponse"),
			alertsByFilter: customPagination(
				"alerts",
				"PaginatedAlertsResponse",
				(args: AlertsByFilterQueryVariables, vars: AlertsByFilterQueryVariables) => args.filter.status === vars.filter.status && args.filter.type === vars.filter.type
			),
			allAlertFlows: customPagination("alertFlows", "PaginatedAlertFlowsResponse"),
			alertFlowsByKeyword: customPagination("alertFlows", "PaginatedAlertFlowsResponse", (args: AlertFlowsByKeywordQueryVariables, vars: AlertFlowsByKeywordQueryVariables) => args.keyword === vars.keyword),
		},
	},
	updates: {
		Subscription: {
			userLocationRecordUpdateAtZone: (_result: UserLocationRecordUpdateAtZoneSubscription, variables: UserLocationRecordUpdateAtZoneSubscriptionVariables, cache) => {
				betterQueryUpdate<UserLocationRecordUpdateAtZoneSubscription, UserLocationRecordsAtZoneQuery>(
					cache,
					{
						query: UserLocationRecordsAtZoneDocument,
						variables: {
							zoneId: variables.zoneId,
						} as UserLocationRecordsAtZoneQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.userLocationRecordUpdateAtZone) {
							let userLocationRecordsAtZone = [...(query?.userLocationRecordsAtZone ?? [])] as ExtendedUserLocationRecordFragment[]

							const index = userLocationRecordsAtZone.findIndex((r) => String(r._id) === String(result.userLocationRecordUpdateAtZone._id))

							if (typeof index === "number" && index >= 0) {
								if (result.userLocationRecordUpdateAtZone.status === UserLocationRecordStatus.Final) {
									userLocationRecordsAtZone.splice(index, 1)
								} else {
									userLocationRecordsAtZone[index] = result.userLocationRecordUpdateAtZone
								}
							} else {
								userLocationRecordsAtZone = [result.userLocationRecordUpdateAtZone, ...userLocationRecordsAtZone]
							}

							return {
								...query,
								userLocationRecordsAtZone,
							}
						}
						return query
					}
				)
			},
			userLocationRecordUpdate: (_result: UserLocationRecordUpdateSubscription, variables: UserLocationRecordUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<UserLocationRecordUpdateSubscription, UserCurrentLocationRecordQuery>(
					cache,
					{
						query: UserCurrentLocationRecordDocument,
						variables: {
							userId: variables.userId,
						} as UserCurrentLocationRecordQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.userLocationRecordUpdate) {
							if (result.userLocationRecordUpdate.status === UserLocationRecordStatus.Final) {
								cache.updateQuery(
									{
										query: UserPastLocationRecordsDocument,
										variables: { userId: variables.userId } as UserPastLocationRecordsQueryVariables,
									},
									(data: any) => {
										return {
											...data,
											userPastLocationRecords: [result.userLocationRecordUpdate, ...(data.userPastLocationRecords || [])],
										} as UserPastLocationRecordsQuery
									}
								)

								return { ...query, userCurrentLocationRecord: null }
							}

							return { ...query, userCurrentLocationRecord: result.userLocationRecordUpdate }
						}
						return query
					}
				)
			},

			userRouteRecordsUpdate: (_result: UserRouteRecordsUpdateSubscription, variables: UserRouteRecordsUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<UserRouteRecordsUpdateSubscription, UserActiveRouteRecordQuery>(
					cache,
					{
						query: UserActiveRouteRecordDocument,
						variables: {
							userId: variables.userId,
						} as UserActiveRouteRecordQueryVariables,
					},
					_result,
					(result, query) => {
						if (result.userRouteRecordsUpdate) {
							if (result.userRouteRecordsUpdate.isFinal) {
								return { ...query, userActiveRouteRecord: null }
							}

							cache.updateQuery(
								{
									query: UserActiveRouteRecordDocument,
									variables: {
										userId: variables.userId,
									} as UserActiveRouteRecordQueryVariables,
								},
								(data: any) => {
									return {
										...data,
										userActiveRouteRecord: result.userRouteRecordsUpdate,
									} as UserActiveRouteRecordQuery
								}
							)

							return { ...query, userActiveRouteRecord: result.userRouteRecordsUpdate }
						}

						return {
							...query,
							userActiveRouteRecord: result.userRouteRecordsUpdate,
						}
					}
				)
			},

			restrictedUserLocationRecordsUpdate: (_result: RestrictedUserLocationRecordsUpdateSubscription, _, cache) => {
				betterQueryUpdate<RestrictedUserLocationRecordsUpdateSubscription, ActiveRestrictedEntriesQuery>(
					cache,
					{
						query: ActiveRestrictedEntriesDocument,
					},
					_result,
					(result, query) => {
						if (result?.restrictedUserLocationRecordsUpdate) {
							const recordIds = [...query.activeRestrictedEntries.recordIds]
							const zoneWiseRecordIds = [...query.activeRestrictedEntries.zoneWiseRecordIds]

							const recordIdsIndex = recordIds.findIndex((uid) => uid === result.restrictedUserLocationRecordsUpdate._id)

							if (recordIdsIndex < 0) {
								recordIds.push(result.restrictedUserLocationRecordsUpdate._id)
							} else if (result.restrictedUserLocationRecordsUpdate.status === UserLocationRecordStatus.Final) {
								recordIds.splice(recordIdsIndex, 1)
							}

							zoneWiseRecordIds.forEach((z) => {
								const zonedRecordIds = [...z.recordIds]

								const index = zonedRecordIds.findIndex((uid) => uid === result.restrictedUserLocationRecordsUpdate._id)

								if (index < 0) {
									zonedRecordIds.push(result.restrictedUserLocationRecordsUpdate._id)
								} else if (result.restrictedUserLocationRecordsUpdate.status === UserLocationRecordStatus.Final) {
									zonedRecordIds.splice(index, 1)
								}
							})

							return {
								...query,
								activeRestrictedEntries: {
									...query.activeRestrictedEntries,
									recordIds,
									zoneWiseRecordIds,
								},
							}
						}
						return query
					}
				)
			},
			readerUpdateAtZone: (_result: ReaderUpdateAtZoneSubscription, variables: ReaderUpdateAtZoneSubscriptionVariables, cache) => {
				betterQueryUpdate<ReaderUpdateAtZoneSubscription, ReadersAtZoneQuery>(
					cache,
					{
						query: ReadersAtZoneDocument,
						variables: {
							zoneId: variables.zoneId,
						} as ReadersAtZoneQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.readerUpdateAtZone) {
							let readersAtZone = [...(query?.readersAtZone ?? [])] as ReaderFragment[]

							const index = readersAtZone.findIndex((r) => String(r._id) === String(result.readerUpdateAtZone._id))

							if (typeof index === "number" && index >= 0) {
								readersAtZone[index] = result.readerUpdateAtZone
							} else {
								readersAtZone = [result.readerUpdateAtZone, ...readersAtZone]
							}

							return {
								...query,
								readersAtZone,
							}
						}
						return query
					}
				)
			},
			readerUpdate: (_result: ReaderUpdateSubscription, variables: ReaderUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<ReaderUpdateSubscription, ReaderByIdQuery>(
					cache,
					{
						query: ReaderByIdDocument,
						variables: {
							readerId: variables.readerId,
						} as ReaderByIdQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.readerUpdate && query?.readerById) {
							return {
								...query,
								readerById: { ...query.readerById, ...result.readerUpdate },
							}
						}
						return query
					}
				)
			},
			reportUpdate: (_result: ReportUpdateSubscription, variables: ReportUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<ReportUpdateSubscription, ReportByIdQuery>(
					cache,
					{
						query: ReportByIdDocument,
						variables: {
							reportId: variables.reportId,
						} as ReportByIdQueryVariables,
					},
					_result,
					(result, query) => {
						if (result) {
							return {
								...query,
								reportById: {
									...query?.reportById,
									...{ ...(result.reportUpdate as ExtendedReportFragment), __typename: "ExtendedReport" },
								},
							}
						}

						return query
					}
				)
			},
		},
		Mutation: {
			login: (_result, _, cache) => {
				betterQueryUpdate<LoginMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.login.user) {
						return {
							me: result.login.user,
						}
					}

					return query
				})
			},
			logout: (result, _, cache) => cache.invalidate("Query"),
			verifyEmailWithSecretCode: (_result, _, cache) => {
				betterQueryUpdate<VerifyEmailWithSecretCodeMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.verifyEmailWithSecretCode && query?.me) {
						return {
							...query,
							me: { ...query.me, email: { ...query.me.email!, isVerified: true, verifiedAt: new Date() } },
						}
					}

					return query
				})
			},
			finishAvatarUpload: (result: FinishAvatarUploadMutation, variables: FinishAvatarUploadMutationVariables, cache) => {
				const extendedUser = cache.readFragment<ExtendedUserFragment>(ExtendedUserFragmentDoc, { _id: variables.userId } as ExtendedUser)

				if (extendedUser) cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, { ...extendedUser, picture: result.finishAvatarUpload }, { _id: variables.userId })

				const user = cache.readFragment<UserFragment>(UserFragmentDoc, { _id: variables.userId } as User)

				if (user) cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...user, picture: result.finishAvatarUpload }, { _id: variables.userId })

				const meUser = cache.readFragment<MeUserFragment>(MeUserFragmentDoc, { _id: variables.userId } as MeUser)

				if (meUser) cache.writeFragment<MeUserFragment, { _id: string }>(MeUserFragmentDoc, { ...meUser, picture: result.finishAvatarUpload }, { _id: variables.userId })
			},
			updateUser: (result: UpdateUserMutation, variables: UpdateUserMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe) {
					cache.writeFragment<MeUserFragment>(MeUserFragmentDoc, {
						...meQuery?.me,
						...(result.updateUser as MeUserFragment),
						__typename: "MeUser",
					})
				} else {
					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, result.updateUser, { _id: variables.userId })
					cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...result.updateUser, __typename: "User" }, { _id: variables.userId })
				}
			},

			activateUser: (result: ActivateUserMutation, variables: ActivateUserMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe) {
					cache.writeFragment<MeUserFragment>(MeUserFragmentDoc, {
						...meQuery?.me,
						...(result.activateUser as MeUserFragment),
						__typename: "MeUser",
					})
				} else {
					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, result.activateUser, { _id: variables.userId })
					cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...result.activateUser, __typename: "User" }, { _id: variables.userId })
				}
			},
			deactivateUser: (result: DeactivateUserMutation, variables: DeactivateUserMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe) {
					cache.writeFragment<MeUserFragment>(MeUserFragmentDoc, {
						...meQuery?.me,
						...(result.deactivateUser as MeUserFragment),
						__typename: "MeUser",
					})
				} else {
					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, result.deactivateUser, { _id: variables.userId })
					cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...result.deactivateUser, __typename: "User" }, { _id: variables.userId })
				}
			},
			updateUserPermissionsGroups: (result: UpdateUserPermissionsGroupsMutation, variables: UpdateUserPermissionsGroupsMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe) {
					cache.writeFragment<MeUserFragment>(MeUserFragmentDoc, {
						...(meQuery?.me as MeUser),
						permissionsGroupIds: result.updateUserPermissionsGroups.map((g) => g._id as string) as string[],
						permissionsGroups: result.updateUserPermissionsGroups || [],
						__typename: "MeUser",
					})
				} else {
					betterQueryUpdate<UpdateUserPermissionsGroupsMutation, UserPermissionsGroupsQuery>(
						cache,
						{ query: UserPermissionsGroupsDocument, variables: { userId: variables.userId } as UserPermissionsGroupsQueryVariables },
						result,
						(_result, query) => {
							if (_result) {
								return {
									...query,
									userPermissionsGroups: result.updateUserPermissionsGroups,
								}
							}

							return query
						}
					)
				}
			},
			updateUserScopedSites: (result: UpdateUserScopedSitesMutation, variables: UpdateUserScopedSitesMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (!isMe) {
					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, result.updateUserScopedSites, { _id: variables.userId })
					cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...result.updateUserScopedSites, __typename: "User" }, { _id: variables.userId })
				}
			},
			switchSite: (result: SwitchSiteMutation, variables: SwitchSiteMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe) {
					cache.writeFragment<MeUserFragment>(MeUserFragmentDoc, {
						...meQuery?.me,
						...(result.switchSite as MeUserFragment),
						__typename: "MeUser",
					})
				}
			},
			assignUserRole: (result: AssignUserRoleMutation, variables: AssignUserRoleMutationVariables, cache) => {
				betterQueryUpdate<AssignUserRoleMutation, UserCurrentUserRoleQuery>(cache, { query: UserCurrentUserRoleDocument, variables: { userId: variables.userId } as UserCurrentUserRoleQueryVariables }, result, (_result, query) => {
					if (_result) {
						return {
							...query,
							userCurrentUserRole: result.assignUserRole,
						}
					}

					return query
				})
			},
			markUserAttendanceIn: (result: MarkUserAttendanceInMutation, variables: MarkUserAttendanceInMutationVariables, cache) => {
				betterQueryUpdate<MarkUserAttendanceInMutation, TodaysUserAttendanceQuery>(
					cache,
					{ query: TodaysUserAttendanceDocument, variables: { userId: variables.userId } as TodaysUserAttendanceQueryVariables },
					result,
					(_result, query) => {
						if (_result) {
							return {
								...query,
								todaysUserAttendance: result.markUserAttendanceIn,
							}
						}

						return query
					}
				)
			},
			markUserAttendanceOut: (result: MarkUserAttendanceOutMutation, variables: MarkUserAttendanceOutMutationVariables, cache) => {
				betterQueryUpdate<MarkUserAttendanceOutMutation, TodaysUserAttendanceQuery>(
					cache,
					{ query: TodaysUserAttendanceDocument, variables: { userId: variables.userId } as TodaysUserAttendanceQueryVariables },
					result,
					(_result, query) => {
						if (_result) {
							return {
								...query,
								todaysUserAttendance: result.markUserAttendanceOut,
							}
						}

						return query
					}
				)
			},
			removeCurrentUserRole: (result: RemoveCurrentUserRoleMutation, variables: RemoveCurrentUserRoleMutationVariables, cache) => {
				betterQueryUpdate<RemoveCurrentUserRoleMutation, UserCurrentUserRoleQuery>(
					cache,
					{ query: UserCurrentUserRoleDocument, variables: { userId: variables.userId } as UserCurrentUserRoleQueryVariables },
					result,
					(_result, query) => {
						if (_result) {
							return {
								...query,
								userCurrentUserRole: null,
							}
						}

						return query
					}
				)
			},
			activateTagOfUser: (_, variables: ActivateTagOfUserMutationVariables, cache) => {
				const tag = cache.readFragment<TagFragment, { userId: string }>(TagFragmentDoc, { assignedToUserId: variables.userId } as Tag)

				if (tag) {
					cache.writeFragment<TagFragment>(TagFragmentDoc, {
						...tag,
						status: TagStatus.Active,
						__typename: "Tag",
					})
				}
			},
			deactivateTagOfUser: (_, variables: DeactivateTagOfUserMutationVariables, cache) => {
				const tag = cache.readFragment<TagFragment>(TagFragmentDoc, { assignedToUserId: variables.userId } as Tag)

				if (tag) {
					cache.writeFragment<TagFragment>(TagFragmentDoc, {
						...tag,
						status: TagStatus.Inactive,
						__typename: "Tag",
					})
				}
			},
			flushTag: (_, variables: FlushTagMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe && meQuery?.me) {
					cache.writeFragment<MeUserFragment, { _id: string }>(MeUserFragmentDoc, { ...meQuery.me, tagId: null, tag: null }, { _id: variables.userId })
				} else {
					const user = cache.readFragment<ExtendedUserFragment>(ExtendedUserFragmentDoc, { _id: variables.userId } as ExtendedUser)

					if (!user) return

					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, { ...user, tagId: null, tag: null }, { _id: variables.userId })
				}
			},
			assignTag: (result: AssignTagMutation, variables: AssignTagMutationVariables, cache) => {
				let isMe = false

				const meQuery = cache.readQuery<MeQuery>({ query: MeDocument })

				if (meQuery?.me?._id === variables.userId) isMe = true

				if (isMe && meQuery?.me) {
					cache.writeFragment<MeUserFragment, { _id: string }>(MeUserFragmentDoc, { ...meQuery.me, tagId: result.assignTag._id, tag: result.assignTag }, { _id: variables.userId })
				} else {
					const user = cache.readFragment<ExtendedUserFragment>(ExtendedUserFragmentDoc, { _id: variables.userId } as ExtendedUser)

					if (!user) return

					cache.writeFragment<ExtendedUserFragment, { _id: string }>(ExtendedUserFragmentDoc, { ...user, tagId: result.assignTag._id, tag: result.assignTag }, { _id: variables.userId })
				}
			},
			updateZone: (result: UpdateZoneMutation, variables: UpdateZoneMutationVariables, cache) => {
				cache.writeFragment<ExtendedZoneFragment, { _id: string }>(ExtendedZoneFragmentDoc, result.updateZone, { _id: variables.zoneId })
				cache.writeFragment<ZoneFragment, { _id: string }>(ZoneFragmentDoc, { ...(result.updateZone as ZoneFragment), __typename: "Zone" }, { _id: variables.zoneId })
			},
			addReaderToZone: (result: AddReaderToZoneMutation, variables: AddReaderToZoneMutationVariables, cache) => {
				const zone = cache.readFragment<ExtendedZoneFragment>(ExtendedZoneFragmentDoc, { _id: variables.zoneId } as ExtendedZone)

				if (!zone) return

				cache.writeFragment<ExtendedZoneFragment, { _id: string }>(ExtendedZoneFragmentDoc, { ...zone, readerIds: [result.addReaderToZone._id, ...(zone.readerIds || [])] }, { _id: variables.zoneId })

				betterQueryUpdate<AddReaderToZoneMutation, ReadersAtZoneQuery>(cache, { query: ReadersAtZoneDocument, variables: { zoneId: variables.zoneId } as ReadersAtZoneQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							readersAtZone: [_result.addReaderToZone, ...(query.readersAtZone || [])],
						}
					}

					return query
				})
			},
			removeReaderFromZone: (result: RemoveReaderFromZoneMutation, variables: RemoveReaderFromZoneMutationVariables, cache) => {
				const zone = cache.readFragment<ExtendedZoneFragment>(ExtendedZoneFragmentDoc, { _id: variables.zoneId } as ExtendedZone)

				if (!zone) return

				const readerIds = [...(zone.readerIds || [])]

				readerIds.splice(readerIds.indexOf(variables.readerId), 1)

				cache.writeFragment<ExtendedZoneFragment, { _id: string }>(ExtendedZoneFragmentDoc, { ...zone, readerIds }, { _id: variables.zoneId })

				betterQueryUpdate<AddReaderToZoneMutation, ReadersAtZoneQuery>(cache, { query: ReadersAtZoneDocument, variables: { zoneId: variables.zoneId } as ReadersAtZoneQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						const readersAtZone = [...(query.readersAtZone || [])]

						readersAtZone.splice(
							readersAtZone.findIndex((r) => r._id === variables.readerId),
							1
						)

						return {
							...query,
							readersAtZone,
						}
					}

					return query
				})
			},
			createReader: (result: CreateReaderMutation, _, cache) => {
				betterQueryUpdate<CreateTagMutation, AllReadersQuery>(cache, { query: AllReadersDocument, variables: { pagination: { limit: 20, page: 1 } } as AllReadersQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							allReaders: { ...query.allReaders, readers: [result.createReader, ...query.allReaders.readers] },
						}
					}

					return query
				})
			},
			createTag: (result: CreateTagMutation, _, cache) => {
				betterQueryUpdate<CreateTagMutation, AllTagsQuery>(cache, { query: AllTagsDocument, variables: { pagination: { limit: 20, page: 1 } } as AllTagsQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							allTags: { ...query.allTags, tags: [result.createTag as ExtendedTag, ...query.allTags.tags] },
						}
					}

					return query
				})
			},
			updateAlertTargetGroup: (result: UpdateAlertTargetGroupMutation, variables: UpdateAlertTargetGroupMutationVariables, cache) => {
				cache.writeFragment<ExtendedAlertTargetGroupFragment, { _id: string }>(ExtendedAlertTargetGroupFragmentDoc, result.updateAlertTargetGroup, { _id: variables.alertTargetGroupId })
				cache.writeFragment<AlertTargetGroupFragment, { _id: string }>(AlertTargetGroupFragmentDoc, { ...result.updateAlertTargetGroup, __typename: "AlertTargetGroup" }, { _id: variables.alertTargetGroupId })
			},
			updateAlertFlow: (result: UpdateAlertFlowMutation, variables: UpdateAlertFlowMutationVariables, cache) => {
				cache.writeFragment<ExtendedAlertFlowFragment, { _id: string }>(ExtendedAlertFlowFragmentDoc, result.updateAlertFlow, { _id: variables.alertFlowId })
				cache.writeFragment<AlertFlowFragment, { _id: string }>(AlertFlowFragmentDoc, { ...(result.updateAlertFlow as AlertFlowFragment), __typename: "AlertFlow" }, { _id: variables.alertFlowId })
			},

			uploadMobileApp: (result: UploadMobileAppMutation, _, cache) => {
				betterQueryUpdate<UploadMobileAppMutation, MobileAppsQuery>(cache, { query: MobileAppsDocument }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							mobileApps: { ...query.mobileApps, mobileApps: [result.uploadMobileApp as MobileApp, ...query.mobileApps] },
						}
					}

					return query
				})

				betterQueryUpdate<UploadMobileAppMutation, LastestMobileAppQuery>(cache, { query: LastestMobileAppDocument }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							lastestMobileApp: {
								...query.lastestMobileApp,
								version: result.uploadMobileApp.version as string,
								url: result.uploadMobileApp.url as string,
							},
						}
					}

					return query
				})
			},
		},
	},
})
