import { Center, chakra, Heading, HStack, Spinner, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { useTodayTrackedUserIdsQuery } from "../../graphql"

export const TodayTotalUsers: React.FC = () => {
	const [{ data, fetching, error }] = useTodayTrackedUserIdsQuery()

	return (
		<VStack flex="1" align="stretch" spacing={4} p="1">
			<Heading fontSize="sm">Today&apos;s active users</Heading>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.todayTrackedUserIds ? (
				<VStack align="stretch">
					<HStack>
						<VStack align="stretch">
							<Heading color="primary.400">
								{data.todayTrackedUserIds.length || 0}{" "}
								<chakra.span fontSize="sm" fontWeight="normal">
									users
								</chakra.span>
							</Heading>
							<Text fontSize="sm">{format(new Date(), "MMM dd, yyyy")}</Text>
						</VStack>
					</HStack>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
