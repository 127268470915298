/* eslint-disable @typescript-eslint/indent */
import { AspectRatio, Box, Center, HStack, Icon, IconButton, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { ChevronLeft, ChevronRight, Home, Map } from "react-feather"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { TileFragment, useTilesQuery } from "../../graphql"

export type TileSelectorProps = {
	tileId?: string
}

export const TileSelector: React.FC<TileSelectorProps> = ({ tileId }) => {
	const { pathname } = useLocation()

	const navigate = useNavigate()

	const [{ data }] = useTilesQuery()

	const [selectedTile, setSelectedTile] = useState<TileFragment>()

	useEffect(() => {
		if (pathname.startsWith("/map") && data?.tiles.length && !selectedTile) {
			if (tileId) {
				setSelectedTile(data.tiles.find((tile) => tile._id === tileId))
			} else {
				setSelectedTile(data.tiles[0])
				navigate(`/map/${data.tiles[0]._id}`)
			}
		}
	}, [data, selectedTile, pathname])

	const handleNextTileButton = () => {
		const index = data?.tiles.findIndex((tile) => tile._id === selectedTile?._id)

		if (typeof index !== "number" || index < 0) return data?.tiles.slice(0) || []

		const nextIndex = (index + 1) % data!.tiles.length
		const nextTile = data?.tiles[nextIndex]

		setSelectedTile(nextTile)
		navigate(`/map/${nextTile?._id}`)
	}

	const handlePrevTileButton = () => {
		const index = data?.tiles.findIndex((tile) => tile._id === selectedTile?._id)

		if (typeof index !== "number" || index < 0) return data?.tiles.slice(0) || []

		const prevIndex = (index - 1 + data!.tiles.length) % data!.tiles.length

		const prevTile = data?.tiles[prevIndex]

		setSelectedTile(prevTile)
		navigate(`/map/${prevTile?._id}`)
	}

	const ref = React.useRef()

	return (
		<>
			{!pathname.startsWith("/map") ? (
				<Box ref={ref as any} className="tiles-selector" pos="fixed" zIndex={10} top={12} right={4} w={24} transition="width .2s ease-in-out" bgColor="grayscale.off-white" rounded="2xl" overflow="hidden">
					<HStack justify="flex-start" overflow="auto">
						<AspectRatio
							w="full"
							minW={24}
							ratio={4 / 3}
							onClick={() => {
								setSelectedTile(data?.tiles.find((t) => t.embedsAllZones === true) || data?.tiles[0])
								navigate("/map")
							}}
							border="6px solid"
							borderColor="primary.400"
							rounded="2xl"
							cursor="pointer"
						>
							<Center w="full" bgColor="grayscale.off-white" rounded="lg">
								<Icon as={Map} fontSize="2xl" />
							</Center>
						</AspectRatio>
					</HStack>
				</Box>
			) : (
				<VStack>
					<IconButton pos="fixed" top={5} left={5} aria-label="home-btn" rounded="full" colorScheme="purple" as={NavLink} to="/">
						<Icon as={Home} />
					</IconButton>
					<Box ref={ref as any} className="prev-tile-selector" pos="fixed" zIndex={12} top="40%" left="1%" w="16" transition="width .2s ease-in-out" bgColor="whiteAlpha.500" rounded="2xl" overflow="hidden">
						<HStack justify="flex-start" overflow="auto">
							<AspectRatio w="full" ratio={4 / 3} rounded="2xl" cursor="pointer">
								<Icon as={ChevronLeft} onClick={() => handlePrevTileButton()} fontSize="2xl" />
							</AspectRatio>
						</HStack>
					</Box>
					<Box ref={ref as any} className="next-tile-selector" pos="fixed" zIndex={12} top="40%" right="1%" w="16" transition="width .2s ease-in-out" bgColor="whiteAlpha.500" rounded="2xl" overflow="hidden">
						<HStack justify="flex-start" overflow="auto">
							<AspectRatio w="full" ratio={4 / 3} rounded="2xl" cursor="pointer">
								<Icon as={ChevronRight} onClick={() => handleNextTileButton()} fontSize="2xl" />
							</AspectRatio>
						</HStack>
					</Box>
				</VStack>
			)}
		</>
	)
}
