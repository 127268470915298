import { Button, Center, Divider, HStack, Icon, Image, Link, LinkBox, LinkOverlay, List, ListItem, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, useDisclosure, useToast, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { ChevronDown } from "react-feather"
import { NavLink } from "react-router-dom"
import { SESSION_TOKEN_KEY } from "../../constants"
import { useLogoutMutation, useMeQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { MobileAppModal } from "../mobileApp"
import { Site } from "./Site"
import { UserTag } from "./UserTag"

export const AccountPopover: React.FC = () => {
	const [{ data }] = useMeQuery()

	const [{ fetching: loggingOut }, logout] = useLogoutMutation()

	const toast = useToast()

	const handleLogout = async () => {
		const { error, data } = await logout({})

		if (error) {
			return toast({ description: error.message.replace("GraphQL ", ""), status: "error" })
		}

		if (!data?.logout) {
			return toast({ description: "Could not log out", status: "error" })
		}

		localStorage.removeItem(SESSION_TOKEN_KEY)

		return toast({ description: "You've been logged out", status: "success" })
	}

	if (!data?.me) return <></>

	const { isOpen, onOpen, onClose } = useDisclosure()

	const canReadMobileApps = useMemo(() => {
		return checkForPermissions(data.me, "mobile-apps/read-mobile-app")[0]
	}, [data])

	return (
		<Popover>
			<PopoverTrigger>
				<HStack p="4" _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer" justify="space-between">
					<UserTag user={data.me} />
					<Icon as={ChevronDown} />
				</HStack>
			</PopoverTrigger>
			<PopoverContent bgColor="whiteAlpha.400" backdropFilter="blur(24px)" border="none" _focus={{ boxShadow: "none", outline: "none" }}>
				<PopoverBody as={VStack} w="full" align="stretch">
					<VStack bgColor="grayscale.off-white" shadow="2xl" rounded="xl" align="stretch" spacing={0}>
						<VStack p="4" align="stretch">
							<UserTag user={data.me} />
						</VStack>
						<Divider />
						<Site user={data.me} />
						<Divider />
						<List w="full">
							<ListItem as={(props: any) => <Button {...props} as={NavLink} to="/profile" />} w="full" variant="ghost" colorScheme="primary" rounded="none">
								<Text w="full" textAlign="left" fontSize="xs">
									Profile
								</Text>
							</ListItem>
							<ListItem as={(props: any) => <Button {...props} as={NavLink} to="/changePassword" />} w="full" variant="ghost" colorScheme="primary" rounded="none">
								<Text w="full" textAlign="left" fontSize="xs">
									Change Password
								</Text>
							</ListItem>

							{canReadMobileApps && (
								<ListItem as={(props: any) => <Button {...props} onClick={onOpen} />} w="full" variant="ghost" colorScheme="primary" rounded="none">
									<Text w="full" textAlign="left" fontSize="xs">
										Mobile App Versions
									</Text>
								</ListItem>
							)}
						</List>
						<Divider />
						<Center as={LinkBox} m="4 !important" p="4" pb="1" borderWidth="1px" borderStyle="dashed" borderColor="primary.200" rounded="xl" _hover={{ bgColor: "purple.100" }} transition="background-color .3s ease-in-out">
							<LinkOverlay as={Link} isExternal href="https://play.google.com/store/apps/details?id=com.lighthouse.jmd" />
							<VStack w="full" spacing={0}>
								<Image src="/download-app.svg" w="full" maxW="32" />
								<Text fontSize="xs" color="primary.800" fontWeight="semibold">
									Download Mobile App
								</Text>
							</VStack>
						</Center>
						<Divider />
						<List w="full">
							<ListItem as={Button} w="full" variant="ghost" colorScheme="error" rounded="none" roundedBottom="xl" isLoading={loggingOut} onClick={handleLogout}>
								<Text w="full" textAlign="left" fontSize="sm">
									Logout
								</Text>
							</ListItem>
						</List>

						{isOpen && canReadMobileApps && <MobileAppModal isOpen={isOpen} onClose={onClose} meData={data.me} />}
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
